import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    allheader: Config.apiBaseUrl + "ddloptionheader/getheader",
    getallheaders: Config.apiBaseUrl + "ddloptionheader/getheader?limit=1500",
    getOptionurl: Config.apiBaseUrl + "listoption/getoptions",
    getOptionsByHeaderIdurl: Config.apiBaseUrl + "listoption/getoptionlistbyid",
    addOptionurl: Config.apiBaseUrl + "listoption/add",
    updateOptionurl: Config.apiBaseUrl + "listoption/update",
    deleteOptionurl: Config.apiBaseUrl + "listoption/delete?optionId=",
    emptyroomsurl: Config.apiBaseUrl + "room/getemptyrooms?clientId=",
    getAllOptionHeaders: Config.apiBaseUrl + "",
    getOptionByHeaderKeyUrl: Config.apiBaseUrl +"listoption/getoptionlistbykey?key=",
    addHeaderUrl: Config.apiBaseUrl + "ddloptionheader/add",
    allHeaders(filter) {
        return axios.get(this.allheader + "?search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAllHeaders() {
        return axios.get(this.getallheaders);
    },
    getHeaderbyId(id) {
        return axios.get(this.geturl + id);
    },
    getOptions(filter) {
        return axios.get(this.getOptionurl + "?headerId=" + filter.headerId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getOptionsByHeaderId(headerId) {

        return axios.get(this.getOptionsByHeaderIdurl+"?headerId=" + headerId)
    },
    addOption(option) {
        return axios.post(this.addOptionurl, option);
    },
    updateOption(option) {
        return axios.put(this.updateOptionurl, option);
    },
    deleteOption(id) {
        return axios.delete(this.deleteOptionurl + id);
    },
    getOptionsByKey(key){
        return axios.get(this.getOptionByHeaderKeyUrl+ key)
    },
    addHeader(header){
        return axios.post(this.addHeaderUrl , header)
    }
}