import axios from "axios"
import Config from "@/Configuration/Config";

export default {
    allUrl: Config.apiBaseUrl + "job/getjobs?status=",
    getAllUrl: Config.apiBaseUrl + "job/getjobs?limit=1500&status=1",
    getByIdUrl: Config.apiBaseUrl + "job/getjobbyid?jobId=",
    addUrl: Config.apiBaseUrl + "job/add",
    updateUrl: Config.apiBaseUrl + "job/update",
    deleteUrl: Config.apiBaseUrl + "job/delete?jobId=",
    getOffersUrl: Config.apiBaseUrl + "job/getofferedjobs?applicantId=",

    all(filter) {
        return axios.get(this.allUrl + filter.status + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getOfferedJobs(filter) {
        return axios.get(this.getOffersUrl + filter.applicantId +"&category="+filter.category)
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(job) {
        return axios.post(this.addUrl, job);
    },
    update(job) {
        return axios.put(this.updateUrl, job);
    },
    delete(jobId) {
        return axios.delete(this.deleteUrl + jobId);
    },
}