import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    allUrl: Config.apiBaseUrl + "stage/getstages?search=",
    getAllUrl: Config.apiBaseUrl + "stage/getstages?limit=1500",
    getAllStagesUrl: Config.apiBaseUrl + "stage/getactivestages?search=",
    getAllStagesForProcessUrl: Config.apiBaseUrl + "stage/getprocessstages",
    getByIdUrl: Config.apiBaseUrl + "stage/getstagebyid?stageId=",
    addUrl: Config.apiBaseUrl + "stage/add",
    updateUrl: Config.apiBaseUrl + "stage/update",
    deleteUrl: Config.apiBaseUrl + "stage/delete?stageId=",
    softDeleteUrl: Config.apiBaseUrl + "stage/softdelete?stageId=",
    assignUrl: Config.apiBaseUrl + "stage/assign?processId=",
    removeLinkUrl: Config.apiBaseUrl + "stage/unlink?processId=",
    filteredSectionsUrl: Config.apiBaseUrl + "section/getfilteredstages?sectionId=",
    approvalStagesUrl: Config.apiBaseUrl + "stage/getapprovals?userId=",
    updatestatusUrl: Config.apiBaseUrl + "stage/updatestatus?id=",
    stageDataUrl: Config.apiBaseUrl + "stage/viewstagedata?applicationId=",


    all(filter) {
        return axios.get(this.allUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getAllUrl);
    }, getAllStages() {
        return axios.get(this.getAllStagesUrl);
    },
    getStagesForProcess() {
        return axios.get(this.getAllStagesForProcessUrl)
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(stage) {
        return axios.post(this.addUrl, stage);
    },
    update(stage) {
        return axios.put(this.updateUrl, stage);
    },
    delete(stageId) {
        return axios.delete(this.deleteUrl + stageId);
    },
    softDelete(stageId) {
        return axios.delete(this.softDeleteUrl + stageId);
    },
    assign(processId, stageId) {
        return axios.get(this.assignUrl + processId + "&stageId=" + stageId);
    },
    removeLink(processId, stageId) {
        return axios.get(this.removeLinkUrl + processId + "&stageId=" + stageId);
    },
    getFilteredStages(sectionId, linkType) {
        return axios.get(this.filteredSectionsUrl + sectionId + "&linkType=" + linkType);
    },
    getStagesForApproval(approverId, filter) {
        return axios.get(this.approvalStagesUrl + approverId + "&search=" + filter.search + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    updateStatus(id, status) {
        return axios.get(this.updatestatusUrl + id + "&status=" + status)
    },
    viewStageData(applicationId, stageId) {
        return axios.get(this.stageDataUrl + applicationId + "&stageId=" + stageId)
    }
}