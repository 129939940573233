export function getApplicantIdFromSession() {
    let applicantId = sessionStorage.getItem("applicantId");

    // Check if the retrieved value is a string "null" or an empty string
    if (applicantId === "null" || applicantId === "") {
        return null;
    } else {
        return parseInt(applicantId, 10); // Convert to number if applicable
    }
}
