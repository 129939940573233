import axios from "axios"
import Config from "@/Configuration/Config";


export default {
    allUrl: Config.apiBaseUrl + "doctemplate/getdoctemplates?search",
    getallUrl: Config.apiBaseUrl + "doctemplate/getdoctemplates?limit=15000",
    getbyIdUrl: Config.apiBaseUrl + "doctemplate/getdoctemplatebyid?docId=",
    addUrl: Config.apiBaseUrl + "doctemplate/add",
    updateUrl: Config.apiBaseUrl + "doctemplate/update",
    deleteUrl: Config.apiBaseUrl + "doctemplate/delete?doctemplatetId=",
    forcedeleteUrl: Config.apiBaseUrl + "doctemplate/forcedelete?doctemplateId=",
    downloadUrl: Config.apiBaseUrl + "doctemplate/download?docId=",

    all(filter) {
        return axios.get(this.allUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getallUrl);
    },
    getbyId(id) {
        return axios.get(this.getbyIdUrl + id);
    },

    add(docTemplate) {
        return axios.post(this.addUrl, docTemplate);
    },
    update(docTemplate) {
        return axios.post(this.updateUrl, docTemplate);
    },
    delete(id) {
        return axios.delete(this.deleteUrl + id);
    },
    forceDelete(id) {
        return axios.delete(this.forcedeleteUrl + id);
    },
    download(id) {
        return axios.get(this.downloadUrl + id);
    },

}