import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    allUrl: Config.apiBaseUrl + "section/getsections?search=",
    getAllUrl: Config.apiBaseUrl + "section/getsections?limit=1500",
    getByIdUrl: Config.apiBaseUrl + "section/getsectionbyid?sectionId=",
    addUrl: Config.apiBaseUrl + "section/add",
    updateUrl: Config.apiBaseUrl + "section/update",
    deleteUrl: Config.apiBaseUrl + "section/delete?sectionId=",
    softDeleteUrl: Config.apiBaseUrl + "section/softdelete?sectionId=",
    assignUrl : Config.apiBaseUrl +"section/assign?stageId=",
    removeLinkUrl : Config.apiBaseUrl +"section/removelink?stageId=",
    filteredSectionsUrl : Config.apiBaseUrl + "section/getfilteredsections?questionId=",
    all(filter) {
        return axios.get(this.allUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(section) {
        return axios.post(this.addUrl, section);
    },
    update(section) {
        return axios.put(this.updateUrl, section);
    },
    delete(sectionId) {
        return axios.delete(this.deleteUrl + sectionId);
    },
    softDelete(sectionId) {
        return axios.delete(this.softDeleteUrl + sectionId);
    },
    assign(stageId,sectionId){
        return axios.get(this.assignUrl+stageId+"&sectionId="+sectionId);
    },
    removeLink(stageId,sectionId){
        return axios.get(this.removeLinkUrl+stageId+"&sectionId="+sectionId);
    },
    getFilteredSections(questionId,linkType){
        return axios.get(this.filteredSectionsUrl+questionId+"&linkType="+linkType);
    },

}