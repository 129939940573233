<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-7">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-5 d-flex justify-content-end">
          <el-input v-model="filter.search" placeholder="Search here..." @keyup="getApplicants()" >
            <template #append>
              <el-button @click="getApplicants()">
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>
          <el-button @click="resetApplicant()"
                     class="border-0 mbtn-primary ms-1 text-capitalize">{{
              getMessageByCode('add_job') ?? 'Add Applicant'
            }}
          </el-button>
        </div>
      </div>
    </div>
    <el-table :data="applicants" :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe>
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            small
        >
        </el-pagination>
      </template>
      <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
      <el-table-column :label="getMessageByCode('forename')??'Forename'" prop="forename" sortable></el-table-column>
      <el-table-column :label="getMessageByCode('surname')??'Surname'" prop="surname"></el-table-column>
      <el-table-column :label="getMessageByCode('email')??'Email'" prop="email"></el-table-column>
      <el-table-column :label="getMessageByCode('gender')??'Gender'" prop="gender"></el-table-column>
      <el-table-column :label="getMessageByCode('nationality')??'Nationality'" prop="nationality"></el-table-column>
      <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="80">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <i
                class="mgc_edit_line text-primary action-btn"
                @click="editApplicant(scope.row,'edit')"
            ></i>
            <i @click="editApplicant(scope.row,'del')"
               class="mgc_delete_2_line text-danger action-btn"
            ></i>
          </el-space>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog v-model="addDialog" draggable align-center>
      <template #header>
        <span v-if="applicant.id < 1">{{ getMessageByCode('add_appl') ?? 'Add Applicant' }}</span>
        <span v-else>{{ getMessageByCode('edit_appl') ?? 'Edit Applicant' }}</span>
      </template>
      <template #default>
        <el-form label-position="top" hide-required-asterisk>
          <div class="row">
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="required lh-base d-block">{{ getMessageByCode('forename') ?? 'Forename' }}</span>
                <el-input v-model="applicant.forename"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('surname') ?? 'Surname' }}</span>
                <el-input v-model="applicant.surname"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="required lh-base d-block">{{ getMessageByCode('email') ?? 'Email' }}</span>

                <el-input v-model="applicant.email"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('nationality') ?? 'Nationality' }}</span>
                <el-select filterable  v-model="applicant.nationality" class="w-100">
                  <el-option
                      v-for="item in ethnicities"
                      :key="item.optId"
                      :label="item.optTitle"
                      :value="item.optTitle"
                  />
                </el-select>
              </el-form-item>
            </div>

            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('ni') ?? 'NI Number' }}</span>

                <el-input class="w-100" v-model="applicant.niNumber"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('address') ?? 'Address' }} 1</span>

                <el-input class="w-100" v-model="applicant.address1"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('address') ?? 'Address' }} 2</span>

                <el-input class="w-100" v-model="applicant.address2"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('address') ?? 'Address' }} 3</span>

                <el-input class="w-100" v-model="applicant.address3"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('home_telephone') ?? 'Home Telephone' }}</span>
                <el-input class="w-100" v-model="applicant.homeTelephoneNumber"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('mobile_number') ?? 'Mobile Number' }}</span>
                <el-input class="w-100" v-model="applicant.mobileTelephoneNumber"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-4">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('gender') ?? 'Gender' }}</span>
                <el-select filterable  v-model="applicant.gender" class="w-100">
                  <el-option
                      v-for="item in genders"
                      :key="item.optId"
                      :label="item.optTitle"
                      :value="item.optTitle"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12 col-md-4">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('birth_date') ?? 'Date Of Birth' }}</span>

                <el-date-picker class="w-100" v-model="applicant.dateOfBirth" :editable="false"></el-date-picker>
              </el-form-item>
            </div>
            <div class="col-12 col-md-4">
              <el-form-item>
                <span class="lh-base d-block">{{ getMessageByCode('post_code') ?? 'Post Code' }}</span>

                <el-input class="w-100" v-model="applicant.postCode"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
            <el-button type="primary" v-if="applicant.id < 1" @click="addApplicant()">
              {{ getMessageByCode('add_appl') ?? 'Add Applicant' }}
            </el-button>
            <el-button type="primary" v-else @click="updateApplicant()">{{
                getMessageByCode('upd_appl') ?? 'Update Applicant'
              }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="450">
      <template #header>
        <span>{{getMessageByCode('delete') ?? 'Delete'}} {{getMessageByCode('applicant') ?? 'Applicant'}}</span>
      </template>
      <template #default>
        <span>{{getMessageByCode('do_u_del') ?? 'Do you want to delete'}}</span> <span class="fw-bold text-danger">{{applicant?.forename}} {{applicant?.surname}}</span>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="deleteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
            <button class="btn btn-sm ms-1 px-3 btn-danger"  @click="deleteApplicants()">
              {{ getMessageByCode('delete') ?? 'Delete' }}
            </button>

          </div>
        </div>
      </template>
    </el-dialog>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>

import {defineComponent} from "vue";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import applicantService from "@/Services/ComponentsServices/applicantService";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";
import optionService from "@/Services/ComponentsServices/optionService";

export default defineComponent({
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      applicants: [],
      translations: [],
      applicant: {
        "id": 0,
        "surname": "",
        "forename": "",
        "dateOfBirth": null,
        "nationality": "",
        "gender": "",
        "niNumber": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "postCode": "",
        "homeTelephoneNumber": "",
        "mobileTelephoneNumber": "",
        "email": ""
      },
      genders: [],
      ethnicities: [],

      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {
    editApplicant(applicant, type) {
      this.applicant = {...applicant};
      switch (type) {
        case 'edit':
          this.$store.state.pageId = this.applicant.id;
          this.$router.push('/add_applicant')
          break;
        case 'del':
          this.deleteDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getApplicants();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getApplicants();
    },
    async deleteApplicants() {
      this.loading = true;
      try {
        let response = await applicantService.delete(this.applicant.id);
        if (response.status === 200) {
          ShowMessage('success', 'Applicant deleted')
        }
       await this.getApplicants();
      } catch (e) {
        console.log(e);
        ShowMessage('error', 'Something went wrong');
      }
      this.loading = false;
    },
    async addApplicant() {
      this.addDialog = false;
      this.loading = true;
      try {
        let response = await applicantService.add(this.applicant);
        if (response.status === 200) {
          ShowMessage('success', "Applicant added");
        }
       await this.getApplicants();
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!.")
      }
      this.loading = false;
    },
    async updateApplicant() {
      this.addDialog = false;
      this.loading = true;
      try {
        let response = await applicantService.update(this.applicant);
        if (response.status === 200) {
          ShowMessage('success', "Applicant updated");
        }
        await this.getApplicants();
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!.")
      }
      this.loading = false;
    },

    async getApplicants() {
      try {
        this.loading = true;
        let response = await applicantService.all(this.filter);
        this.applicants = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getEthnicities() {
      try {
        let response = await optionService.getOptionsByHeaderId(21)
        this.ethnicities = response.data ?? [];

      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getGenders() {
      try {
        let response = await optionService.getOptionsByHeaderId(20)
        this.genders = response.data ?? [];
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetApplicant() {
      this.$store.state.pageId = 0;
      this.$router.push('/add_applicant');
      return;
      // eslint-disable-next-line no-unreachable
      this.addDialog = true;
      this.applicant = {
        "id": 0,
        "surname": "",
        "forename": "",
        "dateOfBirth": null,
        "nationality": "",
        "gender": "",
        "niNumber": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "postCode": "",
        "homeTelephoneNumber": "",
        "mobileTelephoneNumber": "",
        "email": ""
      };
    }
  },
  created() {
    this.translations = getLanguage();
    this.getApplicants();
    this.getGenders();
    this.getEthnicities();
  }
})
</script>

<style scoped>
.el-form--label-top .el-form-item .el-form-item__label {
  margin-bottom: 0 !important;
}
</style>