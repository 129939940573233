<template>
  <div>
    <div class="container-fluid">
      <div class="container-fluid border-bottom mb-2 p-0">
        <div class="row d-flex align-items-center custom-toolbar">
          <div class="col-12 col-md-7">
            <BreadCrumb :showBread="false"/>
          </div>
          <div class="col-12 col-md-5 d-flex justify-content-end">
            <el-input
                v-model="filter.search"
                placeholder="Search here..."
                @keyup="getQuestions()"
            >
              <template #append>
                <el-button @click="getQuestions()">
                  <i class="bi bi-search"></i>
                </el-button>
              </template>
            </el-input>
            <el-button
                @click="goToAddQuestion()"
                class="border-0 mbtn-primary ms-1 text-capitalize"
            >Add Question
            </el-button>
          </div>
        </div>
      </div>
      <el-table :data="questions" :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe>
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              small
          >
          </el-pagination>
        </template>
        <el-table-column label="#" width="80">
          <template #default="scope">
            <div>{{ getRowIndex(scope.$index) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="Title" prop="title" width="400" sortable></el-table-column>
        <el-table-column label="Type" prop="type" width="150"></el-table-column>
        <el-table-column label="Sort Order" prop="sortOrder" sortable width="120">
          <template #default="scope">
            <div class="text-end">
              <span>{{scope.row.sortOrder}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Description" prop="description"></el-table-column>

        <el-table-column align="center" fixed="right" label="Actions" width="120">
          <template #default="scope">
            <div class="d-flex justify-content-between align-items-center">
              <el-tooltip content="Edit">
                <i
                    class="mgc_edit_line text-primary action-btn"
                    @click="editQuestion(scope.row,'edit')"
                ></i>
              </el-tooltip>
              <el-tooltip content="Remove Link">
                <i
                    class="mgc_unlink_line text-warning action-btn"
                    @click="editQuestion(scope.row,'removeLink')"
                ></i>
              </el-tooltip>
              <el-tooltip content="Assign to stage">
                <i
                    class="mgc_list_check_2_line text-success action-btn"
                    @click="editQuestion(scope.row,'assign')"
                ></i>
              </el-tooltip>
              <el-tooltip content="Delete">
                <i @click="editQuestion(scope.row,'delete')"
                   class="mgc_delete_2_line text-danger action-btn"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-model="assignDialog" width="400">
      <template #header>
        <span>Assign Section</span>
      </template>
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <el-form-item label="Section">
            <el-select v-model="question.sectionId" clearable filterable>
              <el-option v-for="section in sections" :key="section.id" :label="section.title"
                         :value="section.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="assignDialog = false">Cancel</el-button>
          <el-button type="primary" @click="assignToSection()" class="ms-2">Assign</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="removeLinkDialog" width="400">
      <template #header>
        <span>Remove Link</span>
      </template>
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <el-form-item label="Section">
            <el-select v-model="question.sectionId" clearable filterable>
              <el-option v-for="stage in sections" :key="stage.id" :label="stage.title"
                         :value="stage.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="removeLinkDialog = false">Cancel</el-button>
          <el-button type="primary" @click="removeLink()" class="ms-2">Remove</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="400">
      <template #header>
        <span>Delete Question</span>
      </template>
      <template #default>
        <span>Are you sure you want to delete </span><span class="text-danger">{{
          question?.title
        }} </span><span> ?.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDialog = false">Cancel</el-button>
          <button class="btn btn-sm btn-danger ms-2" @click="deleteQuestion()">Delete</button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div v-if="loading" class="spinner-border"></div>
</template>
<script>
import processService from "@/Services/ComponentsServices/processService";
import {ShowMessage} from "@/Utility/Utility";
import sectionService from "@/Services/ComponentsServices/sectionService";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import questionsService from "@/Services/ComponentsServices/questionsService";

export default {
  name: "QuestionsList",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      addDialog: false,
      assignDialog: false,
      removeLinkDialog: false,
      deleteDialog: false,
      sections: [],
      questions: [],
      question: {
        "id": 0,
        "title": "",
        "size": 0,
        "questionType": 0,
        "dependent": false,
        "officeUse": false,
        "yesNoDependent": false,
        "validator": 0,
        "cssClass": "",
        "sortOrder": 0,
        "isRequired": false,
        "isPartOfConfirmation": false,
        "dependUpon": "",
        "htmlContent": "",
        "answer": "",
        "description": "",
        "isDeleted": false,
        "deletedBy": "",
        "sectionId": ""
      },
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {
    getRowIndex(index) {
      return (this.filter.currentPage - 1) * this.filter.pageSize + index + 1;
    },
    editQuestion(section, type) {
      this.question = JSON.parse(JSON.stringify(section));

      switch (type) {
        case 'edit':
          this.$store.state.pageId = this.question.id;
          this.$router.push('/question');
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
        case 'assign':
          this.question.sectionId = "";
          this.getSections(1)
          this.assignDialog = true;
          break;
        case 'removeLink':
          this.question.sectionId = "";
          this.getSections(2)
          this.removeLinkDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getQuestions()
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getQuestions();
    },
    async getSections(linkType) {
      this.loading = true;
      try {
        let response = await sectionService.getFilteredSections(this.question.id, linkType);
        this.sections = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getQuestions() {
      this.loading = true;
      try {
        let response = await questionsService.all(this.filter);
        this.questions = response?.data?.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },

    async assignToSection() {
      this.loading = true;

      try {
        if(typeof this.question.sectionId === 'string' || this.question.sectionId < 1){
          ShowMessage('error', "Please select section");
          this.loading = false;
          return;
        }
        this.assignDialog = false;
        let response = await questionsService.assign(this.question.sectionId, this.question.id);
        if (response.status === 200) {
          ShowMessage("success", "Question assigned to section");

        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async removeLink() {
      this.loading = true;
      try {
        if (typeof this.question.sectionId === 'string' || this.question.sectionId < 1) {
          ShowMessage('error', "Please select section");
          this.loading = false;
          return;
        }

        let response = await questionsService.removeLink(this.question.sectionId, this.question.id);
        if (response.status === 200) {
          ShowMessage("success", response?.data?.message ?? "Section assigned to stage");

        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.loading = false;
      this.removeLinkDialog = false;
    },
    async deleteQuestion() {
      this.loading = true;
      this.deleteDialog = false;
      try {
        let response = await questionsService.delete(this.question.id);
        if (response.status === 200) {
          ShowMessage("success", "Question deleted")
        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.loading = false;
      await this.getProcesses();
    },
    async getProcesses() {
      this.loading = true;
      try {
        let response = await processService.getAll();
        this.processes = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
    },
    async goToAddQuestion() {
      this.$store.state.pageId = 0;
      this.$router.push('question')
    }
  },
  created() {
    this.getQuestions();
  }
}
</script>

<style scoped>

</style>