<template>
  <el-scrollbar class="custom-scrollable">
    <el-menu
        :collapse="collapsed"
        :router="true"
        background-color="#f3f3f3"
        class="el-menu-vertical-demo d-flex flex-column justify-content-between h-100"
        text-color="#000"

    >
      <div>
        <el-menu-item
            class="d-flex justify-content-center align-items-center"
            style="height: 60px"
        >
          <!--          <span class="mt-2" v-if="!$store.state.collapsed">LIGHT CRM</span>
                    <span class="mt-2" v-else>CRM</span>-->
          <img v-if="!collapsed" :src="logo" width="100" alt="Logo"/>
          <img v-else :src="icon" width="25" alt="Logo"/>
        </el-menu-item>
        <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
        <el-menu-item

            :class="{ 'active-menu-item': $route.path === '/dashboard' }"
            index="1"
            route="dashboard"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-grid"></i>
          </el-icon>
          <template #title>{{ getMessageByCode('dashboard') ?? 'Dashboard' }}</template>
        </el-menu-item>
        <el-menu-item
            v-if="role === 'Admin' || role === 'Approver'"
            :class="{ 'active-menu-item': $route.path === '/approvals' }"
            index="35"
            route="approvals"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-list-check"></i>
          </el-icon>
          <template #title>Approvals</template>
        </el-menu-item>

        <el-menu-item
            v-if="role === 'Admin'"
            :class="{ 'active-menu-item': $route.path === '/offers' }"
            index="16"
            route="offers"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-briefcase"></i>
          </el-icon>
          <template #title>Offers</template>
        </el-menu-item>
        <el-menu-item v-if="role === 'Admin'"
                      :class="{ 'active-menu-item': $route.path === '/applicants' }"
                      index="3"
                      route="applicants"
                      @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person"></i>
          </el-icon>
          <template #title>Applicants</template>
        </el-menu-item>
        <el-menu-item v-if="jobView && role === 'Admin'"
                      :class="{ 'active-menu-item': $route.path === '/jobs' }"
                      index="4"
                      route="jobs"
                      @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-briefcase"></i>
          </el-icon>
          <template #title>Jobs</template>
        </el-menu-item>
        <el-menu-item
            v-if="docsView  && role === 'Admin'"
            :class="{ 'active-menu-item': $route.path === '/docs' }"
            index="23"
            route="docs"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-file-word"></i>
          </el-icon>
          <template #title>Document Templates</template>
        </el-menu-item>
        <el-menu-item v-if="role === 'Admin'"
                      :class="{ 'active-menu-item': $route.path === '/invitations' }"
                      index="5"
                      route="invitations"
                      @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-share"></i>
          </el-icon>
          <template #title>{{ getMessageByCode('invites') ?? 'Invites' }}</template>
        </el-menu-item>
        <el-menu-item
            :class="{ 'active-menu-item': $route.path === '/profile' }"
            index="9"
            route="profile"
            @click="$emit('closeSidebar')"
        >
          <el-icon>
            <i class="bi bi-person-gear"></i>
          </el-icon>
          <template #title>{{ getMessageByCode('my_prof') ?? 'My Profile' }}</template>
        </el-menu-item>
        <el-sub-menu index="19" v-if="role === 'Admin'">
          <template #title>
            <el-icon>
              <i class="bi bi-files"></i>
            </el-icon>
            <span v-if="!$store.state.sidebarCollapsed">Applications</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
                :class="{ 'active-menu-item': $route.path === '/applications' }"
                class="mt-auto"
                index="21"
                route="applications"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-file-easel"></i>
              </el-icon>
              <template #title>Manage Applications</template>
            </el-menu-item>
            <el-menu-item
                :class="{ 'active-menu-item': $route.path === '/processes' }"
                class="mt-auto"
                index="22"
                route="processes"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-recycle"></i>
              </el-icon>
              <template #title>Manage Processes</template>
            </el-menu-item>
            <el-menu-item
                :class="{ 'active-menu-item': $route.path === '/stages' }"
                class="mt-auto"
                index="25"
                route="stages"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-bar-chart-steps"></i>
              </el-icon>
              <template #title>Manage Stages</template>
            </el-menu-item>
            <el-menu-item
                :class="{ 'active-menu-item': $route.path === '/sections' }"
                class="mt-auto"
                index="26"
                route="sections"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-layers"></i>
              </el-icon>
              <template #title>Manage Sections</template>
            </el-menu-item>
            <el-menu-item
                :class="{ 'active-menu-item': $route.path === '/questions' }"
                class="mt-auto"
                index="27"
                route="questions"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-patch-question"></i>
              </el-icon>
              <template #title>Manage Question</template>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <!--        <el-sub-menu index="19" v-if="reportView">-->
        <!--          <template #title>-->
        <!--            <el-icon>-->
        <!--              <i class="bi bi-printer"></i>-->
        <!--            </el-icon>-->
        <!--            <span v-if="!$store.state.sidebarCollapsed">{{ getMessageByCode('reports') ?? 'Reports' }}</span>-->
        <!--          </template>-->
        <!--          <el-menu-item-group>-->
        <!--            <el-menu-item-->
        <!--                v-if="docExpView"-->
        <!--                :class="{ 'active-menu-item': $route.path === '/document_expiry' }"-->
        <!--                class="mt-auto"-->
        <!--                index="21"-->
        <!--                route="document_expiry"-->
        <!--                @click="$emit('closeSidebar')"-->
        <!--            >-->
        <!--              <el-icon>-->
        <!--                <i class="bi bi-file-earmark-x"></i>-->
        <!--              </el-icon>-->
        <!--              <template #title>{{ getMessageByCode('doc_exp') ?? 'Documents Expiry' }}</template>-->
        <!--            </el-menu-item>-->
        <!--            <el-menu-item-->
        <!--                v-if="missDocView"-->
        <!--                :class="{ 'active-menu-item': $route.path === '/missing_documents' }"-->
        <!--                class="mt-auto"-->
        <!--                index="22"-->
        <!--                route="missing_documents"-->
        <!--                @click="$emit('closeSidebar')"-->
        <!--            >-->
        <!--              <el-icon>-->
        <!--                <i class="bi bi-file-earmark"></i>-->
        <!--              </el-icon>-->
        <!--              <template #title>{{ getMessageByCode('miss_documents') ?? 'Missing Documents' }}</template>-->
        <!--            </el-menu-item>-->
        <!--          </el-menu-item-group>-->
        <!--        </el-sub-menu>-->
        <!--        <el-menu-item-->
        <!--            v-if="docsView"-->
        <!--            :class="{ 'active-menu-item': $route.path === '/documents' }"-->
        <!--            index="8"-->
        <!--            route="documents"-->
        <!--            @click="$emit('closeSidebar')"-->
        <!--        >-->
        <!--          <el-icon>-->
        <!--            <i class="bi bi-folder"></i>-->
        <!--          </el-icon>-->
        <!--          <template #title><span class="text-capitalize">{{ getMessageByCode('documents') ?? 'Documents' }}</span>-->
        <!--          </template>-->
        <!--        </el-menu-item>-->


        <el-sub-menu index="30" v-if="role === 'Admin'">
          <template #title>
            <el-icon>
              <i class="bi bi-sliders"></i>
            </el-icon>
            <span v-if="!$store.state.sidebarCollapsed"
                  class="text-capitalize">{{ getMessageByCode('more') ?? 'More' }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
                v-if="optionView"
                :class="{ 'active-menu-item': $route.path === '/options' }"
                class="mt-auto"
                index="11"
                route="options"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-sliders"></i>
              </el-icon>
              <template #title>{{ getMessageByCode('options') ?? 'Options' }}</template>
            </el-menu-item>
            <el-menu-item
                v-if="userView"
                :class="{ 'active-menu-item': $route.path === '/users' }"
                class="mt-auto"
                index="12"
                route="users"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-people"></i>
              </el-icon>
              <template #title>{{ getMessageByCode('users') ?? 'Users' }}</template>
            </el-menu-item>
            <el-menu-item
                v-if="roleView"
                :class="{ 'active-menu-item': $route.path === '/roles' }"
                class="mt-auto"
                index="13"
                route="roles"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-people"></i>
              </el-icon>
              <template #title><span class="text-capitalize">{{ getMessageByCode('roles_list') ?? 'Roles List' }}</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="templateView"
                :class="{ 'active-menu-item': $route.path === '/templates' }"
                class="mt-auto"
                index="14"
                route="templates"
                @click="$emit('closeSidebar')"
            >
              <el-icon>
                <i class="bi bi-envelope"></i>
              </el-icon>
              <template #title><span class="text-capitalize">{{ getMessageByCode('templates') ?? 'Templates' }}</span>
              </template>
            </el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
      </div>
      <el-menu-item
          v-if="role === 'Admin'"
          :class="{ 'active-menu-item': $route.path === '/settings' }"
          class="mt-auto"
          index="15"
          route="settings"
          @click="$emit('closeSidebar')"
      >
        <el-icon>
          <i class="bi bi-gear"></i>
        </el-icon>
        <template #title><span class="text-capitalize">{{ getMessageByCode('settings') ?? 'Settings' }}</span>
        </template>
      </el-menu-item>
    </el-menu>
  </el-scrollbar>

</template>

<script>
import configService from "@/Services/ComponentsServices/configService";
import {mapState} from "vuex";
import {checkPermission} from "@/Utility/CheckPermission";
import {getLanguage} from "@/Utility/getLanguage";
import {getApplicantIdFromSession} from "@/Utility/sessionHelper";

export default {
  name: "SideBar",
  data() {
    return {
      applicantId: getApplicantIdFromSession(),
      role: sessionStorage.getItem("role"),
      translations: [],
      logo: "",
      icon: "",
      collapsed: false,
      providerView: false,
      propertyView: false,
      reportView: false,
      jobView: false,
      clientView: false,
      roomView: false,
      tenantView: false,
      complianceView: false,
      userView: false,
      roleView: false,
      templateView: false,
      docExpView: false,
      missDocView: false,
      roomReportView: false,
      tenantReportView: false,
      optionView: false,
      docsView: false,
      profileView: false,
      profileEdit: false,
    }
  },
  computed: {
    ...mapState(["sidebarCollapsed"]), // Map the state to a local computed property
  },
  watch: {
    sidebarCollapsed(newVal) {
      this.collapsed = !!newVal;
    },
  },
  methods: {
    async getTitle() {
      try {
        const logoNormal = await configService.getByKey('BrandLogoNormal');
        const icon = await configService.getByKey('BrandIcon');
        if (logoNormal.data.value !== undefined) {
          // Replace with your actual API endpoint
          this.logo = logoNormal.data.value
        }
        if (icon.data.value !== undefined) {
          // Replace with your actual API endpoint
          this.icon = icon.data.value
        }

      } catch (error) {
        console.error('Error fetching app data', error);
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.jobView = checkPermission("MD_JOB_VIEW");
    this.providerView = checkPermission("MD_PRO_VIEW");
    this.propertyView = checkPermission("MD_PROP_VIEW");
    this.reportView = checkPermission("MD_REPORT_VIEW");
    this.clientView = checkPermission("MD_CLIENT_VIEW");
    this.roomView = checkPermission("MD_ROOM_VIEW");
    this.tenantView = checkPermission("MD_TEN_VIEW");
    this.complianceView = checkPermission("MD_COMP_VIEW");
    this.userView = checkPermission("MD_USER_VIEW");
    this.roleView = checkPermission("MD_ROLE_VIEW");
    this.tenantView = checkPermission("MD_TEN_VIEW");
    this.missDocView = checkPermission("MD_DOC_MISS_VIEW");
    this.docExpView = checkPermission("MD_DOC_EXP_VIEW");
    this.tenantReportView = checkPermission("MD_TEN_REPORT_VIEW");
    this.roomReportView = checkPermission("MD_ROOM_REP_VIEW");
    this.templateView = checkPermission("MD_TEMPLATE_VIEW");
    this.optionView = checkPermission("MD_LIST_VIEW");
    this.docsView = checkPermission("MD_DOCUMENT_VIEW");
    this.profileView = checkPermission("MD_USER_PROFILE_VIEW");
    this.profileEdit = checkPermission("MD_USER_PROFILE_EDIT");
    this.getTitle(sessionStorage.getItem("clientId") ?? 0);
  }
};
</script>

<style scoped>
.custom-scrollable {
  height: 100vh !important;
}

.active-menu-item {
  position: relative;
  background-color: #2A5699 !important;
  padding: 20px;
  color: #fff;
  transition: background-color 0.1s;
}
.el-menu-item.is-active {
  color: #fff;
  background-color: #2A5699 !important;
}
/*.active-menu-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  background-color: #0a53be;
  transform: translateY(0);
  transition: transform 0.5s ease;
}*/

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 100vh !important;
}

.el-scrollbar__view {
  height: 100% !important;
}
.active-menu-item[data-v-54f4e01e] {
  position: relative;
  background-color: #2A5699 !important;
  padding: 20px;
  color: #fff !important;
  transition: background-color 0.1s;
}
</style>
