import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    allUrl: Config.apiBaseUrl + "process/getprocesses?search=",
    getAllUrl: Config.apiBaseUrl + "process/getprocesses?limit=1500",
    getByIdUrl: Config.apiBaseUrl + "process/getprocessbyid?processId=",
    addUrl: Config.apiBaseUrl + "process/add",
    updateUrl: Config.apiBaseUrl + "process/update",
    deleteUrl: Config.apiBaseUrl + "process/delete?processId=",
    softDeleteUrl: Config.apiBaseUrl + "process/softdelete?processId=",
    filteredProcessesUrl : Config.apiBaseUrl + "process/getfilteredprocesses?stageId=",
    linkStagesUrl: Config.apiBaseUrl + "process/linkstages?processId=",
    all(filter) {
        return axios.get(this.allUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(process) {
        return axios.post(this.addUrl, process);
    },
    update(process) {
        return axios.put(this.updateUrl, process);
    },
    delete(processId) {
        return axios.delete(this.deleteUrl + processId);
    },
    softDelete(processId) {
        return axios.delete(this.softDeleteUrl + processId);
    },
    getFilteredProcesses(stageId,linkType){
        return axios.get(this.filteredProcessesUrl+stageId+"&linkType="+linkType);
    },
    linkStages(processId,processStages){
        return axios.post(this.linkStagesUrl+processId, processStages);
    }
}