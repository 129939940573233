import Config from "@/Configuration/Config";
import axios from 'axios'

export default {
    allUrl: Config.apiBaseUrl + "client/getclients?search=",
    getUserUrl: Config.apiBaseUrl + "user/getuserbyid?userId=",
    authUrl: Config.apiBaseUrl + "user/authenticate",
    resetUrl: Config.apiBaseUrl + "user/resetpassword?username=",
    changePasswordUrl: Config.apiBaseUrl + "user/passwordchange?username=",
    getPermissionsUrl: Config.apiBaseUrl + "user/getuserpermissions",
    timestamp : new Date().getTime(),
    getUser(userId) {
        return axios.get(this.getUserUrl + userId);
    },
    authenticate(user) {
        return axios.post(this.authUrl , user);
    },
    resetPassword(username){
        return axios.post(this.resetUrl+ username)
    },
    changePassword(user){
        return axios.post(this.changePasswordUrl+user.userName+"&password="+user.password)
    },
    getPermissions() {
        return axios.get(this.getPermissionsUrl);
    }
}