import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    allUrl: Config.apiBaseUrl + "applicant/getapplicants?search=",
    getAllUrl: Config.apiBaseUrl + "applicant/getapplicants?limit=1500",
    getByIdUrl: Config.apiBaseUrl + "applicant/getapplicantbyid?applicantId=",
    addUrl: Config.apiBaseUrl + "applicant/add",
    updateUrl: Config.apiBaseUrl + "applicant/update",
    deleteUrl: Config.apiBaseUrl + "applicant/delete?applicantId=",


    all(filter) {
        return axios.get(this.allUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(applicant) {
        return axios.post(this.addUrl, applicant);
    },
    update(applicant) {
        return axios.put(this.updateUrl, applicant);
    },
    delete(applicantId) {
        return axios.delete(this.deleteUrl + applicantId);
    },
}