import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    allUrl: Config.apiBaseUrl + "application/getapplications?search=",
    getAllUrl: Config.apiBaseUrl + "application/getapplications?limit=1500",
    getByIdUrl: Config.apiBaseUrl + "application/getapplicationbyid?applicationId=",
    addUrl: Config.apiBaseUrl + "application/add",
    updateUrl: Config.apiBaseUrl + "application/update",
    deleteUrl: Config.apiBaseUrl + "application/delete?applicationId=",
    softDeleteUrl: Config.apiBaseUrl + "application/softdelete?applicationId=",
    viewApplicationUrl: Config.apiBaseUrl + "application/view?applicationId=",
    submitUrl: Config.apiBaseUrl + "application/submit?status=",
    submitStageUrl: Config.apiBaseUrl + "stage/submit?stageId=",
    saveUrl: Config.apiBaseUrl + "application/save",
    updateStatusUrl: Config.apiBaseUrl + "application/updatestatus?applicationId=",
    downloadAttachmentsUrl: Config.apiBaseUrl + "application/download/attachments?applicationId=",
    all(filter) {
        return axios.post(this.allUrl, filter);
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(application) {
        return axios.post(this.addUrl, application);
    },
    update(application) {
        return axios.put(this.updateUrl, application);
    },
    delete(applicationId) {
        return axios.delete(this.deleteUrl + applicationId);
    },
    softDelete(applicationId) {
        return axios.delete(this.softDeleteUrl + applicationId);
    },
    viewApplication(applicationId) {
        return axios.get(this.viewApplicationUrl + applicationId);
    },
    submit(status, application) {
        return axios.post(this.submitUrl + status, application)
    },
    submitStage(stageId, questions) {
        return axios.post(this.submitStageUrl + stageId, questions)
    },
    save(application) {
        return axios.post(this.saveUrl, application)
    },
    updateStatus(applicationId, status) {
        return axios.get(this.updateStatusUrl + applicationId + "&status=" + status)
    },
    downloadAttachments(applicationId) {
        return axios.get(this.downloadAttachmentsUrl + applicationId)
    }
}