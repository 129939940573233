<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-6">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-end">
          <el-select v-model="filter.status" class="w-50" @change="getApplications()">
            <el-option v-for="status in statuses" :key="status.value" :label="status.text"
                       :value="status.value"></el-option>
          </el-select>
          <el-input v-model="filter.search" placeholder="Search here..." class="ms-2" @keyup="getApplications()">
            <template #append>
              <el-button @click="getApplications()">
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>
          <!--          <el-button @click="resetApplications()"-->
          <!--                     class="border-0 mbtn-primary ms-1 text-capitalize">Add Application-->
          <!--          </el-button>-->
        </div>
      </div>
    </div>
    <el-table :data="applications" :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe>
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            small
        >
        </el-pagination>
      </template>
      <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
      <el-table-column label="Title" prop="title" sortable></el-table-column>
      <el-table-column label="Job" prop="job"></el-table-column>
      <el-table-column label="Applicant" prop="applicant"></el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="Status" width="120">
        <template #default="scope">
          <span>{{ getStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="Actions" width="150">
        <template #default="scope">
          <n-space size="small">
            <el-tooltip content="View Application">
              <i
                  class="mgc_eye_2_line text-primary action-btn"
                  @click="editApplication(scope.row.id,'view')"
              >
              </i>
            </el-tooltip>
            <el-tooltip content="Delete">
              <i @click="editApplication(scope.row.id,'del')"
                 class="mgc_delete_2_line text-danger action-btn"
              ></i>
            </el-tooltip>
            <el-tooltip content="Print">
              <i @click="editApplication(scope.row.id,'pre')"
                 class="mgc_print_fill text-secondary action-btn"
              ></i>
            </el-tooltip>
            <el-tooltip content="Download Attachments">
              <i @click="editApplication(scope.row.id,'attach')"
                 class="mgc_download_2_fill text-secondary action-btn"
              ></i>
            </el-tooltip>
            <el-tooltip content="Change Status">
              <el-dropdown>
                <i class="bi bi-three-dots-vertical action-btn"
                ></i>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="updateStatus(scope.row.id, 6)">
                      <i class="bi bi-check-circle-fill text-success me-2"></i>
                      <span>Approve</span>
                    </el-dropdown-item>
                    <el-dropdown-item @click="updateStatus(scope.row.id, 7)">
                      <i class="bi bi-x-circle-fill text-danger me-2"></i>
                      <span>Reject</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-tooltip>
          </n-space>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog v-model="addDialog" width="350">
      <template #header>
        <h5 v-if="application.applicationId < 1">Add Application</h5>
        <h5 v-else>Edit Application</h5>
      </template>
      <template #default>
        <el-form label-position="top" hide-required-asterisk>
          <div class="row">
            <el-form-item label="Title">
              <el-input v-model="application.title"></el-input>
            </el-form-item>
            <el-form-item label="Job">
              <el-select v-model="application.inviteId" @change="changeInvite()">
                <el-option v-for="item in invites" :key="item.id" :value="item.id" :label="item.job"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Description">
              <el-input :rows="3"
                        type="textarea" v-model="application.description"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="addDialog = false">Cancel</el-button>
            <el-button type="primary" :disabled="!application.title || application.jobId < 1"
                       v-if="application.applicationId < 1"
                       @click="addApplicant()">
              Add
            </el-button>
            <el-button type="primary" v-else @click="updateApplication()">Update
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="450">
      <template #header>
        <span>Delete Application</span>
      </template>
      <template #default>
        <span>Do you want to delete</span> <span class="fw-bold text-danger">{{ application?.title }} </span>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="deleteDialog = false">Cancel</el-button>
            <button class="btn btn-sm ms-1 px-3 btn-danger" @click="deleteApplication()">Delete
            </button>
          </div>
        </div>
      </template>
    </el-dialog>

    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import inviteService from "@/Services/ComponentsServices/inviteService";
import applicationService from "@/Services/ComponentsServices/applicationService";
import {ShowMessage} from "@/Utility/Utility";
import {NSpace} from "naive-ui";

export default {
  name: "ApplicationsList",
  components: {NSpace, BreadCrumb},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      invites: [],
      applications: [],
      application: {
        "id": 0,
        "title": "",
        "description": "",
        "jobId": "",
        "inviteId": "",
      },
      statuses: [
        {text: "All", value: -1},
        {text: "Not Started", value: 0},
        {text: "In Progress", value: 1},
        {text: "Submitted", value: 3},
        {text: "Completed", value: 4},
        {text: "Approved", value: 6},
        {text: "Rejected", value: 7},
      ],
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: -1,
      }
      ,
    }
  },
  methods: {

    getStatus(status) {
      return this.statuses.find(x => x.value === status).text ?? "";
    },
    editApplication(appId, type) {
      this.application = {...this.applications.find(x => x.id === Number(appId))};
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'del':
          this.deleteDialog = true;
          break;
        case 'view':
          this.$store.state.pageId = this.application.id;
          this.$store.state.applicationReadOnly = false;
          this.$router.push('/application')
          break;
        case 'pre':
          this.openPrintPreview();
          break;
        case 'attach':
          this.downloadAttachments(this.application.id);
          break;
      }
    },
    async downloadAttachments(applicationId) {
      this.loading = true;
      try {
        let response = await applicationService.downloadAttachments(applicationId);
        if (response?.data?.length > 0) {
          response?.data.forEach(file => {
            console.log(file)
            this.downloadFile(file);
          })
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Something went wrong");
      }
      this.loading = false;
    },
    openPrintPreview() {
      const routeData = this.$router.resolve({
        name: 'PrintPreview',
        query: {id: this.application.id},
      });
      window.open(routeData.href, '_blank');
    },
    async updateStatus(applicationId, status) {
      this.loading = true;
      try {
        let response = await applicationService.updateStatus(applicationId, status);
        if (response?.data?.data === true) {
          ShowMessage("success", "Application status updated");
          await this.getApplications();
        } else {
          ShowMessage("error", "Application status update failed");

        }
      } catch (e) {
        ShowMessage("error", e?.response?.data?.message ?? "Something went wrong");
      }
      this.loading = false;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getApplications();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getApplications();
    },

    changeInvite() {
      this.application.jobId = this.invites.find(x => x.id === this.application.inviteId)?.jobId;
    },
    async addApplicant() {

      this.loading = true;
      try {
        if (this.application.jobId < 1) {
          ShowMessage("error", "Please select a job first")
          return;
        }

        let response = await applicationService.add(this.application);
        if (response.status === 200) {
          this.addDialog = false;
          ShowMessage("success", "Application added");
          await this.getApplications();
        }
      } catch (e) {
        console.log(e)
      }

      this.loading = false;
    },
    async updateApplication() {
      this.loading = true;
      try {
        if (this.application.jobId < 1) {
          ShowMessage("error", "Please select a job first")
          return;
        }
        let response = await applicationService.update(this.application);
        if (response.status === 200) {
          this.addDialog = false;
          ShowMessage("success", "Application added");
          await this.getApplications();
        }
      } catch (e) {
        console.log(e)
      }

      this.loading = false;
    },
    async getApplications() {
      this.loading = true;
      try {
        let response = await applicationService.all(this.filter);
        this.applications = response?.data?.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async deleteApplication() {
      this.loading = true;
      try {
        let response = await applicationService.softDelete(this.application.applicationId);
        if (response.status === 200) {
          ShowMessage("success", "Application Deleted");
          await this.getApplications();
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getInvites() {
      try {
        let response = await inviteService.getAll();
        this.invites = response?.data?.items
      } catch (e) {
        console.log(e)
      }
    },
    getMimeType(extension) {
      const mimeTypes = {
        // Images
        '.png': 'image/png',
        '.jpg': 'image/jpeg',
        '.jpeg': 'image/jpeg',
        '.gif': 'image/gif',
        '.bmp': 'image/bmp',
        '.webp': 'image/webp',
        '.svg': 'image/svg+xml',
        '.tiff': 'image/tiff',
        '.ico': 'image/vnd.microsoft.icon',

        // Text
        '.txt': 'text/plain',
        '.csv': 'text/csv',
        '.html': 'text/html',
        '.css': 'text/css',
        '.js': 'application/javascript',
        '.json': 'application/json',
        '.xml': 'application/xml',
        '.md': 'text/markdown',

        // Documents
        '.pdf': 'application/pdf',
        '.doc': 'application/msword',
        '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.odt': 'application/vnd.oasis.opendocument.text',

        // Spreadsheets
        '.xls': 'application/vnd.ms-excel',
        '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '.ods': 'application/vnd.oasis.opendocument.spreadsheet',

        // Presentations
        '.ppt': 'application/vnd.ms-powerpoint',
        '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        '.odp': 'application/vnd.oasis.opendocument.presentation',

        // Audio
        '.mp3': 'audio/mpeg',
        '.wav': 'audio/wav',
        '.ogg': 'audio/ogg',
        '.m4a': 'audio/mp4',
        '.flac': 'audio/flac',
        '.aac': 'audio/aac',

        // Video
        '.mp4': 'video/mp4',
        '.mkv': 'video/x-matroska',
        '.webm': 'video/webm',
        '.avi': 'video/x-msvideo',
        '.mov': 'video/quicktime',
        '.wmv': 'video/x-ms-wmv',

        // Archives
        '.zip': 'application/zip',
        '.rar': 'application/vnd.rar',
        '.7z': 'application/x-7z-compressed',
        '.tar': 'application/x-tar',
        '.gz': 'application/gzip',

        // Fonts
        '.ttf': 'font/ttf',
        '.otf': 'font/otf',
        '.woff': 'font/woff',
        '.woff2': 'font/woff2',

        // Others
        '.exe': 'application/vnd.microsoft.portable-executable',
        '.iso': 'application/x-iso9660-image',
        '.dmg': 'application/x-apple-diskimage',
        '.apk': 'application/vnd.android.package-archive',
        '.bin': 'application/octet-stream',
        '.dll': 'application/vnd.microsoft.portable-executable'
      };
      return mimeTypes[extension] || 'application/octet-stream';
    },
    downloadFile(file) {
      // Get the MIME type based on the file extension
      const mimeType = this.getMimeType(file.fileExtension);

      // Convert base64 content to a Blob
      const byteCharacters = atob(file.value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeType });
      // Create an anchor element
      const link = document.createElement('a');
      // Create a URL for the blob and set it as the href attribute
      link.href = URL.createObjectURL(blob);
      // Set the download attribute with the file name and extension
      link.download = file.fileName;
      // Append the anchor to the body
      document.body.appendChild(link);

      // Trigger a click on the anchor
      link.click();

      // Remove the anchor from the body
      document.body.removeChild(link);
    },

  },
  created() {
    this.getApplications();
    this.getInvites();
  }
}
</script>

<style scoped>

</style>