<template>
  <div>
    <div v-if="!isOnline" class="notification no-internet" ref="notificationDiv">
      No internet connection
    </div>
    <div v-else-if="showOnlineMessage" class="notification online">
      Back online
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue';

export default {
  name: 'InternetStatus',
  setup() {
    const isOnline = ref(navigator.onLine);
    const showOnlineMessage = ref(false);
    const notificationDiv = ref(null);

    const updateOnlineStatus = () => {
      isOnline.value = navigator.onLine;
      if (isOnline.value) {
        showOnlineMessage.value = true;
        setTimeout(() => {
          showOnlineMessage.value = false;
        }, 2000);
      } else {
        scrollNotificationDiv();
      }
    };

    const scrollNotificationDiv = () => {
      if (notificationDiv.value) {
        notificationDiv.value.scrollIntoView({ behavior: 'smooth' });
      }
    };

    onMounted(() => {
      window.addEventListener('online', updateOnlineStatus);
      window.addEventListener('offline', updateOnlineStatus);
      if (!navigator.onLine) {
        scrollNotificationDiv();
      }
    });

    onUnmounted(() => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    });

    watch(isOnline, (newStatus) => {
      if (!newStatus) {
        scrollNotificationDiv();
      }
    });

    return {
      isOnline,
      showOnlineMessage,
      notificationDiv,
    };
  },
};
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 80%;
  max-width: 300px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
}

.no-internet {
  background-color: #232323;
  color: white;
}

.online {
  background-color: green;
  color: white;
}
</style>
