<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-7">
          <BreadCrumb :showBread="false" />
        </div>
        <div class="col-12 col-md-5 d-flex justify-content-end">
          <el-input
            v-model="filter.search"
            placeholder="Search here..."
            @keyup="getProcesses()"
          >
            <template #append>
              <el-button @click="getProcesses()">
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>
          <el-button
            @click="
              resetProcess();
              addDialog = true;
            "
            class="border-0 mbtn-primary ms-1 text-capitalize"
            >Add Process
          </el-button>
        </div>
      </div>
    </div>
    <el-table
      :data="processes"
      :fit="true"
      :flexible="true"
      :selectable="(row) => row.status !== 'disabled'"
      border
      header-cell-class-name="tbl-header"
      stripe
    >
      <template #append>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="filter.totalCount"
          :page="filter.currentPage"
          :page-size="filter.pageSize"
          :page-sizes="[10, 20, 30, 40]"
          layout=" prev, pager, next,sizes"
          class="bg-gray p-2"
          background
          small
        >
        </el-pagination>
      </template>
      <el-table-column
        label="#"
        type="index"
        align="center"
        width="55"
      ></el-table-column>
      <el-table-column
        label="Title"
        prop="title"
        width="400"
        sortable
      ></el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="Status" prop="processStatus"></el-table-column>
      <el-table-column align="center" fixed="right" label="Actions" width="120">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <i
              class="mgc_edit_line text-primary action-btn"
              @click="editProcess(scope.row, 'edit')"
            >
            </i>
            <el-tooltip content="Manage process stages">
              <i
                class="mgc_unlink_line text-secondary action-btn"
                @click="editProcess(scope.row, 'stage')"
              >
              </i>
            </el-tooltip>
            <i
              @click="editProcess(scope.row, 'delete')"
              class="mgc_delete_2_line text-danger action-btn"
            ></i>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-dialog v-model="addDialog" width="400">
    <template #header>
      <span v-if="process.id < 1">Add Process</span>
      <span v-else>Update Process</span>
    </template>
    <template #default>
      <el-form label-position="top">
        <el-form-item label="Title">
          <el-input v-model="process.title"></el-input>
        </el-form-item>
        <el-form-item label="Description">
          <el-input
            type="textarea"
            :rows="7"
            v-model="process.description"
          ></el-input>
        </el-form-item>
      </el-form>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <el-button bg text @click="addDialog = false">Cancel</el-button>
        <el-button
          v-if="process.id < 1"
          type="primary"
          @click="addProcess"
          class="ms-2"
          >Add</el-button
        >
        <el-button v-else type="primary" @click="updateProcess()" class="ms-2"
          >Update</el-button
        >
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="stageDialog" width="500" draggable>
    <template #header>
      <span>{{ process?.title ?? "Stage Process" }}</span>
    </template>
    <template #default>
      <el-form label-position="top">
        <div class="row">
          <div class="col-12">
            <el-form-item>
              <template #label>
                <span>Stages</span>
              </template>
              <el-select
                v-model="process.stages"
                multiple
                collapse-tags
                collapse-tags-tooltip
                value-key="title"
              >
                <el-option
                  v-for="(stage, index) in stages"
                  :label="stage.title"
                  :key="stage.stageId"
                  :value="stage"
                >
                  <div class="d-flex align-items-center">
                    <el-collapse accordion @click.stop style="width: 80%">
                      <el-collapse-item :title="stage.title" @click.stop>
                        <template #title>
                          <span
                            :class="{
                              selected_collapse: isStageFound(stage.stageId),
                            }"
                            >{{ stage.title }}</span
                          >
                        </template>
                        <el-divider
                          border-style="dashed"
                          class="my-0"
                        ></el-divider>
                        <div class="row" @click.stop>
                          <el-form label-position="top">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-6">
                                  <el-form-item class="mb-0">
                                    <template #label>
                                      <span class="text-small"
                                        >Is Required</span
                                      >
                                    </template>
                                    <el-switch
                                      size="small"
                                      v-model="stage.isRequired"
                                      @change="updateStage(stage)"
                                      @click.stop
                                    ></el-switch>
                                  </el-form-item>
                                </div>
                                <div class="col-6">
                                  <el-form-item class="mb-0">
                                    <template #label>
                                      <span class="text-small"
                                        >Required Submission</span
                                      >
                                    </template>
                                    <el-switch
                                      size="small"
                                      v-model="stage.requiredSubmission"
                                      @change="updateStage(stage)"
                                      @click.stop
                                    ></el-switch>
                                  </el-form-item>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="row">
                                <div class="col-6">
                                  <el-form-item class="mb-0">
                                    <template #label>
                                      <span class="text-small"
                                        >Required Approval</span
                                      >
                                    </template>
                                    <el-switch
                                      size="small"
                                      @change="changeRequiredApproval(stage)"
                                      v-model="stage.requiredApproval"
                                      @click.stop
                                    ></el-switch>
                                  </el-form-item>
                                </div>
                                <div
                                  class="col-6"
                                  v-if="stage.requiredApproval"
                                >
                                  <el-form-item class="mb-0">
                                    <template #label>
                                      <span class="text-small"
                                        >Partial Approval</span
                                      >
                                    </template>
                                    <el-switch
                                      size="small"
                                      v-model="stage.partialApproval"
                                      @change="updateStage(stage)"
                                      @click.stop
                                    ></el-switch>
                                  </el-form-item>
                                </div>
                              </div>
                            </div>
                            <div class="col-12" v-if="stage.requiredApproval">
                              <el-form-item>
                                <template #label>
                                  <span class="text-small">Approvers</span>
                                </template>
                                <div class="w-100">
                                  <table
                                    class="table table-sm border table-hover table-striped"
                                    style="max-height: 180px"
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col" class="mfw-bold"></th>
                                        <th
                                          scope="col"
                                          class="mfw-bold w-50 text-caption"
                                        >
                                          Name
                                        </th>
                                        <th scope="col" class="mfw-bold w-50">
                                          <el-input
                                            size="small"
                                            v-model="search"
                                            clearable
                                          ></el-input>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody v-if="computedApprovers.length > 0">
                                      <tr
                                        v-for="app in computedApprovers"
                                        :key="app.id"
                                      >
                                        <td
                                          @click="toggleApprover(stage, app.id)"
                                          class="text-center"
                                        >
                                          <i
                                            v-if="
                                              isApproverFound(
                                                stage.approvers,
                                                app.id
                                              )
                                            "
                                            class="bi bi-check text-success"
                                          ></i>
                                          <span v-else>&nbsp;</span>
                                        </td>
                                        <td
                                          @click="toggleApprover(stage, app.id)"
                                        >
                                          <span class="text-caption">{{
                                            app.displayName
                                          }}</span>
                                        </td>
                                        <td
                                          @click="toggleApprover(stage, app.id)"
                                        ></td>
                                      </tr>
                                    </tbody>
                                    <tbody v-else>
                                      <tr>
                                        <td
                                          rowspan="3"
                                          colspan="3"
                                          class="text-center"
                                        >
                                          <span class="text-secondary"
                                            >No Data</span
                                          >
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </el-form-item>
                            </div>
                          </el-form>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                    <el-space class="ms-2" direction="horizontal" size="small">
                      <n-button
                        @click.stop="moveUp(index)"
                        :disabled="index === 0"
                        strong
                        tertiary
                        size="tiny"
                      >
                        <i class="bi bi-chevron-up"></i>
                      </n-button>
                      <n-button
                        @click.stop="moveDown(index)"
                        :disabled="index === stages.length - 1"
                        strong
                        tertiary
                        size="tiny"
                      >
                        <i class="bi bi-chevron-down"></i>
                      </n-button>
                    </el-space>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <div class="d-flex justify-content-end">
        <el-button bg text @click="stageDialog = false">Cancel</el-button>
        <el-button type="primary" @click="linkStages()" class="ms-2"
          >Add to process</el-button
        >
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="editStageDialog" width="400" align-center draggable>
    <template #header>
      <span>Edit Stage</span>
    </template>
    <template #default>
      <div class="row">
        <el-form label-position="top">
          <div class="col-12">
            <el-form-item>
              <template #label> Title </template>
              <el-input disabled v-model="stage.title"></el-input>
            </el-form-item>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <el-form-item>
                  <template #label> Is Required </template>
                  <el-switch v-model="stage.isRequired"></el-switch>
                </el-form-item>
              </div>
              <div class="col-6">
                <el-form-item>
                  <template #label> Required Submission </template>
                  <el-switch v-model="stage.requiredSubmission"></el-switch>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <el-form-item>
                  <template #label> Required Approval </template>
                  <el-switch
                    v-model="stage.requiredApproval"
                    @change="changeRequiredApproval(stage)"
                  ></el-switch>
                </el-form-item>
              </div>
              <div class="col-6">
                <el-form-item v-if="stage.requiredApproval">
                  <template #label> Partial Approval? </template>
                  <el-switch v-model="stage.partialApproval"></el-switch>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="col-12">
            <el-form-item v-if="stage.requiredApproval">
              <template #label> Approvers </template>
              <el-table :data="approvers">
                <el-table-column label="Title" prop="title"></el-table-column>
              </el-table>

              <!--              <el-select v-model="stage.approvers" multiple>-->
              <!--                <el-option v-for="approver in approvers" :key="approver.id" :label="approver.displayName"-->
              <!--                           :value="approver.id"></el-option>-->
              <!--              </el-select>-->
            </el-form-item>
          </div>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <el-button bg text @click="editStageDialog = false">Cancel</el-button>
        <el-button type="primary" @click="addProcess" class="ms-"
          >Add to process</el-button
        >
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="deleteDialog" width="400">
    <template #header>
      <span>Delete Process</span>
    </template>
    <template #default>
      <span>Are you sure you want to delete </span
      ><span class="text-danger">{{ process?.title }} </span><span> ?.</span>
    </template>
    <template #footer>
      <div class="d-flex justify-content-end">
        <el-button bg text @click="deleteDialog = false">Cancel</el-button>
        <button class="btn btn-sm btn-danger ms-2" @click="deleteProcess()">
          Delete
        </button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import processService from "@/Services/ComponentsServices/processService";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import optionService from "@/Services/ComponentsServices/optionService";
import { ShowMessage } from "@/Utility/Utility";
import stageService from "@/Services/ComponentsServices/stageService";
import userService from "@/Services/ComponentsServices/userService";
import { NButton } from "naive-ui";

export default {
  name: "ProcessList",
  components: { BreadCrumb, NButton },
  data() {
    return {
      loading: false,
      addDialog: false,
      editStageDialog: false,
      stageDialog: false,
      deleteDialog: false,
      activeStage: 1,
      statuses: [],
      processes: [],
      stages: [],
      expandedRows: [],
      stage: {},
      expandedStages: {},
      approvers: [],
      process: {
        id: 0,
        title: "",
        description: "",
        status: 0,
      },
      search: "",
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    };
  },
  computed: {
    computedApprovers() {
      return this.approvers.filter((data) =>
        data.displayName.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    moveUp(index) {
      if (index > 0) {
        this.swap(index, index - 1);
      }
    },

    moveDown(index) {
      if (index < this.stages.length - 1) {
        this.swap(index, index + 1);
      }
    },
    swap(index1, index2) {
      const temp = this.stages[index1];
      this.stages[index1] = this.stages[index2];
      this.stages[index2] = temp;
      this.updateSortNumbers();
    },
    updateSortNumbers() {
      this.stages.forEach((option, index) => {
        option.sortOrder = index + 1;
      });
    },
    sortStages() {
      this.stages = this.stages.sort((a, b) => a.sortOrder - b.sortOrder);
    },
    isStageFound(stageId) {
      return this.process.stages.some((stage) => stage.stageId === stageId);
    },
    isApproverFound(approvers, approverId) {
      return approvers.includes(approverId);
    },
    pushApprover(approvers, approverId) {
      const index = approvers.indexOf(approverId);
      if (index === -1) {
        approvers.push(approverId);
      } else {
        approvers.splice(index, 1);
      }
    },
    updateStage(stage) {
      const index = this.process.stages.findIndex(
        (s) => s.stageId === stage.stageId
      );
      if (index !== -1) {
        this.process.stages[index] = { ...stage };
      }
    },
    toggleApprover(stage, approverId) {
      this.pushApprover(stage.approvers, approverId);
      this.updateStage(stage);
    },
    selectStage(stage) {
      const index = this.process.stages.findIndex((s) => s.id === stage.id);
      if (index === -1) {
        this.process.stages.push(stage);
      } else {
        this.process.stages.splice(index, 1);
      }
    },
    changeRequiredApproval(stage) {
      if (!stage.requiredApproval) {
        stage.approvers = [];
        stage.partialApproval = false;
      }
      this.updateStage(stage);
    },

    swapSortOrder(index1, index2) {
      const tempOrder = this.stages[index1].sortOrder;
      this.stages[index1].sortOrder = this.stages[index2].sortOrder;
      this.stages[index2].sortOrder = tempOrder;
    },
    ediStage(stage) {
      this.stage = { ...stage };
      this.editStageDialog = true;
    },
    toggleExpand(index) {
      this.$set(this.expandedStages, index, !this.expandedStages[index]);
    },
    // selectStage(stage) {
    //   const existingStage = this.process.stages.find(s => s.id === stage.id);
    //   if (!existingStage) {
    //     this.process.stages.push(stage);
    //   }
    // },
    editProcess(process, type) {
      this.process = JSON.parse(JSON.stringify(process));
      switch (type) {
        case "edit":
          this.addDialog = true;
          break;
        case "stage":
          this.getStages();
          this.stageDialog = true;
          break;
        case "delete":
          this.deleteDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getProcesses();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getProcesses();
    },
    async getProcesses() {
      this.loading = true;
      try {
        let response = await processService.all(this.filter);

        this.processes = response?.data?.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e);
      }
    },
    async linkStages() {
      this.loading = true;
      try {
        this.process.stages.forEach((e) => {
          e.processId = this.process.id;
        });
        let response = await processService.linkStages(
          this.process.id,
          this.process.stages
        );
        if (response.status === 200) {
          ShowMessage("success", "Stages link successful");
          await this.getProcesses();
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Something went wrong");
      }
      this.loading = false;
      this.stageDialog = false;
    },
    async addProcess() {
      this.loading = true;
      this.addDialog = false;
      try {
        let response = await processService.add(this.process);
        if (response.status === 200) {
          ShowMessage("success", "Process added");
        }
      } catch (e) {
        ShowMessage(
          "error",
          e.response?.data?.message ?? "Something went wrong"
        );
      }
      this.loading = false;
      await this.getProcesses();
      await this.resetProcess();
    },
    async updateProcess() {
      this.loading = true;
      this.addDialog = false;
      try {
        let response = await processService.update(this.process);
        if (response.status === 200) {
          ShowMessage("success", "Process updated");
        }
      } catch (e) {
        ShowMessage(
          "error",
          e.response?.data?.message ?? "Something went wrong"
        );
      }
      this.loading = false;
      this.addDialog = false;
      await this.getProcesses();
      await this.resetProcess();
    },
    async deleteProcess() {
      this.loading = true;
      this.deleteDialog = false;
      try {
        let response = await processService.delete(this.process.id);
        if (response.status === 200) {
          ShowMessage("success", "Process deleted");
          await this.getProcesses();
        }
      } catch (e) {
        ShowMessage(
          "error",
          e.response?.data?.message ?? "Something went wrong"
        );
      }
      this.loading = false;
      await this.getProcesses();
    },

    async getStages() {
      this.loading = true;
      try {
        let response = await stageService.getAllStages();
        this.stages = response?.data.items ?? [];
        this.process.stages.forEach((processStage) => {
          // Find the index of the stage in this.stages
          let index = this.stages.findIndex(
            (stage) => stage.stageId === processStage.stageId
          );
          // If the stage is found in this.stages
          if (index !== -1) {
            // Update the stage
            this.stages[index] = { ...processStage };
          }
        });

        this.sortStages();
      } catch (e) {
        ShowMessage(
          "error",
          e.response?.data?.message ?? "Something went wrong"
        );
      }
      this.loading = false;
    },
    async getStatuses() {
      this.loading = true;
      try {
        let response = await optionService.getOptionsByKey("Status");
        this.statuses = response?.data ?? [];
      } catch (e) {
        ShowMessage(
          "error",
          e.response?.data?.message ?? "Something went wrong"
        );
      }
      this.loading = false;
    },
    async resetProcess() {
      this.process = {
        id: 0,
        title: "",
        description: "",
        status: 0,
      };
    },
    async getApprovers() {
      this.loading = true;
      try {
        let response = await userService.getApprovers(this.search);
        this.approvers = response?.data?.items ?? [];
        // for (let i = 0; i<=10; i++){
        //   let app = {
        //     id : i+5,
        //     displayName : "Person "+ i.toString(),
        //   };
        //   this.approvers.push(app)
        // }
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.getStatuses();
    this.getProcesses();
    this.getApprovers();
  },
};
</script>
<style scoped>
.el-select-dropdown__item {
  box-sizing: border-box;
  color: var(--el-text-color-regular);
  cursor: pointer;
  font-size: var(--el-font-size-base);
  height: auto !important;
  line-height: 34px;
  overflow: hidden;
  padding: 0 32px 0 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stage-option {
  padding: 10px;
  border-bottom: 1px solid #ebeef5;
}

.stage-header {
  cursor: pointer;
}

.stage-details {
  margin-top: 10px;
}

.el-icon-arrow-down,
.el-icon-arrow-right {
  font-size: 12px;
}

.small-btn {
  width: 25px !important;
  height: 25px !important;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.el-collapse {
  --el-collapse-border-color: var(--el-border-color-lighter);
  --el-collapse-header-height: 48px;
  --el-collapse-header-bg-color: none !important;
  --el-collapse-header-text-color: var(--el-text-color-primary);
  --el-collapse-header-font-size: 13px;
  --el-collapse-content-bg-color: none !important;
  --el-collapse-content-font-size: 13px;
  --el-collapse-content-text-color: var(--el-text-color-primary);
  border-bottom: 1px solid var(--el-collapse-border-color);
  border-top: 1px solid var(--el-collapse-border-color);
}

.selected_collapse {
  color: #2a5699 !important;
  font-weight: 600 !important;
}

.selected_collapse > span {
  color: inherit !important;
  font-weight: inherit !important;
}

.el-select-dropdown__item.is-selected {
  color: #2a5699 !important;
  font-weight: 400 !important;
}

.n-base-select-menu .n-base-select-option {
  background: red !important;
  z-index: 2055 !important;
  inset: 233.6px auto auto 1046.4px;
}

.el-overlay {
  background-color: var(--el-overlay-color-lighter);
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0 !important;
}

.approver-table {
  border: 1px solid #2a5699 !important;
}

.approver-table {
  table-layout: fixed;
  width: 100%;
}

.search-input {
  width: 100%;
  max-width: 200px; /* Set a max width to prevent it from expanding indefinitely */
}

.el-table__header-wrapper,
.el-table__body-wrapper {
  overflow: hidden; /* Prevent the table from expanding indefinitely */
}

.table-sm > :not(caption) > * > * {
  padding: 0.05rem 0.25rem !important;
}
</style>
