import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementPlus from 'element-plus'
import CKEditor from "@ckeditor/ckeditor5-vue";
import 'element-plus/dist/index.css'
import './assets/themes/elementplus.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'bootstrap-icons/font/bootstrap-icons.css';

import axios from 'axios'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");
axios.defaults.headers.common["Accept"] = "application/json";
store.state.loggedIn = sessionStorage.getItem("loggedIn");


const timeoutDuration = 60*60 *500; // 1 minutes
let timeoutId;
const startTimeout = () => {
    timeoutId = setTimeout(logoutUser, timeoutDuration);
};
// Reset the timer when the user interacts with the app
const resetTimeout = () => {
    clearTimeout(timeoutId);
    startTimeout();
};
// Add an event listener to reset the timer on user interaction
window.addEventListener('mousemove', resetTimeout);
window.addEventListener('keypress', resetTimeout);
window.addEventListener('scroll', resetTimeout);


const logoutUser = () => {
    axios.defaults.headers.common["Authorization"] = null;
    sessionStorage.clear();
    localStorage.clear();
    store.state.loggedIn = false;
    router.push('/')
}


createApp(App).use(router).use(CKEditor).use(store).use(ElementPlus).mount('#app')
