import axios from "axios";
import Config from "@/Configuration/Config";

export default {


    get(slug, resource, filter) {
        return axios.get(Config.apiBaseUrl + slug + '/' + resource + "?search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage)
    },
    getAll(slug, resource) {
        return axios.get(Config.apiBaseUrl + slug + '/' + resource)
    },
    getbyId(slug, resource,idSlug,id) {
        return axios.get(Config.apiBaseUrl + slug + '/' + resource + "?"+idSlug+"=" + id)
    },
    getchildsbyId(slug, resource,idSlug,id,filter) {
        return axios.get(Config.apiBaseUrl + slug + '/' + resource + "?"+idSlug+"=" + id+ "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage)
    },
    post(slug, resource, entity) {
        return axios.post(Config.apiBaseUrl + slug + "/" + resource, entity);
    },
    put(slug, resource, entity) {
        return axios.put(Config.apiBaseUrl + slug + "/" + resource, entity);
    },
    delete(slug, resource, entityId) {
        return axios.delete(Config.apiBaseUrl + slug + "/delete?" + resource+"="+ entityId);
    },
    getDocumentTypes(slug,resource) {
        return axios.get(Config.apiBaseUrl + slug + "/" + resource);
    },
    getTenants(slug,resource,filter){
        return axios.get(Config.apiBaseUrl + slug + '/' + resource +"?tenantType="+ filter.tenantType+"&clientId="+filter.clientId+ "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage)
    },
    getUser(userId){

        return axios.get(Config.apiBaseUrl+"user/getuserbyid?userId="+userId);
    },
    getProviders(filter){
        return axios.get(Config.apiBaseUrl+"provider/getproviders?clientId="+filter.clientId+"&search=" + filter.search+"&limit="+filter.pageSize+"&page="+filter.currentPage)
    },
    getProperties(filter){
        return axios.get(Config.apiBaseUrl+"property/getproperties?clientId="+filter.clientId+"&search=" + filter.search+"&limit="+filter.pageSize+"&page="+filter.currentPage)
    },
    getDocuments(filter){
        return axios.get(Config.apiBaseUrl+"document/getdocuments?clientId="+filter.clientId+"&search=" + filter.search+"&limit="+filter.pageSize+"&page="+filter.currentPage)
    },

    resetFilter(){
        this.$store.state.currentPage = 1;
        this.$store.state.pageSize = 10;
        this.$store.state.totalCount = 10;
        this.$store.state.search = "";
        this.$store.state.sort = "";
        this.$store.state.clientId = 1;
        this.$store.state.providerId = 1;
    }
}