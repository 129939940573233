import Config from "@/Configuration/Config";
import axios from "axios";

export default {
    allUrl: Config.apiBaseUrl + "question/getquestions?search=",
    getAllUrl: Config.apiBaseUrl + "question/getquestions?limit=1500",
    getByIdUrl: Config.apiBaseUrl + "question/getquestionbyid?questionId=",
    addUrl: Config.apiBaseUrl + "question/add",
    updateUrl: Config.apiBaseUrl + "question/update",
    deleteUrl: Config.apiBaseUrl + "question/delete?questionId=",
    softDeleteUrl: Config.apiBaseUrl + "question/softdelete?questionId=",
    assignUrl : Config.apiBaseUrl +"question/assign?sectionId=",
    removeLinkUrl : Config.apiBaseUrl +"question/unlink?sectionId=",

    all(filter) {
        return axios.get(this.allUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(question) {
        return axios.post(this.addUrl, question);
    },
    update(question) {
        return axios.put(this.updateUrl, question);
    },
    delete(questionId) {
        return axios.delete(this.deleteUrl + questionId);
    },
    softDelete(questionId) {
        return axios.delete(this.softDeleteUrl +questionId);
    },
    assign(sectionId,questionId){
        return axios.get(this.assignUrl+sectionId+"&questionId="+questionId);
    },
    removeLink(processId, questionId){
        return axios.get(this.removeLinkUrl+processId+"&questionId="+questionId);
    },
}