<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-7">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-5 d-flex justify-content-end">
          <el-input v-model="filter.search" placeholder="Search here..." @keyup="getInvites()">
            <template #append>
              <el-button @click="getInvites()">
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>
          <el-button @click="resetInvitations()"
                     class="border-0 mbtn-primary ms-1 text-capitalize">{{
              getMessageByCode('add_invite') ?? 'Add Invite'
            }}
          </el-button>
        </div>
      </div>
    </div>
    <el-table :data="invitations"
              :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe>
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            small
        >
        </el-pagination>
      </template>
      <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
      <el-table-column :label="getMessageByCode('applicant')??'Applicant'" prop="applicant" sortable></el-table-column>
      <el-table-column label="Email" prop="email"></el-table-column>
      <el-table-column label="Job" prop="job"></el-table-column>
      <el-table-column label="Sent Date" prop="sentDate" width="120">
        <template #default="scope">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="text-date">{{ getFormattedDate(scope.row.sentDate) }}</span>
            <span class="text-date">{{ getFormattedTime(scope.row.sentDate) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="getMessageByCode('sent')??'Sent'" prop="emailSent" width="80">
        <template #default="scope">
          <div class="text-center">
            <div v-if="scope.row.emailSent" class="btn-s-secondary text-date mfw-bold rounded">Sent</div>
            <div v-else class="btn-d-secondary text-date mfw-bold rounded">Not Sent</div>

          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="80">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <el-tooltip content="Resend">
              <i
                  class="mgc_refresh_2_line text-primary action-btn"
                  @click="resendInvitation(scope.row.id)"
              ></i>
            </el-tooltip>
            <el-tooltip content="Delete">
              <i @click="editInvitaion(scope.row,'del')"
                 class="mgc_delete_2_line text-danger action-btn"
              ></i>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>

    <!--    Dialogs-->

    <el-dialog v-model="addDialog" draggable :align-center="true">
      <template #header>
        <el-progress v-if="loading" :percentage="100"

                     :indeterminate="true"
                     :duration="1"
                     :show-text="false"
        ></el-progress>
        <span v-if="invitation.id < 1">{{ getMessageByCode('add_invite') ?? 'Add Invite' }}</span>
        <span v-else>{{ getMessageByCode('edit_invite') ?? 'Edit Invite' }}</span>
      </template>
      <template #default>
        <el-form label-position="top" hide-required-asterisk>
          <div class="row">
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('job') ?? 'Job' }}</span>
                </template>
                <el-select v-model="invitation.jobId" @change="changeJob()">
                  <el-option v-for="job in jobs" :key="job.id" :label="job.title" :value="job.id"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('applicant') ?? 'Applicant' }}</span>
                </template>
                <el-select v-model="invitation.applicantId" @change="changeApplicant()">
                  <el-option v-for="applicant in applicants" :key="applicant.id"
                             :label="applicant.forename+' '+applicant.surname" :value="applicant.id"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('email') ?? 'Email' }}</span>
                </template>
                <el-input disabled v-model="invitation.email"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('password') ?? 'Password' }}</span>
                </template>
                <el-input v-model="invitation.password"></el-input>
              </el-form-item>
            </div>
            <div class="col-12">
              <div class="w-100" style="display: block !important; line-height: 20px !important;">

                <label class="">{{ getMessageByCode('email_body') ?? 'Email Body' }}</label>

                <ckeditor :editor="editor" class="w-100" v-model="invitation.emailBody" :config="editorConfig"
                          style="width: 100% !important;"></ckeditor>
              </div>
            </div>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
            <el-button type="primary" v-if="invitation.id < 1" @click="sendInvitation()">
              {{ getMessageByCode('add_inv') ?? 'Send Invitation' }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="450">
      <template #header>
        <span>{{ getMessageByCode('delete') ?? 'Delete' }} {{ getMessageByCode('invitation') ?? 'Invitation' }}</span>
      </template>
      <template #default>
        <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }}</span> <span
          class="fw-bold text-danger">{{ invitation?.applicant }}</span><span>'s invitation.</span>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="deleteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
            <button class="btn btn-sm ms-1 px-3 btn-danger" @click="deleteInvite()">
              {{ getMessageByCode('delete') ?? 'Delete' }}
            </button>

          </div>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {getLanguage} from "@/Utility/getLanguage";
import applicantService from "@/Services/ComponentsServices/applicantService";
import jobService from "@/Services/ComponentsServices/jobService";
import templateService from "@/Services/ComponentsServices/templateService";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import inviteService from "@/Services/ComponentsServices/inviteService";
import {ShowMessage} from "@/Utility/Utility";
import configService from "@/Services/ComponentsServices/configService";
import moment from "moment";

export default {
  name: "InvitesList",
  components: { BreadCrumb, ckeditor: CKEditor.component},
  data() {
    return {
      loading: false,
      addDialog: false,
      loadingBarTargetRef: 0,
      deleteDialog: false,
      emailTemplate: {},
      editor: ClassicEditor,
      editorConfig: {},
      applicants: [],
      applicant: [],
      jobs: [],
      job: {},
      invitations: [],
      currency: "£",
      currencyPosition: "right",
      dateFormat: "DD/MM/YYYY",

      invitation: {
        "id": 0,
        "applicantId": "",
        "applicant": "",
        "email": "",
        "password": "",
        "emailTemplate": "",
        "jobId": "",
        "emailBody": "",
        "job": "",
      },
      translations: [],
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {
    getFormattedDate(date) {
      if (date === undefined) {
        return date;
      }
      return moment(date).format(this.dateFormat.toString())
    },
    getFormattedTime(date) {
      if (date === undefined) {
        return "";
      }
      return moment(date).format('hh:mm A');
    },
    editInvitaion(invite, type) {
      this.invitation = {...invite};
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'del':
          this.deleteDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getInvites();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getInvites();
    },
    changeJob() {
      this.job = this.jobs.find(x => Number(x.id) === Number(this.invitation.jobId));
      this.invitation.jobId = this.job.id;
      this.invitation.job = this.job.title;
      this.setEmailBody();
    },
    changeApplicant() {
      this.applicant = this.applicants.find(x => Number(x.id) === Number(this.invitation.applicantId));
      this.invitation.email = this.applicant.email.toString();
      this.invitation.applicant = this.applicant.forename.toString() + ' ' + this.applicant.surname.toString();
      this.setEmailBody();
    },
    setEmailBody() {
      if (this.invitation.jobId < 1 || this.invitation.applicantId < 1) {
        return;
      }
      this.invitation.emailBody = this.emailTemplate.etBody ?? "";
      this.invitation.emailBody = this.invitation.emailBody.replaceAll(/\[Applicant\]/g, this.applicant.forename.toString() + ' ' + this.applicant.surname.toString());
      this.invitation.emailBody = this.invitation.emailBody.replaceAll(/\[JOBTITLE\]/g, this.job.title.toString());
      this.invitation.emailBody = this.invitation.emailBody.replaceAll(/\[EMAIL\]/g, this.applicant.email.toString());
      this.invitation.emailBody = this.invitation.emailBody.replaceAll(/\[LINK\]/g, "http://lightcrm.co.uk/");
      if (!this.invitation.password) {
        this.invitation.password = this.generatePassword();
        this.invitation.emailBody = this.invitation.emailBody.replaceAll(/\['PASSWORD'\]/g, this.invitation.password)
      }
    },
    setPassword() {
      if (!this.invitation.password) {
        return;
      }
      this.invitation.emailBody = this.invitation.emailBody.replace(/Password: [A-Za-z0-9]+/, this.invitation.password)
    },
    async sendInvitation() {
      this.loading = true;
      // eslint-disable-next-line no-unreachable
      try {
        if (this.invitation.jobId < 1) {
          this.loading = false;
          ShowMessage("error", "Please select a job first");
          return;
        }
        if (this.invitation.applicantId < 1) {
          this.loading = false;
          ShowMessage("error", "Please select an applicant first");
          return;
        }
        let response = await inviteService.add(this.invitation);
        if (response.status === 200) {
          ShowMessage("success", "Invitation sent")
          this.addDialog = false;
          await this.getInvites();
        }

      } catch (e) {
        ShowMessage("error", e?.response?.data?.message ?? "Invitation sending failed")
        console.log(e)
      }
      this.loading = false;
    },
    async resendInvitation(invitationId) {
      this.loading = true;
      try {
        let response = await inviteService.resend(invitationId);
        if (response.status === 200) {
          ShowMessage("success", "Invitation Resent")
        }
        this.addDialog = false;
        await this.getInvites();
      } catch (e) {

        ShowMessage("error", e?.response?.data?.message ?? "Invitation sending failed")
        console.log(e)
      }
      this.loading = false;
    },
    async getInvites() {
      this.loading = true;
      try {
        let response = await inviteService.all(this.filter);
        this.invitations = response?.data?.items ?? {}
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async deleteInvite() {
      this.loading = true;
      try {
        let response = await inviteService.delete(this.invitation.id);
        if (response.status === 200) {
          ShowMessage('success', 'Invitation deleted')
        }
        this.deleteDialog = false;
        await this.getInvites();
      } catch (e) {
        console.log(e);
        ShowMessage('error', 'Something went wrong');
      }
      this.loading = false;
    },
    generatePassword() {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let password = '';
      for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
      }
      return password;
    },
    async getApplicants() {
      try {
        this.loading = true;
        let response = await applicantService.getAll();
        this.applicants = response.data.items ?? [];
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getJobs() {
      try {
        this.loading = true;
        let response = await jobService.getAll();
        this.jobs = response.data.items ?? [];
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    resetInvitations() {
      this.addDialog = true;
      this.invitation = {
        "id": 0,
        "applicantId": "",
        "applicant": "",
        "email": "",
        "password": "",
        "jobId": "",
        "emailBody": "",
        "job": "",
        "emailTemplate": this.emailTemplate.etId,
      };
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getTemplate() {
      this.loading = true;
      try {
        let response = await templateService.getTemplateByCode("JOBOFFER");
        this.emailTemplate = response?.data ?? {};
        this.invitation.emailTemplate = this.emailTemplate.etId;
      } catch (e) {
        console.log(e)
      }
    },
    async getConfigs() {
      try {
        this.loading = true;
        let response = await configService.getByKey("CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey("CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey("DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
  watch: {
    'invitation.password'(newValue) {

      const passwordRegex1 = /Password: [^\s]+/;
      const passwordRegex2 = /Password:[^\s]+/;

      // Check if the first regex matches
      if (passwordRegex1.test(this.invitation.emailBody)) {
        // If the first regex matches, perform the replacement using the first regex
        this.invitation.emailBody = this.invitation.emailBody.replace(passwordRegex1, `Password: ${newValue}`);
      } else {
        // If the first regex doesn't match, perform the replacement using the second regex
        this.invitation.emailBody = this.invitation.emailBody.replace(passwordRegex2, `Password: ${newValue}`);
      }
    },
  },
  created() {
    this.translations = getLanguage();
    this.getInvites();
    this.getApplicants();
    this.getJobs();
    this.getTemplate();
  }
}
</script>

<style scoped>
.ck.ck-editor {
  position: relative;
  width: 100% !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset, .ck.ck-reset_all {
  box-sizing: border-box;
  height: auto;
  position: static;
  width: 100% !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset, .ck.ck-reset_all {
  box-sizing: border-box;
  height: auto;
  position: static;
  width: 100%;
}

.ck.ck-editor {
  position: absolute !important;
  top: 0;
}

.el-form-item__content {
  display: block !important;
}
</style>