import Config from "@/Configuration/Config";
import axios from 'axios'

export default {
    allurl: Config.apiBaseUrl + "user/getusers",
    getUrl: Config.apiBaseUrl + "user/getuserbyid?userId=",
    //getbyclienturl: Config.apiBaseUrl + "room/getdocumentIdbyclient?clientId=",
    addUrl: Config.apiBaseUrl + "user/add",
    updateUrl: Config.apiBaseUrl + "user/update",
    deleteurl: Config.apiBaseUrl + "user/delete?userId=",
    signupUrl: Config.apiBaseUrl + "user/signup?email=",
    verifySignupUrl: Config.apiBaseUrl + "user/verifysignup?guid=",
    getUserUrl: Config.apiBaseUrl + "user/getusers?clientId=",
    getApproversUrl: Config.apiBaseUrl + "user/getapprovers?search=",

    all(filter) {
        return axios.get(this.allurl + "?search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll(proId) {
        return axios.get(this.getallurl + "&proId=" + proId);
    },

    getbyId(id) {
        return axios.get(this.getUrl + id);
    },
    add(user) {
        return axios.post(this.addUrl, user);
    },
    update(user) {
        return axios.put(this.updateUrl, user);
    },
    signup(email) {
        return axios.post(this.signupUrl + email);
    },
    verifySignup(guid) {
        return axios.post(this.verifySignupUrl + guid);
    },
    delete(userId) {
        return axios.delete(this.deleteurl + userId);
    },
    getApprovers(search) {
        return axios.get(this.getApproversUrl + search)
    }
}