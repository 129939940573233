import axios from "axios"
import Config from "@/Configuration/Config";

export default {
    allUrl: Config.apiBaseUrl + "invite/getinvites?search=",
    getAllUrl: Config.apiBaseUrl + "invite/getinvites?limit=1500",
    getByIdUrl: Config.apiBaseUrl + "invite/getinvitebyid?inviteId=",
    addUrl: Config.apiBaseUrl + "invite/add",
    updateUrl: Config.apiBaseUrl + "invite/update",
    deleteUrl: Config.apiBaseUrl + "invite/delete?inviteId=",
    resendUrl: Config.apiBaseUrl + "invite/resend?inviteId=",


    all(filter) {
        return axios.get(this.allUrl + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    getById(id) {
        return axios.get(this.getByIdUrl + id);
    },
    add(invite) {
        return axios.post(this.addUrl, invite);
    },
    resend(invitationId){
        return axios.get(this.resendUrl+invitationId)
    },
    update(invite) {
        return axios.put(this.updateUrl, invite);
    },
    delete(inviteId) {
        return axios.delete(this.deleteUrl + inviteId);
    },
}