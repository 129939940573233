<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link :to="$store.state.previousRoute">
            <el-button text bg class="btn btn-light ms-1">
              <i class="bi bi-chevron-left me-2"></i>
              <span class="text-capitalize">Back</span>
            </el-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="applicationView d-flex flex-column justify-content-center align-items-center py-2">
      <div class="w-100 w-md-75">
        <div class="app-title text-center">
          <h3>{{ application?.title ?? "" }}</h3>
        </div>
        <div class="mt-5">
          <el-steps :active="active" align-center>
            <el-step class="custom-step" v-for="(stage, index) in application.process?.stages" :key="stage.id"
                     @click="changeStep(active,index,stage)">
              <template #icon>
                <i v-if="index < active" class="bi bi-check-circle-fill h2"></i>
                <i v-else :class="getIcon(index + 1)"></i>
              </template>
              <template #title>
                <span>{{ stage.title }}</span>
              </template>

            </el-step>
          </el-steps>
          <div class="">
            <div v-for="section in application.process?.stages[active]?.sections" :key="section.id">
              <div class="card mt-3">
                <div class="card-header bg-gray-light border-0 py-3">
                  <h5 class="mfw-bold m-0">{{ section?.title ?? "" }}</h5>
                </div>
                <div class="card-body">
                  <div>
                    <el-form label-position="top" :model="application" :rules="formRules">
                      <el-form-item v-for="question in section?.questions" :key="question.id"
                                    :class="question.cssClass">
                        <template #label>
                          <span :class="question.isRequired ? 'required' : ''">{{ question.title }}</span>
                        </template>
                        <template #default>
                          <el-input :disabled="applicationReadOnly" v-if="getQuestionType(question.questionType) === 'upload' && !question.validation"
                                    v-model="question.fileName">
                            <template #prepend>
                              <el-upload :disabled="applicationReadOnly" :auto-upload="false" :on-change="(file) => handleFileChange(file, question)"
                                         :show-file-list="false"
                                         class="mb-0">
                                <template #trigger>
                                  <el-button :disabled="applicationReadOnly" type="primary">Choose file</el-button>
                                </template>
                              </el-upload>
                            </template>
                          </el-input>
                          <el-input :disabled="applicationReadOnly" v-if="getQuestionType(question.questionType) === 'freetext' && !question.validation"
                                    v-model="question.value">
                          </el-input>
                          <el-input :disabled="applicationReadOnly" type="textarea" :rows="3"
                                    v-if="getQuestionType(question.questionType) === 'textarea' && !question.validation"
                                    v-model="question.value">
                          </el-input>
                          <div class="w-100"
                               v-if="getQuestionType(question.questionType) === 'freetext' &&  question?.validation === 'email'">
                            <el-input :disabled="applicationReadOnly" v-model="question.value" @keyup="validateEmail(question.value)"></el-input>
                            <span class="floating-placeholder text-caption text-danger" v-if="msg.email">{{
                                msg.email
                              }}</span>
                          </div>
                          <div class="w-100"
                               v-if="getQuestionType(question.questionType) === 'date' &&  question?.validation === 'date'">
                            <el-date-picker :disabled="applicationReadOnly" :format="dateFormat" :editable="false" v-model="question.value"
                            ></el-date-picker>

                          </div>
                          <el-select :disabled="applicationReadOnly" v-if="getQuestionType(question.questionType) === 'dropdown'"
                                     v-model="question.value">
                            <el-option v-for="option in getOptionsForHeaderId(question.optionHeaderId ?? 0)"
                                       :key="option.optId"
                                       :label="option.optTitle" :value="option.optTitle">
                            </el-option>
                          </el-select>
                          <el-select :disabled="applicationReadOnly" v-if="getQuestionType(question.questionType) === 'yesno'"
                                     v-model="question.value">
                            <el-option label="Yes" value="true"></el-option>
                            <el-option label="No" value="false"></el-option>
                          </el-select>

                        </template>
                      </el-form-item>
                    </el-form>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="application.applicationId > 0">
            <el-divider></el-divider>
            <div class="d-flex justify-content-between">
              <el-button class="bg-gray-light" @click="preStep()">
                <i class="bi bi-arrow-bar-left"></i>
                <span class="ms-2">Previous</span>
              </el-button>

              <div v-if="active < application.process.stages.length">

                <el-button-group>
                  <el-button
                      v-if="currentStage.requiredSubmission && (currentStage.status < 3 || currentStage.status ===7)"
                      type="primary"
                      @click="submitStage()">
                    <span>Submit</span>
                  </el-button>
                  <el-button
                      v-else
                      type="primary"
                      @click="saveAndContinue()">
                    <span>Save & Continue Later</span>
                  </el-button>
                  <el-button type="primary" @click="nextStage()">
                    <span>Next</span>
                    <i class="bi bi-arrow-bar-right"></i>
                  </el-button>
                </el-button-group>

              </div>
              <el-button v-else type="success" @click="submitApplication(0)">
                <span>Finish & Submit</span>
                <i class="bi bi-arrow-bar-right ms-2"></i>
              </el-button>
            </div>
          </div>
          <!--          <el-tabs v-model="active" class="demo-tabs" >-->
          <!--            <el-tab-pane v-for="(stage, index) in application?.process?.stages" :name="index" :key="stage.id" >-->
          <!--              <template #label>-->
          <!--                <div>-->
          <!--                  <span>{{stage.title}}</span>-->

          <!--                </div>-->
          <!--              </template>-->
          <!--              <template #default>-->
          <!--                <div v-for="section in stage.sections" :key="section.id">-->
          <!--                  <div class="card mt-3">-->
          <!--                    <div class="card-header">-->
          <!--                      <span>{{section?.title??""}}</span>-->
          <!--                    </div>-->
          <!--                    <div class="card-body">-->
          <!--                      <div v-for="question in section?.questions" :key="question.id">-->
          <!--                        <span>{{question.title}}</span>-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </template>-->

          <!--            </el-tab-pane>-->
          <!--          </el-tabs>-->


        </div>
      </div>

    </div>

    <el-empty v-if="application.applicationId === undefined || application.applicationId < 1"
              description="No Application Data Found"/>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import applicationService from "@/Services/ComponentsServices/applicationService";
import {ShowMessage} from "@/Utility/Utility";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import optionService from "@/Services/ComponentsServices/optionService";
import configService from "@/Services/ComponentsServices/configService";
import {status} from '@/Utility/status'

export default {
  name: "ViewApplication",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      optionsMap: new Map(),
      status: status.NotStarted,
      applicationId: 0,
      application: {},
      active: 0,
      stage: {},
      questionTypes: [],
      msg: [],
      currency: "£",
      currencyPosition: "right",
      dateFormat: "DD/MM/YYYY",
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
      formRules: {
        nameRule: [
          {required: true, message: 'Please enter your name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        emailRule: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validatemyEmail, trigger: 'blur'}
        ], userEmail: [
          {required: true, message: 'Please enter the user email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid user email', trigger: ['blur', 'change']},
          {validator: this.validatemyEmail, trigger: 'blur'}
        ],
      },
    }
  },
  computed: {
    applicationReadOnly() {
      return this.$store.state.applicationReadOnly;
    },
    currentStage() {
      return this.application?.process?.stages[this.active];
    }
  },
  methods: {
    async submitStage() {
      try {
        let questions = this.extractStageQuestions(this.application, this.currentStage.stageId);
        let response = await applicationService.submitStage(this.currentStage.stageId, questions);
        console.log(response?.data?.message, 'stage submit')
        if (response?.data?.message.includes("success")) {
          ShowMessage("success", response?.data?.message);
          await this.getApplication(this.application.applicationId);
        } else {
          ShowMessage("error", response?.data?.message)
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Stage submitting failed");
      }
    },
    async submitApplication(status) {
      try {
        let questions = this.extractAllQuestions(this.application);
        let response = await applicationService.submit(status, questions);
        console.log(response)
        if (response?.data?.message.includes("success")) {
          ShowMessage("success", response?.data?.message);
          this.$router.push(this.$store.state.previousRoute)
        } else {
          ShowMessage("error", response?.message)
        }
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Something went wrong")
      }
    },
    changeStep(active, index, stage) {
      this.stage = JSON.parse(JSON.stringify(stage));
      this.active = index;
    },
    validateEmail(value) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg['email'] = '';
      } else {
        this.msg['email'] = 'Please enter a valid email address';
      }
    },
    validatemyEmail(rule, value, callback) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        callback();
      } else {
        callback(new Error('Please enter a valid email address'));
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleFileChange(file, question) {
      if (file === undefined) {
        return;
      }
      question.fileName = file.name
      question.fileSize = file.raw.size;
      question.fileExtension = file.raw.name.split('.').pop();
      const selectedFile = file.raw;
      this.returnDocObject(selectedFile, (base64String) => {
        question.value = base64String;
        // Now you can send this.Document to your API

      });

    },
    returnDocObject(file, callback) {
      const reader = new FileReader();
      reader.onload = () => {
        const byteArray = new Uint8Array(reader.result);
        const base64String = btoa(String.fromCharCode.apply(null, byteArray));
        callback(base64String);
      };

      reader.readAsArrayBuffer(file);
    },
    async getListOptions(headerId) {

      if (this.optionsMap.has(headerId)) {
        return this.optionsMap.get(headerId);
      }
      try {
        let response = await optionService.getOptionsByHeaderId(headerId);
        let options = response?.data ?? [];
        this.optionsMap.set(headerId, options);
        return options;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    getOptionsForHeaderId(headerId) {
      return this.optionsMap.get(headerId) || [];
    },
    getQuestionType(id) {
      return this.questionTypes.find(x => x.optId === id)?.optValue.toLowerCase() ?? "";

    },
    getIcon(index) {
      return "bi bi-" + index + "-circle-fill h2"
    },
    async nextStage() {
      this.loading = true;
      let questions = this.extractAllQuestions(this.application)
      let response = await applicationService.save(questions);
      console.log(response)
      const maxSteps = this.application.process.stages.length;
      // let currentStage = this.application?.process?.stages[this.active];
      // if (currentStage.isRequired && !currentStage.partialApproval && currentStage.status !== status.Approved) {
      //   ShowMessage("error", "The stage must be approved before proceeding to next stage");
      //   return;
      // }
      // if (currentStage.isRequired && currentStage.status !== status.Approved) {
      //   ShowMessage("error", "The stage must be approved before proceeding to next stage");
      //   return;
      // }
      if (this.application?.process?.stages[this.active + 1] !== undefined) {
        this.stage = JSON.parse(JSON.stringify(this.application?.process?.stages[this.active + 1]));
        return;
      }
      if (this.active < maxSteps) {
        this.active++;
      }
      this.loading = false;
    },
    async preStep() {
      if (this.application?.process?.stages[this.active - 1] !== undefined) {
        this.stage = JSON.parse(JSON.stringify(this.application?.process?.stages[this.active - 1]));
      }

      if (this.active > 0) {
        this.active--;
      }
      let questions = this.extractAllQuestions(this.application)
      // eslint-disable-next-line no-unused-vars
      let response = await applicationService.save(questions);
    },
    extractAllQuestions(application) {
      let questions = [];
      application.process.stages.forEach(stage => {
        stage.sections.forEach(section => {
          questions = questions.concat(section.questions);
        });
      });
      return questions;
    },
    extractStageQuestions(application, stageId) {
      let questions = [];
      application.process.stages.forEach(stage => {
        if (stage.id === stageId) {
          stage.sections.forEach(section => {
            questions = questions.concat(section.questions);
          });
        }
      });
      return questions;
    },
    async getApplication(applicationId) {
      this.loading = true;
      try {
        let response = await applicationService.viewApplication(applicationId);
        this.application = response?.data ?? {};
        this.stage = JSON.parse(JSON.stringify(this.application?.process?.stages[0]))
        for (let stage of this.application?.process?.stages) {
          for (let section of stage?.sections) {
            for (let question of section?.questions) {
              if (this.getQuestionType(question.questionType) === 'dropdown') {
                await this.getListOptions(question.optionHeaderId ?? 0);
              }
            }
          }
        }
      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong");
      }
      this.loading = false;
    },
    async getQuestionTypes() {
      try {
        let response = await optionService.getOptionsByKey("QuestionTypes");
        this.questionTypes = response?.data ?? [];
      } catch (e) {
        console.log(e)
      }
    },
    async getConfigs() {
      try {
        let response = await configService.getByKey("CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey("CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey("DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";

      } catch (e) {
        console.log(e);
      }
    },
    async saveAndContinue() {
      try {
        let questions = this.extractAllQuestions(this.application)
        let response = await applicationService.save(questions);
        if (response.status === 200) {
          this.$router.push(this.$store.state.previousRoute)
        }

      } catch (e) {
        ShowMessage("error", e?.response?.message ?? "Something went wrong");
      }
    },
    updateApplication() {
      this.extractAllQuestions(this.application);

    }
  },
  created() {
    if (this.$store.state.pageId > 0) {
      this.getApplication(this.$store.state.pageId);
    }
    this.getConfigs();
    this.getQuestionTypes();
  }
}
</script>


<style scoped>

</style>