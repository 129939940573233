<template>
  <div>
    <div class="container-fluid">
      <div class="container-fluid border-bottom mb-2 p-0">
        <div class="row d-flex align-items-center custom-toolbar">
          <div class="col-12 col-md-7">
            <BreadCrumb :showBread="false"/>
          </div>
          <div class="col-12 col-md-5 d-flex justify-content-end">
            <el-input
                v-model="filter.search"
                placeholder="Search here..."
                @keyup="getStages()"
            >
              <template #append>
                <el-button @click="getStages()">
                  <i class="bi bi-search"></i>
                </el-button>
              </template>
            </el-input>
            <el-button
                @click="resetStage(); addDialog= true;"
                class="border-0 mbtn-primary ms-1 text-capitalize"
            >Add Stage
            </el-button>
          </div>
        </div>
      </div>
      <el-table :data="stages" :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe>
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              small
          >
          </el-pagination>
        </template>
        <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
        <el-table-column label="Title" prop="title" width="400" sortable></el-table-column>
        <el-table-column label="Description" prop="description"></el-table-column>
        <el-table-column align="center" fixed="right" label="Actions" width="120">
          <template #default="scope">
            <div class="d-flex justify-content-between align-items-center">
              <el-tooltip content="Edit">
                <i
                    class="mgc_edit_line text-primary action-btn"
                    @click="editStage(scope.row,'edit')"
                ></i>
              </el-tooltip>
              <el-tooltip content="Remove Link">
                <i
                    class="mgc_unlink_line text-warning action-btn"
                    @click="editStage(scope.row,'removeLink')"
                ></i>
              </el-tooltip>
              <el-tooltip content="Assign to process">
                <i
                    class="mgc_list_check_2_line text-success action-btn"
                    @click="editStage(scope.row,'assign')"
                ></i>
              </el-tooltip>
              <el-tooltip content="Delete">
                <i @click="editStage(scope.row,'delete')"
                   class="mgc_delete_2_line text-danger action-btn"
                ></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog v-model="addDialog" width="700" :align-center="true">
      <template #header>
        <span v-if="stage.id < 1">Add Stage</span>
        <span v-else>Update Stage</span>
      </template>
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <div class="row">
            <div class="col-12 col-md-6">
              <el-form-item label="Title" required>
                <el-input v-model="stage.title"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item label="Process">
                <el-select v-model="stage.processId">
                  <el-option v-for="process in processes" :key="process.id" :label="process.title"
                             :value="process.id"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12">
              <el-form-item label="Description">
                <el-input type="textarea" :rows="5" v-model="stage.description"></el-input>
              </el-form-item>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-md-4">

                  <el-form-item label="Require Submission">
                    <template #label>
                      <span class="text-small">Require Submission</span>
                    </template>
                    <el-switch class="w-100" v-model="stage.requiredSubmission"></el-switch>
                  </el-form-item>
                </div>
                <div class="col-md-4">
                  <el-form-item label="Require Approval">
                    <template #label>
                      <span class="text-small">Require Approval</span>
                    </template>
                    <el-switch class="w-100" @change="changeApproval()" v-model="stage.requiredApproval"></el-switch>
                  </el-form-item>
                </div>
                <div class="col-md-4" v-if="stage.requiredApproval">
                  <el-form-item label="Partial Approval">
                    <template #label>
                      <span class="text-small">Partial Approval</span>
                    </template>
                    <el-switch class="w-100" v-model="stage.partialApproval"></el-switch>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item label="Sort Order">
                <el-input-number class="w-100" :min="0" v-model="stage.sortOrder"
                                 controls-position="right"></el-input-number>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6" v-if="stage.requiredApproval">
              <el-form-item label="Approvers">
                <el-select v-model="stage.approvers" multiple
                           collapse-tags
                           collapse-tags-tooltip filterable clearable>
                  <el-option v-for="approver in approvers" :key="approver.id" :label="approver.displayName"
                             :value="approver.id"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="addDialog = false">Cancel</el-button>
          <el-button v-if="stage.id < 1" type="primary" @click="addStage()" class="ms-2">Add</el-button>
          <el-button v-else type="primary" @click="updateStage()" class="ms-2">Update</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="assignDialog" width="400">
      <template #header>
        <span>Assign Stage</span>
      </template>
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <el-form-item label="Process">
            <el-select v-model="stage.processId">
              <el-option v-for="process in processes" :key="process.id" :label="process.title"
                         :value="process.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="assignDialog = false">Cancel</el-button>
          <el-button type="primary" @click="assignToProcess()" class="ms-2">Assign</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="removeLinkDialog" width="400">
      <template #header>
        <span>Remove Link</span>
      </template>
      <template #default>
        <el-form label-position="top" require-asterisk-position="right">
          <el-form-item label="Process">
            <el-select v-model="stage.processId">
              <el-option v-for="process in processes" :key="process.id" :label="process.title"
                         :value="process.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="removeLinkDialog = false">Cancel</el-button>
          <el-button type="primary" @click="removeLink()" class="ms-2">Remove</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="400">
      <template #header>
        <span>Delete Stage</span>
      </template>
      <template #default>
        <span>Are you sure you want to delete </span><span class="text-danger">{{
          stage?.title
        }} </span><span> ?.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button bg text @click="deleteDialog = false">Cancel</el-button>
          <button class="btn btn-sm btn-danger ms-2" @click="deleteStage()">Delete</button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import processService from "@/Services/ComponentsServices/processService";
import {ShowMessage} from "@/Utility/Utility";
import stageService from "@/Services/ComponentsServices/stageService";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import userService from "@/Services/ComponentsServices/userService";

export default {
  name: "StagesList",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      addDialog: false,
      assignDialog: false,
      deleteDialog: false,
      removeLinkDialog: false,
      processes: [],
      stages: [],
      approvers: [],
      stage: {
        "id": 0,
        "approvers": [],
        "title": "",
        "description": "",
        "sortOrder": 0,
        "isDeleted": true,
        "deletedBy": "",
        "processId": null,
        "requiredSubmission": false,
        "requiredApproval": false,
        "partialApproval": false,
        "isApproved": false,
      },
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {

    editStage(stage, type) {
      this.stage = JSON.parse(JSON.stringify(stage));
      switch (type) {
        case 'edit':
          if(this.stage.processId < 1){
            this.stage.processId = null;
          }
          this.addDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
        case 'assign':
          this.stage.processId = null;
          this.getProcesses(1)
          this.assignDialog = true;
          break;
        case 'removeLink':
          this.stage.processId = null;
          this.getProcesses(2)
          this.removeLinkDialog = true;
          break;

      }
    },
    changeApproval() {
      if (!this.stage.requiredApproval) {
        this.stage.partialApproval = false;
        this.stage.approvers = [];
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getProcesses()
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getProcesses();
    },
    async getStages() {
      this.loading = true;
      try {
        let response = await stageService.all(this.filter);
        this.stages = response?.data?.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? 1;
        this.filter.pageSize = response.data.pageSize;
      } catch (e) {
        console.log(e)
      }
    },
    async addStage() {
      this.loading = true;
      this.addDialog = false;
      try {
        if (!this.stage.title) {
          ShowMessage("error", "Please enter stage title");
          return;
        }
        if (typeof this.stage.processId == "string") {
          this.stage.processId =null;
        }
        let response = await stageService.add(this.stage);
        if (response.status === 200) {
          ShowMessage("success", "Stage added");
          await this.getStages();
        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.loading = false;
      await this.getStages();
      await this.resetStage();
    },
    async updateStage() {
      this.loading = true;
      this.addDialog = false;

      try {
        if (!this.stage.title) {
          ShowMessage("error", "Please enter stage title");
          return;
        }
        if (typeof this.stage.processId == "string") {
          this.stage.processId = null;
        }
        let response = await stageService.update(this.stage);
        if (response.status === 200) {
          ShowMessage("success", "Stage updated");
          this.getStages();
        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.loading = false;
      this.addDialog = false;
      this.getStages();
      this.resetStage();
    },
    async assignToProcess() {
      this.loading = true;
      try {
        if (this.stage.processId === undefined || this.stage.processId < 1) {
          ShowMessage("error", "Please select a process");
          return;
        }
        let response = await stageService.assign(this.stage.processId, this.stage.id);
        if (response.status === 200) {
          ShowMessage("success", "Stage assigned to process");
          await this.getStages();
        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.assignDialog = false;
      this.loading = false;
      await this.getStages();
    },
    async removeLink() {
      this.loading = true;
      try {
        if (this.stage.processId === undefined || this.stage.processId < 1) {
          ShowMessage("error", "Please select a process");
          return;
        }
        let response = await stageService.removeLink(this.stage.processId, this.stage.id);
        if (response.status === 200) {
          ShowMessage("success", response?.data?.message ?? "Section assigned to stage");
        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.removeLinkDialog = false;
      this.loading = false;
      await this.getStages();
    },
    async deleteStage() {
      this.loading = true;
      this.deleteDialog = false;
      try {
        let response = await stageService.delete(this.stage.id);
        if (response.status === 200) {
          ShowMessage("success", "Stage deleted");
          await this.getStages();
        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.loading = false;
      await this.getProcesses();
    },
    async getProcesses(linkType) {
      this.loading = true;
      try {
        let response = await processService.getFilteredProcesses(this.stage.id, linkType);
        this.processes = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
    },
    async getApprovers() {
      this.loading = true;
      try {
        let response = await userService.getApprovers(this.filter.search);
        this.approvers = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
    },


    async resetStage() {
      this.stage = {
        "id": 0,
        "title": "",
        "description": "",
        "sortOrder": 0,
        "isDeleted": true,
        "deletedBy": "",
        "processId": null
      };
    }
  },
  created() {
    this.getStages();
    this.getApprovers();
  }
}
</script>

<style scoped>

</style>