<!--suppress JSUnresolvedReference -->
<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
<!--          <el-select v-if="clientId == null || clientId == 0" class="w-100" filterable @change="ChangeClient" v-model="filter.id">-->
<!--            <el-option-->
<!--                v-for="item in Clients"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id"-->
<!--            />-->
<!--          </el-select>-->
         <router-link @click="$store.state.pageId = 0" to="/add-user">
           <el-button
                      class="border-0 mbtn-primary ms-2 text-capitalize">{{getMessageByCode('add_user')??'Add User'}}
           </el-button>
         </router-link>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-end">
            <el-button class="bg-danger text-white fw-normal me-2 border-0"
                       @click="$store.state.deleteDialog = !$store.state.deleteDialog"
                       v-if="selectedIds.length > 1">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} selected
            </el-button>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-2 ms-auto d-flex justify-content-end">
          <el-input class="align-self-end" placeholder="Search here..."
                    @clear="getUsers" v-model="filter.search"
                    @keyup="getUsers"
                    clearable>
            <template #append>
              <el-button @click="getUsers">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <el-table stripe style="width: 100%" border
                    header-cell-class-name="tbl-header" :data="Users">
            <template #append>
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :total="filter.totalCount"
                  :page="filter.currentPage"
                  :page-size="filter.pageSize"
                  :page-sizes="[10, 20, 30, 40]"
                  layout=" prev, pager, next,sizes"
                  class="bg-gray p-2"
                  background
                  small
              >
              </el-pagination>
            </template>
            <el-table-column type="selection"/>
            <el-table-column min-width="150" prop="displayName" :label="getMessageByCode('name')??' Name'"/>
            <el-table-column min-width="220" prop="email" :label="getMessageByCode('email')??' Email'"/>
            <el-table-column min-width="150" prop="mobile" :label="getMessageByCode('mobile')??' Mobile'"/>
            <el-table-column min-width="150" prop="role" :label="getMessageByCode('role') ?? ' Role'"></el-table-column>
            <el-table-column v-if="userDel || userEdit" fixed="right" :label="getMessageByCode('action')??' Actions'" align="center" width="100">
              <template #default="scope">
                <el-space spacer="|" alignment="center">
                  <i v-show="userEdit"
                     class="mgc_edit_line text-primary action-btn"
                     @click="editUser('edit',scope.row)"
                  ></i>
                  <i v-show="userDel"  @click="editUser('del',scope.row)"
                     class="mgc_delete_2_line text-danger action-btn"
                  ></i>
                </el-space>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border d-loader"></div>


    <el-dialog :title="User.id > 0 ? 'Update Profile' : 'Add Profile'" v-model="addUserDialog" width="600">
      <el-form :model="User" hide-required-asterisk label-position="top">
        <div class="row">
          <div class="col-12 col-md-6" v-if="this.clientId == null">
            <el-form-item>
              <template #label>
                <span class="">Client</span>
              </template>
              <el-select :disabled="clientId ?? null" v-model="User.clientId" filterable class="w-100"
                         placeholder="Select"
              >
                <el-option
                    v-for="item in Clients"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item>
              <template #label>
                <span class="required">Full Name</span>
              </template>
              <el-input v-model="User.displayName"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item>
              <template #label>
                <span class="required">Username</span>
              </template>
              <el-input v-model="User.userName"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item>
              <template #label>
                <span class="required">Email</span>
              </template>
              <el-input v-model="User.email"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6">
            <el-form-item>
              <template #label>
                <span class="">Phone</span>
              </template>
              <el-input v-model="User.mobile"></el-input>
            </el-form-item>
          </div>
          <div class="col-12 col-md-6 d-flex justify-content-between">
            <el-form-item>
              <template #label>
                <span class="">Active</span>
              </template>
              <el-switch active-text="Yes" inactive-text="No" v-model="User.isActive"></el-switch>
            </el-form-item>

          </div>
          <div class="col-12 col-md-6 d-flex justify-content-between">
            <el-form-item>
              <template #label>
                <span class="">Email Notification</span>
              </template>
              <el-switch active-text="Yes" inactive-text="No" v-model="User.isEmailNotification"></el-switch>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="addUserDialog = false" class="me-2">Cancel</el-button>
          <el-button v-if="User.id < 1" type="primary" @click="addUser()">Add User</el-button>
          <el-button v-else type="primary" @click="updateUser()">Update User</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
        v-model="deleteUserDialog"
        title="Delete User"
        width="30%"
    >
      <span>Do you want to delete {{ User.displayName }}</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button bg text @click="deleteUserDialog = false">Cancel</el-button>
        <el-button class="bg-danger text-white border-0" @click="deleteUserDialog = false; deleteUser()">
          Delete
        </el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import userService from "@/Services/ComponentsServices/userService";
import {checkPermission} from "@/Utility/CheckPermission";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "UsersList",
  components: {BreadCrumb},
  data() {
    return {
      deleteUserDialog: false,
      addUserDialog: false,
      userCreate: false,
      userEdit: false,
      userView: false,
      userDel: false,
      edit: false,
      userId:sessionStorage.getItem("userId"),
      translations: [],
      roles: [],
      User: {
        "id": 0,
        "clientId": sessionStorage.getItem("clientId") ?? 0,
        "userName": "",
        "email": "",
        "displayName": "",
        "isActive": true,
        "mobile": "",
        "isEmailNotification": true,
        "image": "",
        "password": ""
      },
      Users: [],
      clientId: sessionStorage.getItem("clientId"),
      Clients: [
        {
          id: 0,
          name: "Super Admin"
        }
      ],
      selectedIds: [],
      selectedRows: [],
      loading: false,
      filter: {
        clientId: sessionStorage.getItem("clientId") ?? 0,
        providerId: sessionStorage.getItem("providerId")?? 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        id:0,
      },

    }
  },
  methods: {

    editUser(type, user) {
      switch (type) {
        case "edit":
          this.User = {...user};
          //this.addUserDialog = true;
          this.$store.state.pageId = this.User.id;
          this.$router.push('add-user')
          break;
        case "del":
          this.User = {...user};
          if (this.User.id === this.userId){
            ShowMessage("error","Cannot delete own user");
            return;
          }
          this.deleteUserDialog = true;
          break;
        case 0:
          this.resetUser();
          this.addUserDialog = true;
          break;
      }
    },
    resetUser(){
      this.User = {
        "id": 0,
        "clientId": sessionStorage.getItem("clientId") ?? "",
        "userName": "",
        "email": "",
        "displayName": "",
        "isActive": true,
        "mobile": "",
        "isEmailNotification": true,
        "image": "",
        "password": ""
      }
    },
    async deleteUser() {
      try {
        // eslint-disable-next-line no-unused-vars
        await userService.delete(this.User.id);
       await this.getUsers();
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!");
      }
    },
    handleSelectionChange(selection) {
      this.selectedRows = selection;
    },

    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getUsers();
    }, handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getUsers();
    },

    async getUsers() {
      try {

        this.loading = true;
        let response = await userService.all(this.filter);
        this.Users = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;

        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async addUser() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.add(this.User);
        if (response.status === 200) {
          ShowMessage("success", "Profile added");
          this.addUserDialog = false;
          await this.getUsers();
        }
        this.loading = false;

      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.")
      }
    },
    async updateUser() {
      try {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        let response = await userService.update(this.User);
        if (response.status === 200) {
          ShowMessage("success", "Profile updated");
          this.addUserDialog = false;
         await this.getUsers();
        }
        this.loading = false;

      } catch {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.")
      }
    },
        getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.userId = Number(sessionStorage.getItem("userId"))
    this.translations = getLanguage();
    this.userCreate = checkPermission("MD_USER_CREATE")
    this.userDel = checkPermission("MD_USER_DEL")
    this.userEdit = checkPermission("MD_USER_EDIT")
      this.getUsers();
  }
}
</script>

<style scoped>

</style>