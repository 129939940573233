import {createRouter, createWebHistory} from "vue-router";
import Login from "@/components/Authentication/Login.vue";
import SADashboard from "@/components/Dashboard/SADashboard";
import CLDashboard from "@/components/Dashboard/CLDashboard";
import Users from "@/components/Users/UsersList.vue";
import Settings from "@/components/Settings/Settings";
import UserProfile from "@/components/Profile/UserProfile"
import {isAuthenticated, isAdmin} from "@/components/Authentication/Authenticate";
import ForBidden from "@/components/404/Forbidden.vue";
import PageNotFound from "@/components/404/PageNotFound";
import {ShowMessage} from "@/Utility/Utility";
import OptionsList from "@/components/Options/OptionsList.vue";
import RolesList from "@/components/Roles/RolesList.vue"
import RolesPermissions from "@/components/Roles/RolesPermissions.vue";
import ForgotPassword from '@/components/Authentication/ForgotPassword.vue'
import ResetPassword from "@/components/Authentication/ResetPassword.vue"
import TemplatesList from "@/components/Templates/TemplatesList.vue"
import DocumentsExpiry from "@/components/Reports/DocumentsExpiry.vue";
import MissingDocuments from "@/components/Reports/MissingDocuments.vue";
import Signup from "@/components/Authentication/Signup.vue";
import Register from "@/components/Authentication/Register.vue";
import AddUser from "@/components/Users/AddUser.vue";
import Jobs from "@/components/Jobs/Jobs.vue";
import AddJob from "@/components/Jobs/AddJob.vue";
import ApplicantsList from "@/components/Applicants/ApplicantsList.vue";
import AddApplicant from "@/components/Applicants/AddApplicant.vue";
import Invite from "@/components/Invites/Invite.vue"
import JobsOffered from "@/components/Offers/JobsOffered.vue";
import {checkPermission} from "@/Utility/CheckPermission";
import {getMessageByCode} from "@/Utility/getMessagebyCode";
import DocTemplates from "@/components/DocTemplates/DocTemplates.vue";
import ApplicationsList from "@/components/Applications/ApplicationsList.vue";
import Process from "@/components/Applications/Process/Process.vue";
import StagesList from "@/components/Applications/Stages/StagesList.vue";
import SectionsList from "@/components/Applications/Sections/SectionsList.vue";
import QuestionsList from "@/components/Applications/Questions/QuestionsList.vue";
import AddQuestion from "@/components/Applications/Questions/AddQuestion.vue";
import ViewApplication from "@/components/Applications/ViewApplication.vue";
import ApprovalsList from "@/components/Applications/Approvals/ApprovalsList.vue";
import store from '../store/index'
import StageData from "@/components/Applications/Approvals/StageData.vue"
import PrintPreview from "@/components/Applications/PrintPreview.vue"

const routes = [
    {
        path: '/print-preview',
        name: 'PrintPreview',
        component: PrintPreview,
        beforeEnter: (to, from, next) => {
            store.dispatch('toggleLayout', false);
            next();
        },
        beforeRouteLeave: (to, from, next) => {
            store.dispatch('toggleLayout', true);
            next();
        },
    },
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    }, {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    }, {
        path: "/password-reset",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/403",
        name: "ForBidden",
        component: ForBidden,
    },

    {
        path: "/dashboard",
        name: "SADashboard",
        component: SADashboard,
        meta: {
            pageTitle: 'Dashboard',
            breadcrumbs: ['Dashboard'],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('dashboard') ?? 'Dashboard'
            to.meta.breadcrumbs = getMessageByCode('dashboard') ?? 'Dashboard'
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/applicants",
        name: "Applicants",
        component: ApplicantsList,
        meta: {
            pageTitle: 'Applicants',
            breadcrumbs: ['Applicants'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    }, {
        path: "/approvals",
        name: "ApprovalsList",
        component: ApprovalsList,
        meta: {
            pageTitle: 'Approvals',
            breadcrumbs: ['Approvals'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    }, {
        path: "/stagedata",
        name: "StageData",
        component: StageData,
        meta: {
            pageTitle: 'Stage Data',
            breadcrumbs: ['StageData'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    }, {
        path: "/application",
        name: "ViewApplication",
        component: ViewApplication,
        meta: {
            pageTitle: 'View Application',
            breadcrumbs: ['View Application'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },


    {
        path: "/processes",
        name: "Process",
        component: Process,
        meta: {
            pageTitle: 'Process',
            breadcrumbs: ['Process'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    }, {
        path: "/stages",
        name: "StagesList",
        component: StagesList,
        meta: {
            pageTitle: 'Application Stages',
            breadcrumbs: ['Application Stages'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/sections",
        name: "SectionsList",
        component: SectionsList,
        meta: {
            pageTitle: 'Application Sections',
            breadcrumbs: ['Application Sections'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/questions",
        name: "QuestionsList",
        component: QuestionsList,
        meta: {
            pageTitle: 'Application Questions',
            breadcrumbs: ['Application Questions'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    }, {
        path: "/question",
        name: "AddQuestion",
        component: AddQuestion,
        meta: {
            pageTitle: 'Application Question',
            breadcrumbs: ['Application Question'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/Applications",
        name: "Applications",
        component: ApplicationsList,
        meta: {
            pageTitle: 'Applications',
            breadcrumbs: ['Applications'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/offers",
        name: "JobsOffered",
        component: JobsOffered,
        meta: {
            pageTitle: 'Offers',
            breadcrumbs: ['ApplicantsOffers'],
        },
        beforeEnter: (to, from, next) => {

            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/add_applicant",
        name: "Add Applicants",
        component: AddApplicant,
        meta: {
            pageTitle: 'Applicant',
            breadcrumbs: ['Add'],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('applicant') ?? 'Applicant'
            to.meta.breadcrumbs = [getMessageByCode('dashboard') ?? 'Add']
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/jobs",
        name: "Jobs",
        component: Jobs,
        meta: {
            pageTitle: 'Jobs',
            breadcrumbs: ['Jobs'],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('jobs') ?? 'Jobs'
            to.meta.breadcrumbs = getMessageByCode('jobs') ?? 'Jobs'
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/add_job",
        name: "Add Job",
        component: AddJob,
        meta: {
            pageTitle: 'Job',
            breadcrumbs: ['Add'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && isAdmin()) {
                next();
            } else if (isAuthenticated() && !isAdmin()) {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            } else {
                next('/');
            }
        },
    },
    {
        path: "/invitations",
        name: "Invitations",
        component: Invite,
        meta: {
            pageTitle: 'Application Invites',
            breadcrumbs: ['Application Invites'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/docs",
        name: "Document Templates",
        component: DocTemplates,
        meta: {
            pageTitle: 'Document Template',
            breadcrumbs: ['Document Templates'],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/home",
        name: "CLDashboard",
        component: CLDashboard,
        meta: {
            pageTitle: "Dashboard",
            breadcrumbs: ["Dashboard"],
        },
        beforeEnter: (to, from, next) => {
            to.meta.pageTitle = getMessageByCode('dashboard') ?? 'Dashboard'
            to.meta.breadcrumbs = getMessageByCode('dashboard') ?? 'Dashboard'
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },

    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
            pageTitle: 'Users',
            breadcrumbs: ['Users'],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_USER_VIEW")) {
                if (isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    }, {
        path: "/add-user",
        name: "Add User",
        component: AddUser,
        meta: {
            pageTitle: getMessageByCode('add_user') ?? 'Add Profile',
            breadcrumbs: [getMessageByCode('add_user') ?? 'Add Profile'],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_USER_CREATE")) {
                if (isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            pageTitle: "Settings",
            breadcrumbs: ["Settings",],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_CONFIG_VIEW")) {
                if (isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    }, {
        path: "/templates",
        name: "templates",
        component: TemplatesList,
        meta: {
            pageTitle: "Templates",
            breadcrumbs: ["Templates",],
        },
        beforeEnter: (to, from, next) => {
            if (checkPermission("MD_TEMPLATE_VIEW")) {
                if (isAuthenticated()) {
                    next();
                } else {
                    next('/');
                }
            } else {
                ShowMessage("error", "Unauthorized");
                router.push("/403")
            }
        },
    },
    {
        path: "/roles",
        name: "roles",
        component: RolesList,
        meta: {
            pageTitle: "Roles",
            breadcrumbs: ["Roles",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated() && checkPermission("MD_ROLE_VIEW")) {
                next();
            } else {
                next('/');
            }
        },
    }, {
        path: "/roles-permissions",
        name: "roles-permissions",
        component: RolesPermissions,
        meta: {
            pageTitle: "Role Permissions",
            breadcrumbs: ["Role Permissions",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/options",
        name: "options",
        component: OptionsList,
        meta: {
            pageTitle: "Options",
            breadcrumbs: ["Options",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated()) {
                next();
            } else {
                next('/');
            }
        },
    },

    {
        path: "/profile", name: "profile", component: UserProfile, meta: {
            pageTitle: "Profile", breadcrumbs: ["Profile",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/document_expiry", name: "documentexpiry", component: DocumentsExpiry, meta: {
            pageTitle: "Documents Expiry", breadcrumbs: ["Documents Expiry",],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated && checkPermission("MD_DOC_EXP_VIEW")) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: "/missing_documents", name: "missingdocuments", component: MissingDocuments, meta: {
            pageTitle: "Missing Documents", breadcrumbs: ["Missing Documents"],
        },
        beforeEnter: (to, from, next) => {
            if (isAuthenticated && checkPermission("MD_DOC_MISS_VIEW")) {
                next();
            } else {
                next('/');
            }
        },
    },
    {
        path: '/404',
        name: 'NotFound',
        component: PageNotFound
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // Check if the requested route exists
    store.state.previousRoute = from;
    if (router.hasRoute(to.name)) {
        next(); // Route exists, proceed with the navigation
    } else {
        next({name: 'NotFound'}); // Route not found, redirect to the not-found route
    }
});

//let propertyView = checkPermission("MD_PROP_VIEW");
//let clientView = checkPermission("MD_CLIENT_VIEW");
//let providerView = checkPermission("MD_PRO_VIEW");
//let tenantView = checkPermission("MD_TEN_VIEW");
//let complianceView = checkPermission("MD_COMP_VIEW");
//let userView = checkPermission("MD_USER_VIEW");
//let roleView = checkPermission("MD_ROLE_VIEW");
//let missDocView = checkPermission("MD_DOC_MISS_VIEW");
//let docExpView = checkPermission("MD_DOC_EXP_VIEW");
//let tenantReportView = checkPermission("MD_TEN_REPORT_VIEW");
//let roomReportView = checkPermission("MD_ROOM_REP_VIEW");
//let templateView = checkPermission("MD_TEMPLATE_VIEW");


export default router;
