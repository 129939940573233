<template>

  <div class="container-fluid p-2 p-md-0 main-wrapper" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
    <div class="row login-wrapper justify-content-center align-items-center">
      <div class="col-11 col-md-9 bg-white shadow rounded">
<!--        <el-progress v-if="loading" :percentage="100"-->

        <!--                     :indeterminate="true"-->
        <!--                     :duration="1"-->
        <!--                     striped-->
        <!--                     :stroke-width="4"-->
        <!--                     striped-flow-->
        <!--                     :show-text="false"></el-progress>-->
        <div class="row">
          <div class="col-12 col-lg-6 px-5 py-5 text-center sign-in-col">
            <div class="mb-3">
              <h3 class="fw-bold">Sign In</h3>
              <span>New Here?
              <router-link to="signup">
             Create an account
              </router-link>

              </span>
            </div>
            <div class="col-12 col-md-11 m-0 p-0">
              <el-form>
                <el-form-item>
                  <label>Email</label>
                  <el-input
                      v-model="User.userName"
                      class="inpt-bg"
                      size="large"
                      tabindex="1"
                      @keydown.space.prevent
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="d-flex justify-content-between w-100">
                    <label>Password</label>
                    <router-link to="forgot-password">
                      <a href=""> Forgot password ? </a>
                    </router-link>
                  </div>
                  <el-input
                      v-model="User.password"
                      :type="showPass ? 'text' : 'password'"
                      size="large"
                      tabindex="2"
                      @keyup.enter="Login"
                  >
                    <template #append>
                      <el-button @click="showPass = !showPass">
                        <i v-if="showPass" class="bi bi-eye"></i>
                        <i v-else class="bi bi-eye-slash"></i>
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item class="mt-5">
                  <el-button
                      :disabled="loading || (!User.userName || !User.password)"
                      :loading="loading"
                      class="mbtn-primary rounded-1 w-100"
                      size="large"
                      @click="Login"
                  >{{ loading ? "Signing In" : "Continue" }}
                  </el-button
                  >
                </el-form-item>
                <el-divider>OR</el-divider>
                <el-form-item>
                  <el-button bg class="btn btn-light w-100" size="large" text>
                    <img
                        alt=""
                        src="../../assets/svg/brand-logos/google-icon.svg"
                        width="18"
                    />
                    <span class="ms-2">Continue with Google</span>
                  </el-button>
                </el-form-item>
                <el-form-item>
                  <el-button bg class="btn btn-light w-100" size="large" text>
                    <img
                        alt=""
                        src="../../assets/svg/brand-logos/facebook-4.svg"
                        width="18"
                    />
                    <span class="ms-2">Continue with Facebook</span>
                  </el-button>
                </el-form-item>
                <el-form-item>
                  <el-button bg class="btn btn-light w-100" size="large" text>
                    <img
                        alt=""
                        src="../../assets/svg/brand-logos/apple-black.svg"
                        width="18"
                    />
                    <span class="ms-2">Continue with Apple</span>
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="col-12 col-md-6 login-side-section">
            <img src="../../assets/images/login.png" alt="side-img"/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="spinner-border login-loader"></div>
  </div>
</template>
<script>
import axios from "axios";
import {ShowMessage} from "@/Utility/Utility";
import {jwtDecode} from "jwt-decode";
import loginServices from "@/Services/loginServices";
import configService from "@/Services/ComponentsServices/configService";
import clientService from "@/Services/ComponentsServices/clientService";
import {useLoadingBar} from "naive-ui";

export default {
  name: "LogIn",
  components: {},
  data() {
    return {
      loading: false,
      showPass: false,
      imageUrl: '',
      loadingBar: useLoadingBar(),
      User: {
        userName: "",
        password: "",
      },
    };
  },
  methods: {
    Login: async function () {
      try {
        this.loadingBar.start();
        this.loading = true;
        let response = await loginServices.authenticate(this.User);
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + response.data;
        sessionStorage.setItem("token", response.data);

        const decodedToken = jwtDecode(response.data);

        await this.getPermissions(decodedToken.primarysid)
        sessionStorage.setItem("mUserId", decodedToken.primarysid);
        this.loadingBar.finish();
      } catch (e) {
        this.loadingBar.error();
        this.loading = false;
        if (e.response !== undefined) {
          ShowMessage("error", e.response.data.message);
        } else {
          ShowMessage("error", "Something went wrong!")
        }
      }
    },
    async getUser(userId) {
      try {
        const response = await loginServices.getUser(userId);

        sessionStorage.setItem("roleName", response?.data?.roleName)
        sessionStorage.setItem("userId", response?.data?.id)
        sessionStorage.setItem("applicantId", response?.data?.applicantId);
        sessionStorage.setItem("role", response?.data?.roleName);
        this.$store.state.loggedIn = true;
        if (response.data.applicantId <= 1) {
          this.$router.push('/dashboard')
        } else {
          this.$router.push('/offers')

        }
        await this.getLanguage(response.data.iso);
        sessionStorage.setItem("loggedIn", true);


      } catch (error) {
        console.error("Error fetching user or setting language:", error);
      }
    },

    async getLanguage(iso) {
      try {
        const response = await clientService.getClientLanguage(iso);
        sessionStorage.setItem("language", JSON.stringify(response.data));
        return response;
      } catch (error) {
        console.error("Error fetching language:", error);
      }
    },
    async getPermissions(userId) {
      try {
        let response = await loginServices.getPermissions();
        sessionStorage.setItem("permissions", JSON.stringify(response.data));
        await this.getUser(userId);
      } catch (e) {
        console.log(e)
      }
    },

    async getTitle(clientId) {
      try {

        const response = await configService.getByKey(clientId, 'AppTitle');
        const response2 = await configService.getByKey(clientId, 'FavIcon');

        if (response.data.value !== undefined) {
          // Replace with your actual API endpoint
          /*const favicon = document.getElementById('favicon');
          if (favicon) {
            favicon.href = faviconUrl;
          }

          // Set title dynamically*/
          document.title = response.data.value;
        }
        if (response2.data.value !== undefined) {
          const icon = response2.data.value;
          // Convert base64 to Blob
          this.updateFavicon(icon)

        }

      } catch (error) {
        console.error('Error fetching app data', error);
      }
    },
    updateFavicon(imageString) {
      // Create a link element
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");

      // Set the attributes for the link element
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = imageString;

      // Update the favicon in the document's head
      document.head.appendChild(link);
    },
    ClearUserData() {
      this.$store.state.loggedIn = false;
      sessionStorage.removeItem("loggedIn");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("applicantId");
      sessionStorage.clear();
      localStorage.clear();
      window.caches
    },
    async getBackground() {
      try {
        let response = await configService.getByKey("LoginBackground");
        this.imageUrl = response?.data?.value;
      } catch (e) {
        console.log(e)
      }
    }
  },
  beforeCreate() {


  },
  created() {
    this.ClearUserData();
    this.getBackground();
  }
};
</script>
<style scoped>
.main-wrapper {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-wrapper {
  height: 100vh;
  overflow: hidden !important;
  z-index: 999 !important;
}

.login-loader {
  position: absolute;
  z-index: 999;
  left: 30%;
  top: 34%;
}

</style>
