<template>
  <div class="container-fluid bg-gray">
    <!--    <ToolBar :showCancel="true" back-route="tenants" :show-select="false" :ShowAddBtn="true" :btn-text="mainBtnText"
                 @addbtn-clicked="AddOrUpdateTenant"/>-->
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row align-items-center py-2">
        <div class="col-12 col-md-8">
          <BreadCrumb :view="edit" :id="applicant.id" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="applicants">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              {{ getMessageByCode('cancel') ?? 'Cancel' }}
            </el-button>
          </router-link>
          <el-button v-else @click="checkApplicant()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            {{ getMessageByCode('cancel') ?? 'Cancel' }}
          </el-button>
          <el-button v-if="edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            {{ getMessageByCode('edit') ?? 'Edit' }}
          </el-button>
          <el-button @click="addApplicant" v-if="applicant.id < 1"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('add_appl') ?? 'Add Applicant' }}
          </el-button>
          <el-button @click="updateApplicant()" v-if="applicant.id > 0 &&!edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('upd_appl') ?? 'Update Applicant' }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="container-fluid m-0 p-0">
      <el-form ref="tenantForm" :model="applicant">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="card border-0 shadow-sm ">
              <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                <span
                    class="card-title mb-0 text-capitalize">{{
                    getMessageByCode('appl_info') ?? 'Applicant Info'
                  }}</span>
                <span class="bi bi-card-checklist"></span>
              </div>
              <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
              <div class="card-body">
                <div>
                  <label class="lblText required">{{ getMessageByCode('first_name') ?? 'First Name' }}</label>
                  <el-form-item prop="forename" :rules="formRules.firstName" class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.forename">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText required text-capitalize">{{ getMessageByCode('surname') ?? 'Surname' }}</label>
                  <el-form-item prop="surname" :rules="formRules.surName" class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.surname">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText  text-capitalize">{{ getMessageByCode('dob') ?? 'Date of Birth' }}</label>
                  <el-form-item class="mb-2">
                    <el-date-picker :disabled="edit" :editable="false" class="w-100" :format="dateFormat"
                                    v-model="applicant.dateOfBirth"/>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText  text-capitalize">{{ getMessageByCode('gender') ?? 'Gender' }}</label>
                  <el-form-item class="mb-2">
                    <el-select :disabled="edit" v-model="applicant.gender" class="w-100">
                      <el-option
                          v-for="item in genders"
                          :key="item.optId"
                          :label="item.optTitle"
                          :value="item.optTitle"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText  text-capitalize">{{ getMessageByCode('ethn') ?? 'Ethnicity' }}</label>
                  <el-form-item class="mb-2">
                    <el-select :disabled="edit" v-model="applicant.nationality" class="w-100">
                      <el-option
                          v-for="item in ethnicities"
                          :key="item.optId"
                          :label="item.optTitle"
                          :value="item.optTitle"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card border-0 shadow-sm ">
              <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                <span
                    class="card-title mb-0 text-capitalize">{{
                    getMessageByCode('address') ?? 'Address'
                  }}</span>
                <span class="bi bi-sign-turn-right"></span>
              </div>
              <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
              <div class="card-body">
                <div>
                  <label class="lblText">{{ getMessageByCode('address') ?? 'Address' }} 1</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.address1">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('address') ?? 'Address' }} 2</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.address2">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('address') ?? 'Address' }} 3</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.address3">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('post_code') ?? 'Post Code' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.postCode">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText  text-capitalize">{{ getMessageByCode('ni') ?? 'NI Number' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.niNumber">
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card border-0 shadow-sm ">
              <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
                <span
                    class="card-title mb-0 text-capitalize">{{
                    getMessageByCode('contact') ?? 'Contact'
                  }}</span>
                <span class="bi bi-telephone-outbound fs-6"></span>
              </div>
              <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>
              <div class="card-body">
                <div>
                  <label class="lblText required">{{ getMessageByCode('email') ?? 'Email' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.email">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('home_phone') ?? 'Home Telephone' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.homeTelephoneNumber">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <label class="lblText">{{ getMessageByCode('mobile_phone') ?? 'Mobile Number' }}</label>
                  <el-form-item class="mb-2">
                    <el-input :disabled="edit" v-model="applicant.mobileTelephoneNumber">
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>

import {defineComponent} from "vue";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import optionService from "@/Services/ComponentsServices/optionService";
import {getLanguage} from "@/Utility/getLanguage";
import configService from "@/Services/ComponentsServices/configService";
import applicantService from "@/Services/ComponentsServices/applicantService";
import {ShowMessage} from "@/Utility/Utility";
import store from "@/store";

export default defineComponent({
  components: {BreadCrumb},
  data() {
    return {
      loading: true,
      edit: true,
      genders: [],
      ethnicities: [],
      currency: "£",
      currencyPosition: "right",
      dateFormat: "DD/MM/YYYY",
      applicant: {
        "id": 0,
        "surname": "",
        "forename": "",
        "dateOfBirth": null,
        "nationality": "",
        "gender": "",
        "niNumber": "",
        "address1": "",
        "address2": "",
        "address3": "",
        "postCode": "",
        "homeTelephoneNumber": "",
        "mobileTelephoneNumber": "",
        "email": ""
      },
      formRules: {
        firstName: [
          {required: true, message: 'Please enter forename', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        surName: [
          {required: true, message: 'Please enter surname', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        address1: [
          {required: true, message: 'Please enter your name', trigger: 'blur'},
          // You can add more rules for the name field if needed
        ],
        emailRule: [
          {required: true, message: 'Please enter the email', trigger: 'blur'},
          {type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          {validator: this.validateEmail, trigger: 'blur'}
        ],

      },
      translations: [],
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {
    checkApplicant() {
      if (this.applicant.id < 1) {
        this.$router.push('applicants');
        return;
      }
      this.edit = !this.edit
    },
    async addApplicant() {
      this.addDialog = false;
      this.loading = true;
      try {
        let response = await applicantService.add(this.applicant);
        if (response.status === 200) {
          ShowMessage('success', "Applicant added");
          this.$router.push('/applicants')
        }

      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!.")
      }
      this.loading = false;
    },
    async updateApplicant() {
      this.addDialog = false;
      this.loading = true;
      try {
        let response = await applicantService.update(this.applicant);
        if (response.status === 200) {
          ShowMessage('success', "Applicant updated");
          this.$router.push('/applicants')
        }
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong!.")
      }
      this.loading = false;
    },

    async getEthnicities() {
      try {
        let response = await optionService.getOptionsByHeaderId(21)
        this.ethnicities = response.data ?? [];

      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getGenders() {
      try {
        let response = await optionService.getOptionsByHeaderId(20)
        this.genders = response.data ?? [];
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let response = await configService.getByKey("CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey("CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey("DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getApplicant() {
      this.loading = true;
      try {
        let response = await applicantService.getById(this.$store.state.pageId);
        this.applicant = response.data;
      } catch (e) {
        console.log(e)
      }
    }
  },
  created() {
    this.translations = getLanguage();
    if (store.state.pageId > 0) {
      this.getApplicant();
    } else {
      this.edit = false;
    }
    this.getGenders();
    this.getEthnicities();
  }
})
</script>

<style scoped>

</style>