<template>
  <div>
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-12 col-md-7">
          <div class="card">
            <div class="card-header color-primary py-3">
              <h3>{{ application?.title }}</h3>
            </div>
            <div class="card-body">
              <div v-for="(stage, index) in application?.process?.stages" :key="stage.id">
                <n-avatar class="color-primary border-5 border-white" size="small" circle
                          style="position: absolute; left: -15px">
                  <span>{{ index + 1 }}</span>
                </n-avatar>
                <span class="ps-2 h5">{{ stage.title }}</span>
                <div style="background-color: #fcfcfc !important;" class="p-2 rounded-2">
                  <div v-for="section in stage.sections" :key="section.id" class="rounded-2  ">
                    <div>
                      <el-form label-position="top">
                        <el-form-item v-for="question in section?.questions" :key="question.id" class="mb-0"
                                      :class="question.cssClass">

                          <span :class="question.isRequired ? 'required' : ''" class="m-0">{{ question.title }}</span>

                          <el-input disabled
                                    v-if="getQuestionType(question.questionType) === 'upload' && !question.validation"
                                    v-model="question.fileName">
                            <template #prepend>
                              <el-upload disabled :auto-upload="false"
                                         :show-file-list="false"
                                         class="mb-0">
                                <template #trigger>
                                  <el-button disabled type="primary" class="border">Choose file</el-button>
                                </template>
                              </el-upload>
                            </template>
                          </el-input>
                          <el-input disabled
                                    v-if="getQuestionType(question.questionType) === 'freetext' && !question.validation"
                                    v-model="question.value">
                          </el-input>
                          <el-input disabled type="textarea" :rows="3"
                                    v-if="getQuestionType(question.questionType) === 'textarea' && !question.validation"
                                    v-model="question.value">
                          </el-input>
                          <div class="w-100"
                               v-if="getQuestionType(question.questionType) === 'freetext' &&  question?.validation === 'email'">
                            <el-input disabled v-model="question.value"></el-input>

                          </div>
                          <div class="w-100"
                               v-if="getQuestionType(question.questionType) === 'date' &&  question?.validation === 'date'">
                            <el-date-picker disabled :format="dateFormat" :editable="false" v-model="question.value"
                            ></el-date-picker>

                          </div>
                          <el-select disabled v-if="getQuestionType(question.questionType) === 'dropdown'"
                                     v-model="question.value">
                            <el-option v-for="option in getOptionsForHeaderId(question.optionHeaderId ?? 0)"
                                       :key="option.optId"
                                       :label="option.optTitle" :value="option.optTitle">
                            </el-option>
                          </el-select>
                          <el-select disabled v-if="getQuestionType(question.questionType) === 'yesno'"
                                     v-model="question.value">
                            <el-option label="Yes" value="true"></el-option>
                            <el-option label="No" value="false"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <n-spin v-show="loading" description="Loading" class="print-spinner" size="small">
    </n-spin>
  </div>
</template>
<script>
import {NSpin} from "naive-ui";
import applicationService from "@/Services/ComponentsServices/applicationService";
import {ShowMessage} from "@/Utility/Utility";
import optionService from "@/Services/ComponentsServices/optionService";
import configService from "@/Services/ComponentsServices/configService";
import {NAvatar} from "naive-ui";

export default {
  name: "PrintPreview",
  components: {NAvatar, NSpin},
  data() {
    return {
      loading: false,
      optionsMap: new Map(),
      applicationId: 0,
      application: {},
      currency: "£",
      currencyPosition: "right",
      dateFormat: "DD/MM/YYYY",
      questionTypes: [],
    }
  },
  methods: {
    async getApplication(applicationId) {
      this.loading = true;
      try {
        let response = await applicationService.viewApplication(applicationId);
        this.application = response?.data ?? {};
      } catch (e) {
        console.log(e)
        ShowMessage("error", e?.response?.message ?? "Something went wrong");
      }
      this.loading = false;
    },
    getOptionsForHeaderId(headerId) {
      return this.optionsMap.get(headerId) || [];
    },
    getQuestionType(id) {
      return this.questionTypes.find(x => x.optId === id)?.optValue.toLowerCase() ?? "";

    },
    async getQuestionTypes() {
      try {
        let response = await optionService.getOptionsByKey("QuestionTypes");
        this.questionTypes = response?.data ?? [];
      } catch (e) {
        console.log(e)
      }
    },
    async getConfigs() {
      try {
        let response = await configService.getByKey("CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey("CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey("DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";

      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.applicationId = this.$route.query.id;
    if (this.applicationId > 0) {
      this.getApplication(this.applicationId);
    }
    this.getConfigs();
    this.getQuestionTypes();
  }
}
</script>

<style scoped>
.print-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

</style>