import {createStore} from 'vuex'
import {getLanguage} from "@/Utility/getLanguage";

export default createStore({
    state: {
        darkTheme: false,
        pageId: 0,
        stageId: 0,
        applicationId: 0,
        sidebarCollapsed: false,
        addUserDialog: false,
        addNoteDialog: false,
        applicationReadOnly: false,
        addComplianceDocDialog: false,
        loggedIn: false,
        showLayout: true,
        percentage: 0,
        loading: false,
        deleteDialog: false,
        allDeleteDialog: false,
        roleId: 0,
        refreshProfile: false,
        securityRoleId: 0,
        selectedIds: [],
        entityName: "",
        addCompDlg: false,
        addDocumentD: false,
        language: getLanguage(),
        clientId: 0,
        roleName: sessionStorage.getItem("roleName"),
        entityId: 0,
        Clients: [],
        previousRoute: "",
        filter: {
            clientId: 1,
            providerId: 1,
            search: "",
            sort: "",
            pageSize: 10,
            currentPage: 1,
            totalCount: 0,
        },
    },
    getters: {
        showLayout: state => state.showLayout,
    },
    mutations: {
        setDialog(state, value) {
            state.deleteDialog = value;
        },
        setShowLayout(state, payload) {
            state.showLayout = payload;
        },
    },
    actions: {
        toggleLayout({commit}, showLayout) {
            commit('setShowLayout', showLayout);
        },
    },

    modules: {}
})
