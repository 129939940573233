<template>
  <div class="container-fluid">
    <ToolBar :ShowAddBtn="true" :show-bread="true" :showSelect="false"
             :btnText="getMessageByCode('add_temp')??'Add Template'" @addbtn-clicked="openDialog"/>
    <div>
      <div class="d-flex justify-content-end mb-1">
        <div class="col-12 col-md-8">
          <div class="d-flex justify-content-end">
            <el-button v-if="selectedIds.length > 1"
                       class="bg-danger text-white fw-normal me-2 border-0"
                       @click="$store.state.deleteDialog = !$store.state.deleteDialog">
              <i class="bi bi-trash me-2"></i>
              {{ selectedIds.length }} selected
            </el-button>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-4 mb-1 ms-auto p-0 m-0 d-flex justify-content-end">

          <el-input v-model="filter.search" class="align-self-end" clearable
                    :placeholder="getMessageByCode('search')??'Search here...'"
                    @clear="getTemplates"
                    @keyup="getTemplates">
            <template #append>
              <el-button @click="getTemplates">
                <template #icon>
                  <i class="bi bi-search"></i>
                </template>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
      <el-table  :data="Templates"
                :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
                header-cell-class-name="tbl-header"
                stripe @selection-change="handleSelectionChange">
        <template #append>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :total="filter.totalCount"
              :page="filter.currentPage"
              :page-size="filter.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              layout=" prev, pager, next,sizes"
              class="bg-gray p-2"
              background
              small
          >
          </el-pagination>
        </template>
        <el-table-column align="center" type="selection" width="55"/>
        <el-table-column :label="getMessageByCode('')?? 'Title'" prop="etTitle" min-width="200">
          <!--          <template #default="scope">
                      <span class="router-text">{{
                          scope.row.name
                        }}</span>
                    </template>-->
        </el-table-column>
        <el-table-column :label="getMessageByCode('subject')??' Subject'" prop="etSubject" min-width="200"/>
        <el-table-column :label="getMessageByCode('code')??' Code'" prop="etCode" min-width="200"/>
        <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??' Actions'" width="130">
          <template #default="scope">
            <el-space alignment="center" spacer="|">
              <i @click="editTemplate(scope.row)"
                 class="mgc_edit_line text-primary action-btn"
              ></i>

              <i @click="deleteDialogShow(scope.row)"
                 class="mgc_delete_2_line text-danger action-btn"
              ></i>
            </el-space>
          </template>
        </el-table-column>
      </el-table>
    </div>


    <el-dialog
        v-model="addDialog"
        :title="template.etId < 1 ? getMessageByCode('add_temp')?? 'Add Template' :getMessageByCode('edit')?? 'Edit' + ' '+getMessageByCode('template')??'Template'"
        width="50%"

    >
      <el-form label-position="right" :model="template" ref="templateForm" label-width="100px" :rules="formRules">
        <el-form-item label="Type:">
          <el-select v-model="template.etType" clearable filterable class="w-100">

            <el-option
                v-for="item in types"
                :key="item.optId"
                :label="item.optTitle"
                :value="item.optId"
                style="font-weight: normal; color: #0a0a0a"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="getMessageByCode('title')??'Title'" prop="etTitle" :rules="formRules.title">
          <el-input v-model="template.etTitle"></el-input>
        </el-form-item>
        <el-form-item :label="getMessageByCode('subject')??'Subject'">
          <el-input v-model="template.etSubject"></el-input>
        </el-form-item>
        <el-form-item :label="getMessageByCode('code')??'Code'" prop="etCode" :rules="formRules.etCode">
          <el-input v-model="template.etCode"></el-input>
        </el-form-item>
      </el-form>
      <label>{{getMessageByCode('body')?? 'Body' }}</label>
      <ckeditor :editor="editor" v-model="template.etBody" :config="editorConfig" class="custom-ckeditor"></ckeditor>

      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="addDialog = false">Cancel</el-button>
        <el-button v-if="template.etId < 1" type="primary" class="fw-normal"
                   @click="AddTemplate()" :disabled="addBtnDsbld">
        {{getMessageByCode('add')?? 'Add' }}
        </el-button>
        <el-button v-else type="primary" class="fw-normal"
                   @click="UpdateTemplate()" :disabled="addBtnDsbld">
        {{getMessageByCode('update')?? 'Update' }}
        </el-button>
      </span>
      </template>
    </el-dialog>

    <!--    Delete Dialog-->
    <el-dialog
        v-model="deleteDialog"
        title="Delete Template"
        width="30%"

    >
      <span>Are your sure? you want to delete <span class="mfw-bold text-danger">{{ template.etTitle }}</span> template.</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button text bg @click="deleteDialog = false">Cancel</el-button>

        <button type="primary" class="fw-normal btn btn-sm btn-danger"
                @click="deleteDialog = false,deleteTemplate()" :disabled="addBtnDsbld">
        Delete
        </button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import ToolBar from "@/components/Layout/ToolBar.vue";
import templateService from "@/Services/ComponentsServices/templateService";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import optionService from "@/Services/ComponentsServices/optionService";
import {ShowMessage} from "@/Utility/Utility";
import {getLanguage} from "@/Utility/getLanguage";

export default {
  name: "TemplatesList",
  components: {ToolBar, ckeditor: CKEditor.component},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      addBtnDsbld: false,
      editor: ClassicEditor,
      editorConfig: {
        transformText: false,
      },
      Templates: [],
      translations: [],
      template: {
        "etId": 0,
        "etTitle": "",
        "etBody": "<p>Your content goes here!</p>",
        "etCode": "",
        "etSubject": "",
        "etType": null,
        "etIsActive": 1,
        "etCreatedDate": new Date(),
      },
      selectedIds: [],
      types: [],
      filter: {
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
      formRules: {
        title: [
          {required: true, message: 'Please enter the title', trigger: 'blur'},

        ],
        etCode: [
          {min: 3, max: 10, message: 'Template code must be of 3-10 characters', trigger: ['blur', 'change']},
        ],
      },
    }
  },
  methods: {

    deleteDialogShow(template) {
      this.template = template;
      this.deleteDialog = true;
    },
    editTemplate(template) {
      this.template = template;
      this.addDialog = true;
    },
    // validateCode(rule, value, callback) {
    //   console.log(value)
    //   if (value.length !== 10) {
    //     this.addBtnDsbld = true;
    //     callback(new Error('Template code must be of 10 characters'));
    //   } else {
    //     this.addBtnDsbld = false;
    //     callback();
    //   }
    // },
    openDialog() {
      this.resetTemplate();
      this.addDialog = true;
    },
    handleSelectionChange(selection) {
      this.selectedIds = selection.map(obj => obj.id);
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getTemplates();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getTemplates();
    },

    async deleteTemplate() {
      try {
        this.loading = true;
        let response = await templateService.deleteTemplate(this.template.etId);
        if (response.status === 200) {
          ShowMessage("success", "Template deleted successfully");
        }
        this.getTemplates();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
        ShowMessage("error", "Something went wrong!.")
      }
    },
    async getTemplates() {
      try {
        this.loading = true;
        let response = await templateService.all(this.filter);
        this.Templates = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage =response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;

        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getTemplateTypes() {
      try {
        this.loading = true;
        let response = await optionService.getOptionsByHeaderId(16)
        this.types = response.data ?? [];
        if (this.types.length > 0) {
          this.template.etType = this.types[0].optId;
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async AddTemplate() {
      this.loader = true;
      this.$refs.templateForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let response = await templateService.addTemplate(this.template);
            this.resetTemplate();
            if (response.status == 200) {
              this.addDialog = false;
              this.getTemplates();

            }
          } catch (e) {
            this.loading = false;
            console.log(e);
          }
        } else {
          ShowMessage("error", "Please enter the required fields!");
          this.loader = false;
        }
      });
      this.loader = false;


    }, async UpdateTemplate() {
      this.loader = true;
      this.$refs.templateForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let response = await templateService.updateTemplate(this.template);
            this.resetTemplate();
            if (response.status == 200) {
              this.addDialog = false;
              this.getTemplates();
            }
          } catch (e) {
            this.loading = false;
            console.log(e);
          }
        } else {
          ShowMessage("error", "Please enter the required fields!");
          this.loader = false;
        }
      });
      this.loader = false;


    },
    resetTemplate() {
      this.template = {
        "etId": 0,
        "etTitle": "",
        "etBody": "<b>Your content goes here!</b>",
        "etCreatedByUser": 0,
        "etIsActive": 0,
        "etIsDefaultConcerns": 0,
        "etIsDefaultWorkshops": 0,
        "etCode": "",
        "etSubject": "",
        "etType": null
      }
    }
    ,
    getMessageByCode(code) {
      // Find the object with the matching code
      if(this.translations == null){
        this.translations = getLanguage();
        return ;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.getTemplateTypes();
    this.getTemplates();
    this.template.etBody = this.getMessageByCode('your_cont') ?? "You content goes here!.."
  }
}
</script>

<style scoped>
.ck-content{
  text-transform: none !important;
}
</style>