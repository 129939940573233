<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-4">
          <BreedCrumb :showBread="false"/>
        </div>
        <div v-if="applicantId === null" class="col-12 col-md-8 d-flex justify-content-end">
          <el-select class="w-100 w-md-50" filterable v-model="filter.category" style="max-width: 150px"
                     @change="getOfferedJobs()">
            <el-option v-for="item in jobCategories" :key="item.optId" :label="item.optTitle"
                       :value="item.optId" @change="getOfferedJobs()"></el-option>
          </el-select>
          <el-select class="w-100 w-md-50 ms-1" filterable v-model="filter.applicantId" style="max-width: 200px"
                     @change="getOfferedJobs()">
            <el-option v-for="item in applicants" :key="item.id" :label="item.forename + ' '+ item.surname"
                       :value="item.id"></el-option>
          </el-select>
          <el-input placeholder="Search here..." class="ms-1" @keyup="getOfferedJobs()"
                    style="max-width: 350px">
            <template #append>
              <el-button @click="getOfferedJobs()">
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>

        </div>
      </div>
    </div>
    <div v-if="offers.length < 1 && loading" class="row">
      <div class="col-12 col-md-6 col-lg-3" v-for="i in 4" :key="i">
        <div class="card border-1 rounded-1">
          <div class="card-header bg-white">
            <n-skeleton text width="60%"/>
          </div>
          <div class="card-body">
            <n-skeleton text :repeat="6"/>
            <el-divider border-style="dashed" class="my-2 mt-5"></el-divider>
            <n-skeleton></n-skeleton>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 col-md-6 col-lg-3" v-for="offer in offers" :key="offer.id">
        <div class="card shadow-sm border-1 rounded-1">
          <div class="card-header bg-white">
            <span> {{ offer.applicant }}</span>
          </div>
          <div class="card-body">
            <n-h1>
              <n-text underline>{{ offer?.jobTitle }}</n-text>
            </n-h1>
            <span class="text-secondary">{{ offer.description }}</span>
            <el-divider border-style="dashed" class="my-2 mt-5"></el-divider>
            <div class="d-flex justify-content-end">
              <n-button :color="colors.primary" @click="viewApplication(offer)">View Offer</n-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jobService from "@/Services/ComponentsServices/jobService";
import applicantService from "@/Services/ComponentsServices/applicantService";
import BreedCrumb from "../Layout/BreedCrumb.vue";
import optionService from "@/Services/ComponentsServices/optionService";
import {getApplicantIdFromSession} from "@/Utility/sessionHelper";
import {NButton, NSkeleton, NH1, NText} from 'naive-ui'
import colors from "@/Utility/colors";

export default {
  name: "JobsOffered",
  computed: {
    colors() {
      return colors
    }
  },
  components: {NButton, BreedCrumb, NSkeleton, NH1, NText},
  data() {
    return {
      loading: false,
      applicants: [],
      offers: [],
      jobCategories: [
        {optId: 0, optTitle: "All"}
      ],
      applicantId: getApplicantIdFromSession(),
      filter: {
        applicantId: Number(sessionStorage.getItem("applicantId")),
        search: "",
        category: 0,
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {
    viewApplication(offer) {
      this.$store.state.pageId = offer.applicationId;
      if (offer.status > 3) {
        this.$store.state.applicationReadOnly = true;
      }
      this.$router.push('/application')
    },
    async getOfferedJobs() {
      this.loading = true;
      try {
        let response = await jobService.getOfferedJobs(this.filter);
        this.offers = response?.data ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getJobCategories() {
      this.loading = true;
      try {
        let response = await optionService.getOptionsByHeaderId(30);
        this.jobCategories = this.jobCategories.concat(response.data)

      } catch (e) {
        console.log()
      }
      this.loading = false;
    },
    async getApplicants() {
      try {
        this.loading = true;
        if (this.applicantId === null) {
          let response = await applicantService.getAll();
          this.applicants = response.data.items ?? [];

          if (this.applicants.length > 0) {
            this.filter.applicantId = this.applicants[0].id;
          }
        }
        await this.getOfferedJobs();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
  },
  created() {
    if (sessionStorage.getItem("applicantId") === 'null') {
      this.filter.applicantId = 0;
    } else {
      this.filter.applicantId = sessionStorage.getItem("applicantId");
    }
    this.getJobCategories();
    this.getApplicants();
  }
}
</script>

<style scoped>

</style>

