<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-7">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-5 d-flex justify-content-end">

          <el-input v-model="filter.search" placeholder="Search here...">
            <template #append>
              <el-button>
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>
          <el-button class="border-0 mbtn-primary ms-1 text-capitalize" @click="resetDocTemplate()">
            Add Doc Template
          </el-button>
        </div>
      </div>
    </div>
    <el-table :data="docTemplates"
              :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe>
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            small
        >
        </el-pagination>
      </template>
      <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
      <el-table-column label="Title" prop="title" sortable></el-table-column>
      <el-table-column label="File Name" prop="fileName"></el-table-column>
      <el-table-column label="Category" prop="category"></el-table-column>
      <el-table-column label="Code" prop="code"></el-table-column>
      <el-table-column label="Size" prop="size" width="120px">
        <template #default="scope">
          <div class="text-end">
            <span>{{ getFormattedSize(scope.row.size) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="Actions" width="120">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <el-tooltip content="Edit">
              <i
                  class="mgc_edit_line text-primary action-btn"
                  @click="editdocTemplate(scope.row,'edit')"
              ></i>
            </el-tooltip>
            <el-tooltip content="Download">
              <i
                  class="mgc_download_2_line text-primary action-btn"
                  @click="editdocTemplate(scope.row,'download')"
              ></i>
            </el-tooltip>
            <el-tooltip content="Delete">
              <i @click="editdocTemplate(scope.row,'del')"
                 class="mgc_delete_2_line text-danger action-btn"
              ></i>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="addDialog" width="450" draggable :align-center="true">
      <template #header>
        <h5 v-if="docTemplate.id < 1">Add Document Template</h5>
        <h5 v-else>Update Document Template</h5>
      </template>
      <template #default>
        <el-form label-position="top" hide-required-asterisk>
          <div class="row">
            <div class="col-12">
              <el-form-item label="Title">
                <el-input v-model="docTemplate.title"></el-input>
              </el-form-item>
            </div>
            <div class="col-12">
              <el-form-item label="Category">
                <el-select v-model="docTemplate.category">
                  <el-option v-for="cat in docCategories" :label="cat.optTitle" :value="cat.optId"
                             :key="cat.optId"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12">
              <el-form-item label="Code">
                <el-input v-model="docTemplate.code" @keydown.space.prevent></el-input>
              </el-form-item>
            </div>
            <div class="col-12">
              <el-form-item label="File">
                <el-input v-model="docTemplate.fileName">
                  <template #prepend>
                    <el-upload :auto-upload="false" :accept="acceptedFormats" :on-change="handleFileChange"
                               :show-file-list="false"
                               class="mb-0">
                      <template #trigger>
                        <el-button type="primary">Select file</el-button>
                      </template>
                    </el-upload>
                  </template>
                </el-input>
                <div class="w-100 d-flex justify-content-end">
                  <el-space>
                    <span class="custom-caption">{{ getFormattedSize(docTemplate.size) }}</span>
                    <span class="custom-caption">{{ docTemplate.fileExtension }}</span>
                  </el-space>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="addDialog = false">Cancel</el-button>
          <el-button v-if="docTemplate.id < 1" type="primary" class="text-capitalize" @click="uploadDocument()">
            Upload
          </el-button>
          <el-button v-else type="primary" class="text-capitalize" @click="updatedDocument()">
            Update
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="450">
      <template #header>
        <span>Delete Document Template</span>
      </template>
      <template #default>
        <span>Do you want to delete</span> <span
          class="fw-bold text-danger">{{ docTemplate?.title }}</span>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="deleteDialog = false">Cancel</el-button>
            <button class="btn btn-sm ms-1 px-3 btn-danger" @click="deleteDocTemplate()">
              Delete
            </button>

          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import optionService from "@/Services/ComponentsServices/optionService";
import doctemplateService from "@/Services/ComponentsServices/doctemplateService";
import configService from "@/Services/ComponentsServices/configService";
import {ShowMessage} from "@/Utility/Utility";

export default {
  name: "DocTemplate",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      docCategories: [],
      docFormats: [],
      acceptedFormats: "",
      docTemplates: [],
      docTemplate: {
        "id": 0,
        "fileName": "",
        "title": "",
        "category": 0,
        "code": "",
        "fileExtension": "",
        "mimeType": "",
        "object": "",
        "size": 0,
      },
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {
    editdocTemplate(docTemplate, type) {
      this.docTemplate = {...docTemplate};
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'del':
          this.deleteDialog = true;
          break;
        case 'download':
          this.downloadFile();
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getdocTemplates();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getdocTemplates();
    },
    handleFileChange(file) {
      const selectedFile = file.raw;
      this.docTemplate.fileName = file.name
      this.docTemplate.size = file.raw.size;
      this.docTemplate.mimeType = file.raw.type;
      this.docTemplate.fileExtension = file.raw.name.split('.').pop();
      this.returnDocObject(selectedFile, (base64String) => {
        this.docTemplate.object = base64String;
      });
    },
    returnDocObject(file, callback) {
      const reader = new FileReader();
      reader.onload = () => {
        const byteArray = new Uint8Array(reader.result);
        const base64String = btoa(String.fromCharCode.apply(null, byteArray));
        callback(base64String);
      };

      reader.readAsArrayBuffer(file);
    },
    async getdocTemplates() {
      this.loading = true;
      try {
        let response = await doctemplateService.all(this.filter);
        this.docTemplates = response?.data?.items ?? []
      } catch (e) {
        console.log(e)
      }
    },

    async downloadFile() {
      this.loading = true;
      try {
        let response = await doctemplateService.download(this.docTemplate.id);
        console.log(response.data);

      } catch (error) {
        console.log(error)
       // ShowMessage("error", "Something went wrong: " + error.message);
      } finally {
        this.loading = false;
      }
    },
    download(fileContent, fileName) {
      // Convert the file content to a Blob object
      const blob = new Blob([fileContent], { type: 'application/octet-stream' });

      // Create a temporary anchor element
      const a = document.createElement('a');
      document.body.appendChild(a);

      // Set the href and download attributes of the anchor element
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;

      // Programmatically trigger a click event on the anchor element
      a.click();

      // Cleanup: remove the anchor element and revoke the Object URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(a.href);
    },
    getFormattedSize(bytes, decimals = 2) {
      if (!Number(bytes)) {
        return '0 Bytes';
      }

      const kbToBytes = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes));

      return `${parseFloat((bytes / Math.pow(kbToBytes, index)).toFixed(dm))} ${sizes[index]}`;
    },
    async uploadDocument() {
      this.loading = true;
      try {
        if (typeof this.docTemplate.category === 'string') {
          this.docTemplate.category = 0
        }
        let response = await doctemplateService.add(this.docTemplate);
        if (response.status === 200) {
          ShowMessage("success", "Document uploaded")
        }
        this.addDialog = false;
        await this.getdocTemplates();
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async updatedDocument() {
      this.loading = true;
      try {
        if (typeof this.docTemplate.category === 'string') {
          this.docTemplate.category = 0
        }
        let response = await doctemplateService.update(this.docTemplate);
        if (response.status === 200) {
          ShowMessage("success", "Document updated")
        }
        this.addDialog = false;
        await this.getdocTemplates();
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async deleteDocTemplate() {
      this.loading = true;
      try {
        let response = await doctemplateService.forceDelete(this.docTemplate.id);
        if (response.status === 200) {
          ShowMessage("success", "Document deleted")
        }
        this.deleteDialog = false;
        await this.getdocTemplates();
      } catch (e) {
        console.log(e);
        ShowMessage("error", "Something went wrong")
      }
      this.deleteDialog = false;
    },
    async getFormats() {
      try {
        let response = await configService.getByKey("DocumentTypes");
        this.acceptedFormats = response?.data?.value ?? "";
      } catch (e) {
        console.log(e)
      }
    },
    async getdocCategories() {
      try {
        let response = await optionService.getOptionsByHeaderId(11);
        this.docCategories = response?.data ?? [];
        if (this.docCategories.length > 0) {
          this.docTemplate.category = this.docCategories[0].optId;
        } else {
          this.docTemplate.category = ""
        }
      } catch (e) {
        console.log(e)
      }
    },
    resetDocTemplate() {
      this.docTemplate = {
        "id": 0,
        "fileName": "",
        "title": "",
        "category": "",
        "code": "",
        "fileExtension": "",
        "mimeType": "",
        "object": "",
        "size": 0,
      }

      this.addDialog = true;
    }
  },
  created() {
    this.getdocTemplates();
    this.getFormats();
    this.getdocCategories();
  }
}
</script>
<style scoped>


</style>