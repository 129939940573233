<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-7">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-5 d-flex justify-content-end">
          <router-link to="questions">
            <el-button text bg class="btn btn-light ms-1">
              <i class="bi bi-chevron-left me-2"></i>
              <span class="text-capitalize">Cancel</span>
            </el-button>
          </router-link>
          <el-button v-if="!edit" @click="edit = !edit" type="primary" class="ms-1">
            <span class="text-capitalize">Edit</span>
          </el-button>
          <el-button
              v-if="question.id < 1"
              @click="addQuestion()"
              class="border-0 mbtn-primary ms-1 text-capitalize"
          >Add Question
          </el-button>
          <el-button
              v-else
              @click="updateQuestion()"
              class="border-0 mbtn-primary ms-1 text-capitalize"
          >Update Question
          </el-button>
        </div>
      </div>
    </div>
    <el-form :model="question" label-position="top" hide-required-asterisk>
      <div class="row">
        <div class="col-12 col-md-4">
          <el-form-item label="Section">
            <el-select :disabled="!edit" v-model="question.sectionId" filterable clearable>
              <el-option v-for="section in sections" :key="section.id" :label="section.title"
                         :value="section.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Title">
            <el-input :disabled="!edit" v-model="question.title"></el-input>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Placeholder">
            <el-input :disabled="!edit" v-model="question.placeholder"></el-input>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Question Type">
            <el-select :disabled="!edit" v-model="question.questionType" filterable clearable
                       @change="changeQuestionType()">
              <el-option v-for="question in questionTypes" :key="question.optId" :label="question.optTitle"
                         :value="question.optId"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-if="questionType === 'Dropdown'" class="col-12 col-md-4">
          <el-form-item label="Answer">
            <el-select :disabled="!edit" v-model="question.optionHeaderId" filterable clearable>
              <el-option v-for="header in optionHeaders" :key="header.id" :label="header.title"
                         :value="header.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Yes No Dependent">
            <el-select :disabled="!edit" v-model="question.yesNoDependent">
              <el-option label="Yes"
                         :value="true"></el-option>
              <el-option label="No"
                         :value="false"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Office Use">
            <el-select :disabled="!edit" v-model="question.officeUse">
              <el-option label="Yes"
                         :value="true"></el-option>
              <el-option label="No"
                         :value="false"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Validation">
            <el-select :disabled="!edit" v-model="question.validation" filterable clearable>
              <el-option v-for="validation in validations" :key="validation.optId" :label="validation.optTitle"
                         :value="validation.optTitle.toLowerCase()"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Is Part of Confirmation">
            <el-select :disabled="!edit" v-model="question.isPartOfConfirmation">
              <el-option label="Yes"
                         :value="true"></el-option>
              <el-option label="No"
                         :value="false"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="CSS Class">
            <el-input :disabled="!edit" v-model="question.cssClass"></el-input>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <el-form-item label="Description">
            <el-input type="textarea" :rows="1" :disabled="!edit" v-model="question.description"></el-input>
          </el-form-item>
        </div>
        <div class="col-12 col-md-4">
          <div class="row justify-content-between align-items-center">
            <div class="col-12 col-md-6">
              <el-form-item label="Sort Order">
                <el-input-number :disabled="!edit" :min="0" v-model="question.sortOrder"></el-input-number>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6 d-flex justify-content-end">
              <el-form-item label="Is Required">
                <el-switch :disabled="!edit" v-model="question.isRequired"></el-switch>
              </el-form-item>
            </div>
          </div>
        </div>

      </div>
    </el-form>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>

<script>

import {ShowMessage} from "@/Utility/Utility";
import sectionService from "@/Services/ComponentsServices/sectionService";
import questionsService from "@/Services/ComponentsServices/questionsService";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import optionService from "@/Services/ComponentsServices/optionService";

export default {
  name: "AddQuestion",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      edit: false,
      sections: [],
      optionHeaders: [],
      validations: [],
      questionTypes: [],
      questionType: [],
      question: {
        "id": 0,
        "title": "",
        "size": 0,
        "questionType": "",
        "dependent": false,
        "officeUse": false,
        "isAttachment": false,
        "yesNoDependent": false,
        "validator": "",
        "cssClass": "",
        "sortOrder": 0,
        "isRequired": false,
        "isPartOfConfirmation": false,
        "dependUpon": "",
        "htmlContent": "",
        "answer": "",
        "description": "",
        "isDeleted": false,
        "deletedBy": "",
        "sectionId": "",
        "value": "",
        "placeholder": "",
        "optionHeaderId": null,
        "validation": "",
        "fileExtension": "",
        "fileSize": 0,
        "fileFormat": "",
        "fileName": "",
      }
    }
  },
  methods: {
    changeQuestionType() {
      this.questionType = this.questionTypes.find(x => x.optId === this.question.questionType)?.optValue ?? "";
      if (this.questionType !== 'Dropdown') {
        this.question.answer = '';
      }
      this.question.isAttachment = this.questionType === 'Upload';
    },

    async addQuestion() {
      this.loading = true;
      try {
        if (!this.question.title) {
          ShowMessage("error", "Please enter question title");
          return;
        }
        if (this.question.questionType < 1) {
          ShowMessage("error", "Please select question type");
          return;
        }
        if (typeof this.question.sectionId == "string") {
          this.question.sectionId = 0;
        }
        if (typeof this.question.validator == "string") {
          this.question.validator = 0;
        }
        let response = await questionsService.add(this.question);
        if (response.status === 200) {
          ShowMessage("success", "Question added");
          this.$router.push('/questions')
        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async updateQuestion() {
      this.loading = true;
      try {
        if (!this.question.title) {
          ShowMessage("error", "Please enter question title");
          return;
        }
        if (this.question.questionType < 1) {
          ShowMessage("error", "Please select question type");
          return;
        }
        if (typeof this.question.sectionId == "string") {
          this.question.sectionId = 0;
        }
        if (typeof this.question.validator == "string") {
          this.question.validator = 0;
        }
        let response = await questionsService.update(this.question);
        if (response.status === 200) {
          ShowMessage("success", "Question updated");
          this.$router.push('/questions')
        }
      } catch (e) {
        console.log(e)
        ShowMessage("error", e.response?.data?.message ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getSections() {
      this.loading = true;
      try {
        let response = await sectionService.getAll();
        this.sections = response?.data?.items ?? [];

      } catch (e) {
        console.log(e)
      }
    },
    async getQuestionTypes() {
      this.loading = true;
      try {
        let response = await optionService.getOptionsByKey("QuestionTypes");
        this.questionTypes = response?.data ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getValidations() {
      this.loading = true;
      try {
        let response = await optionService.getOptionsByKey("Validation");
        this.validations = response?.data ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getOptionHeaders() {
      this.loading = true;
      try {
        let response = await optionService.getAllHeaders();
        this.optionHeaders = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getQuestion() {
      this.loading = true;
      try {
        let response = await questionsService.getById(this.question.id);
        this.question = response?.data;
        if (this.question.validator === 0) {
          this.question.validator = "";
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },

  },
  created() {
    this.getSections();
    this.getQuestionTypes();
    this.getValidations();
    this.getOptionHeaders();
    if (this.$store.state.pageId > 0) {
      this.question.id = this.$store.state.pageId;
      this.getQuestion();
    } else {
      this.edit = !this.edit
    }
  }
}
</script>


<style scoped>

</style>