<template>
  <n-loading-bar-provider>
    <div class="common-layout m-0 p-0">
      <internet-status></internet-status>
      <el-container style="height: 100vh;">
        <el-aside v-if="state.loggedIn && $store.getters.showLayout" class="d-none d-md-block" width="200">
          <Sidebar/>
        </el-aside>
        <el-container>
          <el-header v-if="state.loggedIn && $store.getters.showLayout" class="m-0 p-0">
            <HeaderComp/>
          </el-header>
          <el-main class="m-0 p-0">
            <router-view/>
          </el-main>
        </el-container>
      </el-container>
    </div>

  </n-loading-bar-provider>
</template>
<script>

import HeaderComp from "@/components/Navigation/Header";
import Sidebar from "@/components/Navigation/Sidebar";
import {mapState} from "vuex";
import InternetStatus from "@/Utility/InternetStatus.vue";
import {NLoadingBarProvider} from "naive-ui";

export default {
  components: {
    NLoadingBarProvider,
    InternetStatus,
    HeaderComp,
    Sidebar
  },
  data() {
    return {
      collapsed: false,
      isOnline: true,
    }
  },
  computed: {
    state() {
      return this.$store.state;
    },
    ...mapState(["sidebarCollapsed"]), // Map the state to a local computed property
  },
  mounted() {
    window.addEventListener('online', () => {
      this.isOnline = true
    });
    window.addEventListener('offline', () => {
      this.isOnline = false
    });
  },
  watch: {
    sidebarCollapsed(newVal) {
      if (newVal) {
        this.collapsed = true;
      } else {
        this.collapsed = false;
      }
    },
  },
  created() {
    window.addEventListener('online', () => {
      this.isOnline = true
    });
    window.addEventListener('offline', () => {
      this.isOnline = false
    });
  }
}
</script>
<style>
@import "./assets/bootstrap/css/bootstrap.css";
@import "./assets/MingCute/fonts/MingCute.css";
@import "./assets/Style/Style.css";

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

/* Enter and leave animation classes */
.router-view-enter-active {
  animation: fadeIn 0.5s ease-in-out;
}

.router-view-leave-active {
  animation: fadeOut 0.5s ease-in-out;
}
</style>
