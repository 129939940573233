<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row py-2 align-items-center">
        <div class="col-12 col-md-8">
          <BreadCrumb :id="job.id" :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <router-link v-if="edit" to="users">
            <el-button text bg class="btn btn-light">
              <i class="bi bi-chevron-left me-2"></i>
              <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
            </el-button>
          </router-link>
          <el-button v-else @click="checkJob()" text bg class="btn btn-light">
            <i class="bi bi-chevron-left me-2"></i>
            <span class="text-capitalize">{{ getMessageByCode('cancel') ?? 'Cancel' }}</span>
          </el-button>
          <el-button v-if="jobEdit && edit" text bg class="border-0 mbtn-primary ms-2" @click="edit = !edit">
            <span class="text-capitalize">{{ getMessageByCode('edit') ?? 'Edit' }}</span>
          </el-button>
          <el-button v-if="job.id < 1"
                     class="border-0 mbtn-primary ms-2"><span
              class="text-capitalize">{{ getMessageByCode('add_job') ?? 'Add Job' }}</span>
          </el-button>
          <el-button v-if="job.id > 0 && jobEdit && !edit" :disabled="edit"
                     class="border-0 mbtn-primary ms-2 text-capitalize">
            {{ getMessageByCode('upd_job') ?? 'Update Job' }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-4 mt-3">
          <div class="card border-0 shadow-sm ">
            <div class="card-header card-header-bg2 d-flex justify-content-between align-items-center">
              <span class="card-title mb-0">{{getMessageByCode('basic_info') ?? 'Basic Info'}}</span>
              <span class="bi bi-info-circle"></span>
            </div>
            <el-divider border-style="dashed" class="mt-0 mb-0"></el-divider>

          </div>
        </div>
      </div>
    </div>



  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {getLanguage} from "@/Utility/getLanguage";
import {checkPermission} from "@/Utility/CheckPermission";

export default {
  name: "AddJob",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      edit: false,
      jobEdit: false,
      translations: [],
      job: {
        "id": 0,
        "title": "",
        "shortDescription": "",
        "longDescription": "",
        "salary": "",
        "category": "",
        "division": "",
        "department": "",
        "enabled": true,
        "country": "",
        "availableFrom": null,
        "availableTo": null
      }
    }
  },
  methods: {
    checkJob() {
      if (this.job.id < 1) {
        this.$router.push('jobs');
        return;
      }
      this.edit = !this.edit
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    }
  },
  created() {
    this.translations = getLanguage();
    this.jobEdit = checkPermission("MD_JOB_EDIT")
  }
}
</script>

<style scoped>

</style>