<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-6">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="col-12 col-md-6 d-flex justify-content-end">
          <el-select v-model="filter.status" @change="getJobs()" class="w-50 me-1">
            <el-option v-for="item in status" :key="item.value" :label="item.text" :value="item.value"></el-option>
          </el-select>
          <el-input v-model="filter.search" placeholder="Search here..." @keyup="getJobs()">
            <template #append>
              <el-button @click="getJobs()">
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>
          <el-button @click="resetJob()"
                     class="border-0 mbtn-primary ms-1 text-capitalize">{{
              getMessageByCode('add_job') ?? 'Add Job'
            }}
          </el-button>
        </div>
      </div>
    </div>
    <el-table :data="jobs"
              :fit="true" :flexible="true" :selectable="(row) => row.status !== 'disabled'" border
              header-cell-class-name="tbl-header"
              stripe>
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            small
        >
        </el-pagination>
      </template>
      <el-table-column label="#" type="index" align="center" width="55"></el-table-column>
      <el-table-column :label="getMessageByCode('title')??'Title'" prop="title" sortable></el-table-column>
      <el-table-column label="Category" prop="category"></el-table-column>
      <el-table-column label="Division" prop="division"></el-table-column>
      <el-table-column label="Department" prop="department"></el-table-column>
      <el-table-column :label="getMessageByCode('salary')  ?? 'Salary'" prop="salary" width="100">
        <template #default="scope">
          <div class="text-end" v-if="currencyPosition.toString() === 'left' && scope.row.salary !== ''">
            <span>{{ currency + " " + (scope.row.salary !== null ? scope.row.salary : 0) }}</span>
          </div>
          <div class="text-end" v-if="currencyPosition.toUpperCase() === 'right' && scope.row.salary !== ''">
            <span>{{ (scope.row.salary !== null ? scope.row.salary : 0) + " " + currency }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="getMessageByCode('active')??'Active'" prop="isActive" width="80">
        <template #default="scope">
          <div class="text-center">
            <el-tag v-if="scope.row.enabled" type="success" size="small">Yes</el-tag>
            <el-tag v-else type="danger" size="small">No</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" :label="getMessageByCode('actions')??'Actions'" width="80">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <i
                class="mgc_edit_line text-primary action-btn"
                @click="editJob(scope.row,'edit')"
            ></i>
            <i @click="editJob(scope.row,'del')"
               class="mgc_delete_2_line text-danger action-btn"
            ></i>
          </el-space>
        </template>
      </el-table-column>
    </el-table>

    <!--    Dialogs-->
    <el-dialog v-model="addDialog" draggable align-center>
      <template #header>
        <span v-if="job.id < 1">{{ getMessageByCode('add_job') ?? 'Add Job' }}</span>
        <span v-else>{{ getMessageByCode('edit_job') ?? 'Edit Job' }}</span>
      </template>
      <template #default>
        <el-form label-position="top" hide-required-asterisk>
          <div class="row">
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="required">{{ getMessageByCode('title') ?? 'Title' }}</span>
                </template>
                <el-input v-model="job.title"></el-input>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="required">{{ getMessageByCode('process') ?? 'Process' }}</span>
                </template>
                <el-select v-model="job.processId">
                  <el-option v-for="process in processes" :key="process.id" :label="process.title"
                             :value="process.id"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('category') ?? 'Category' }}</span>
                </template>
                <el-select v-model="job.category">
                  <el-option v-for="i in categories" :key="i.optId" :label="i.optTitle" :value="i.optId"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('department') ?? 'Department' }}</span>
                </template>
                <el-select v-model="job.department">
                  <el-option v-for="i in departments" :key="i.optId" :label="i.optTitle"
                             :value="i.optId"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('division') ?? 'Division' }}</span>
                </template>
                <el-select v-model="job.division">
                  <el-option v-for="i in divisions" :key="i.optId" :label="i.optTitle"
                             :value="i.optId"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('short_desc') ?? 'Short Description' }}</span>
                </template>
                <el-input
                    v-model="job.shortDescription"
                    autosize
                    type="textarea"
                />
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">{{ getMessageByCode('long_desc') ?? 'Long Description' }}</span>
                </template>
                <el-input
                    v-model="job.longDescription"
                    autosize
                    type="textarea"
                />
              </el-form-item>
            </div>

            <!--            <div class="col-12 col-md-6">-->
            <!--              <el-form-item>-->
            <!--                <template #label>-->
            <!--                  <span class="">{{ getMessageByCode('availableFrom') ?? 'Available From' }}</span>-->
            <!--                </template>-->
            <!--                <el-date-picker-->
            <!--                    v-model="job.availableFrom"-->
            <!--                    :editable="false"-->
            <!--                    class="w-100"-->
            <!--                    :format="dateFormat"-->
            <!--                />-->
            <!--              </el-form-item>-->
            <!--            </div>-->
            <!--            <div class="col-12 col-md-6">-->
            <!--              <el-form-item>-->
            <!--                <template #label>-->
            <!--                  <span class="">{{ getMessageByCode('availableTo') ?? 'Available To' }}</span>-->
            <!--                </template>-->
            <!--                <el-date-picker-->
            <!--                    v-model="job.availableTo"-->
            <!--                    :editable="false"-->
            <!--                    class="w-100"-->
            <!--                    :format="dateFormat"-->
            <!--                />-->
            <!--              </el-form-item>-->
            <!--            </div>-->

            <div class="col-12 col-md-6">
              <el-form-item>
                <template #label>
                  <span class="">Country</span>
                </template>
                <el-select v-model="job.country">
                  <el-option v-for="i in countries" :key="i.optId" :label="i.optTitle"
                             :value="i.optTitle"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-8">
                  <el-form-item>
                    <template #label>
                      <span class="">Salary</span>
                    </template>
                    <el-input
                        v-model="job.salary"

                    />
                  </el-form-item>
                </div>
                <div class="col-12 col-md-4">
                  <el-form-item class="w-100">
                    <template #label>
                      <span class="">Enabled</span>
                    </template>
                    <el-switch
                        v-model="job.enabled"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>

          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="addDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
            <el-button type="primary" v-if="job.id < 1" @click="addJob()">
              {{ getMessageByCode('add_job') ?? 'Add Job' }}
            </el-button>
            <el-button type="primary" v-else @click="updateJob()">{{
                getMessageByCode('upd_job') ?? 'Update Job'
              }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="450">
      <template #header>
        <span>{{ getMessageByCode('delete') ?? 'Delete' }} {{ getMessageByCode('job') ?? 'Job' }}</span>
      </template>
      <template #default>
        <span>{{ getMessageByCode('do_u_del') ?? 'Do you want to delete' }}</span> <span
          class="fw-bold text-danger">{{ job?.title }}</span>
      </template>
      <template #footer>
        <div class="row justify-content-end">
          <div>
            <el-button text bg @click="deleteDialog = false">{{ getMessageByCode('cancel') ?? 'Cancel' }}</el-button>
            <button class="btn btn-sm ms-1 px-3 btn-danger" @click="deleteJob()">
              {{ getMessageByCode('delete') ?? 'Delete' }}
            </button>

          </div>
        </div>
      </template>
    </el-dialog>

    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import {getLanguage} from "@/Utility/getLanguage";
import jobService from "@/Services/ComponentsServices/jobService";
import optionService from "@/Services/ComponentsServices/optionService";
import {ShowMessage} from "@/Utility/Utility";
import configService from "@/Services/ComponentsServices/configService";
import processService from "@/Services/ComponentsServices/processService";

export default {
  name: "JobsList",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      jobs: [],
      translations: [],
      categories: [],
      divisions: [],
      departments: [],
      countries: [],
      processes: [],
      currency: "£",
      currencyPosition: "right",
      dateFormat: "DD/MM/YYYY",
      job: {
        "id": 0,
        "processId": "",
        "title": "",
        "shortDescription": "",
        "longDescription": "",
        "salary": "",
        "category": "",
        "division": "",
        "department": "",
        "enabled": true,
        "country": "",
        "availableFrom": null,
        "availableTo": null
      },
      status: [
        {text: "All", value: 2},
        {text: "Active", value: 1},
        {text: "InActive", value: 0},
      ],
      filter: {
        applicantId: 1,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },
  methods: {
    editJob(job, type) {
      this.job = {...job};
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'del':
          this.deleteDialog = true;
          break;
      }
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getJobs();
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getJobs();
    },
    async addJob() {

      this.loading = true;
      try {
        if (!this.job.title) {
          ShowMessage("error", "Please add job title");
          this.loading = false
          return;
        }
        if (this.job.processId < 1) {
          ShowMessage("error", "Please attach process to job");
          this.loading = false
          return;
        }

        if (typeof this.job.category === 'string') {
          this.job.category = 0
        }
        if (typeof this.job.department === 'string') {
          this.job.department = 0
        }
        if (typeof this.job.division === 'string') {
          this.job.division = 0
        }

        let response = await jobService.add(this.job);
        if (response.status === 200) {
          ShowMessage('success', 'Job added successfully');
          await this.getJobs();
        } else {
          ShowMessage('error', 'Job adding failed');
        }

      } catch (e) {
        console.log(e)
      }
      this.loading = false;
      this.addDialog = false;
    },
    async updateJob() {
      this.addDialog = false;
      this.loading = true;
      try {
        if (!this.job.title) {
          ShowMessage("error", "Please add job title");
          this.loading = false
          return;
        }
        if (this.job.processId < 1) {
          ShowMessage("error", "Please attach process to job");
          return;
        }
        if (typeof this.job.category === 'string') {
          this.job.category = 0
        }
        if (typeof this.job.department === 'string') {
          this.job.department = 0
        }
        if (typeof this.job.division === 'string') {
          this.job.division = 0
        }
        let response = await jobService.update(this.job);
        if (response.status === 200) {
          ShowMessage('success', 'Job updated successfully');
          await this.getJobs();
        } else {
          ShowMessage('error', 'Job updating failed');
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async deleteJob() {
      this.loading = true;
      try {
        let response = await jobService.delete(this.job.id);
        if (response.status === 200) {
          ShowMessage('success', 'Job deleted')
        }
      } catch (e) {
        console.log(e);
        ShowMessage('error', 'Something went wrong');
      }
    },
    async getJobs() {
      try {
        this.loading = true;
        let response = await jobService.all(this.filter);
        this.jobs = response.data.items ?? [];
        this.filter.totalCount = response.data.totalCount ?? 0;
        this.filter.currentPage = response.data.currentPage ?? response.data.currentPage === 0 ? 1 : response.data.currentPage || 1;
        this.filter.pageSize = response.data.pageSize;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getCountries() {
      try {
        let response = await optionService.getOptionsByHeaderId(7);
        this.countries = response.data ?? [];
        if (this.countries.length > 0) {
          this.job.country = this.countries[0].optTitle;
        }
      } catch (e) {
        console.log()
      }
    },
    async getCategories() {
      try {
        let response = await optionService.getOptionsByHeaderId(30);
        this.categories = response.data ?? [];
        if (this.categories.length > 0) {
          this.job.category = this.categories[0].optId;
        }

      } catch (e) {
        console.log()
      }
    },
    async getDivisions() {
      try {
        let response = await optionService.getOptionsByHeaderId(31);
        this.divisions = response.data ?? [];
        if (this.divisions.length > 0) {
          this.job.division = this.divisions[0].optTitle;
        }
      } catch (e) {
        console.log()
      }
    },
    async getDepartments() {
      try {
        let response = await optionService.getOptionsByHeaderId(32);
        this.departments = response.data ?? [];
        if (this.departments.length > 0) {
          this.job.department = this.departments[0].optTitle;
        }
      } catch (e) {
        console.log()
      }
    },
    async getProcesses() {
      this.loading = true;
      try {
        let response = await processService.getAll();
        this.processes = response?.data?.items ?? [];
      } catch (e) {
        console.log(e)
      }
    },
    getMessageByCode(code) {
      // Find the object with the matching code
      if (this.translations == null) {
        this.translations = getLanguage();
        return;
      }
      const matchingObject = this.translations.find(obj => obj.code === code);
      return matchingObject ? matchingObject.message : null;
    },
    async getConfigs() {
      try {
        this.loading = true;
        let response = await configService.getByKey("CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey("CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey("DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    resetJob() {
      this.addDialog = true;
      this.job = {
        id: 0,
        title: "",
        processId: "",
        shortDescription: "",
        longDescription: "",
        salary: "",
        category: "",
        division: "",
        department: "",
        enabled: true,
        country: "",
        availableFrom: null,
        availableTo: null,
        firstName: "",
        lastName: ""
      };
    }
  },
  created() {
    this.translations = getLanguage();
    this.getJobs();
    this.getCountries();
    this.getCategories();
    this.getDivisions();
    this.getDepartments();
    this.getConfigs();
    this.getProcesses();
  }
}
</script>
<style scoped>

</style>