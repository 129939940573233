<template>
  <div class="container-fluid main-wrapper p-2 p-md-0" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
    <div class="row h-100">
      <div class="col-12 col-md-6  text-center sign-in-col">
        <div class="bg-white w-100 w-md-75 p-4 rounded-2">
          <div class="mb-3">
            <h3 class="fw-bold">Reset Password</h3>
          </div>
          <div class="col-12  m-0 p-0">
            <el-form ref="myForm" :model="User" :rules="formRules" label-position="top">
              <!--            <el-form-item label="Username" prop="userName">
                            <el-input
                                v-model="Profile.userName"
                                class="inpt-bg"
                                size="large"
                                type="text"
                                tabindex="1"
                            ></el-input>
                          </el-form-item>-->
              <el-form-item label="New Password" prop="password">
                <el-input
                    v-model="User.password"
                    :type="showPass ? 'text' : 'password'"
                    size="large"
                    tabindex="2"

                >
                  <template #append>
                    <el-button @click="showPassC = !showPassC">
                      <i v-if="showPassC" class="bi bi-eye"></i>
                      <i v-else class="bi bi-eye-slash"></i>
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="Confirm Password" prop="confirmPassword">
                <el-input
                    v-model="User.confirmPassword"
                    :type="showPass ? 'text' : 'password'"
                    size="large"
                    tabindex="2"
                    @keyup.enter="changePassword"
                >
                  <template #append>
                    <el-button @click="showPass = !showPass">
                      <i v-if="showPass" class="bi bi-eye"></i>
                      <i v-else class="bi bi-eye-slash"></i>
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item class="mt-5">
                <el-button
                    :disabled="loading || (!User.userName)"
                    :loading="loading"
                    class="mbtn-primary rounded-1 w-100"
                    size="large"
                    @click="submitForm"
                >{{ loading ? "Singing In" : "Continue" }}
                </el-button
                >
              </el-form-item>
              <el-divider>OR</el-divider>
              <el-form-item>
                <router-link to="/" class="w-100">
                  <el-button ref="loginBtn" bg class="btn btn-light w-100" size="large" text>
                    <span class="ms-2">Login</span>
                  </el-button>
                </router-link>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import axios from "axios";
import loginServices from "@/Services/loginServices";
import {ShowMessage} from "@/Utility/Utility";
import {jwtDecode} from "jwt-decode";
import configService from "@/Services/ComponentsServices/configService";

export default {
  name: "ResetPassword",
  data() {
    return {
      showPass: false,
      showPassC: false,
      loading: false,
      imageUrl:"",
      retypePassword: "",
      User: {
        userName: "",
        password: "",
        confirmPassword: '',
      },
      formRules: {
        userName: [
          {required: true, message: 'Please enter a username', trigger: 'blur'},
        ],
        password: [
          {required: true, message: 'Please enter a password', trigger: 'blur'},
          {min: 6, max: 50, message: 'Password length should be between 6 and 50 characters', trigger: 'blur'},
        ],
        confirmPassword: [
          {required: true, message: 'Please confirm your password', trigger: 'blur'},
          {validator: this.validatePassword, trigger: 'blur'},
        ],
      },
    }
  },
  methods: {
    validatePassword(rule, value, callback) {
      if (value === '' || value === this.User.password) {
        callback();
      } else {
        callback(new Error('Passwords do not match'));
      }
    },
    submitForm() {
      this.$refs.myForm.validate(valid => {
        if (valid) {
          this.changePassword();
        }
      });
    },
    async changePassword() {
      try {
        this.loading = true;

        // eslint-disable-next-line no-unused-vars
        let response = await loginServices.changePassword(this.User);

        ShowMessage("success", "Password changed successfully");
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e.message);
        ShowMessage("error", "Something went wrong");
      }
    },
    setUser(username) {
      this.User.userName = username;
    }
  },
  created() {
    configService.getByKey(0, "LoginBackground").then((res) => {
      this.imageUrl = res.data.value;
    })
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('guid');
    if (token !== null) {
      const user = jwtDecode(token)
     this.User.userName = user.email;
      sessionStorage.setItem("token", token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");
    }
    // Remove the token from the URL
    setTimeout(function () {
      let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({path: newUrl}, '', newUrl);
    }, 1);
  },

}
</script>
<style scoped>
.main-wrapper{
  height: 100vh;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>