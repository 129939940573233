import Config from "@/Configuration/Config";
import axios from "axios"

export default {
    allUrl: Config.apiBaseUrl + "config/getconfigs?clientId=",
    getbyKeyUrl: Config.apiBaseUrl + "config/getconfigbykey?key=",
    addUrl: Config.apiBaseUrl + "config/add",
    all(filter) {
        return axios.get(this.allUrl + filter.clientId + "&search=" + filter.search + "&sort=" + filter.sort + "&limit=" + filter.pageSize + "&page=" + filter.currentPage);
    },
    getByKey(key) {
        return axios.get(this.getbyKeyUrl  + key);
    },
    add(config) {
        return axios.post(this.addUrl, config)
    }

}