<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center justify-content-between custom-toolbar">
        <div class="col-12 col-md-6">
          <BreadCrumb :showBread="false"/>
        </div>
        <div class="d-flex justify-content-end" :class="role ==='Admin' ? 'col-12 col-md-6' : 'col-12 col-md-4'">
          <el-select v-if="role === 'Admin'" class="w-100 w-md-50" v-model="approverId" filterable
                     @change="getApprovals()">
            <el-option v-for="app in approvers" :key="app.id" :label="app.displayName" :value="app.id"></el-option>
          </el-select>
          <el-input v-model="filter.search" placeholder="Search here..." class="ms-2" @keyup="getApprovals()">
            <template #append>
              <el-button @click="getApprovals()">
                <i class="bi bi-search"></i>
              </el-button>
            </template>
          </el-input>
        </div>
      </div>
    </div>
    <el-table
        :data="approvals"
        :fit="true"
        :flexible="true"
        :selectable="(row) => row.status !== 'disabled'"
        border
        header-cell-class-name="tbl-header"
        stripe
    >
      <template #append>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :total="filter.totalCount"
            :page="filter.currentPage"
            :page-size="filter.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            layout=" prev, pager, next,sizes"
            class="bg-gray p-2"
            background
            small
        >
        </el-pagination>
      </template>
      <el-table-column
          label="#"
          type="index"
          align="center"
          width="55"
      ></el-table-column>
      <el-table-column
          label="Title"
          prop="title"
          sortable
      ></el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="Applicant" prop="applicant"></el-table-column>
      <el-table-column label="Job Title" prop="applicationTitle"></el-table-column>
      <el-table-column label="Status" prop="status" width="100">
        <template #default="scope">
          <span>{{ getStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>

      <el-table-column align="center" fixed="right" label="Actions" width="80">
        <template #default="scope">
          <el-space alignment="center" spacer="|">
            <el-tooltip content="View stage">
              <i @click="editStage(scope.row, 'data')"
                 class="bi bi-clipboard-check text-secondary action-btn"
              >
              </i>
            </el-tooltip>
            <!--            <el-tooltip content="View stage data">-->
            <!--              <i @click="editStage(scope.row, 'data')"-->
            <!--                 class="bi bi-ui-checks text-primary action-btn"-->
            <!--              >-->
            <!--              </i>-->
            <!--            </el-tooltip>-->
            <el-dropdown type="primary">

              <i class="bi bi-three-dots-vertical"></i>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="updateStatus(scope.row.id, 6)">
                    <div class="d-flex align-items-center">
                      <i class="bi bi-check-circle-fill mfw-bold text-success me-2"></i>
                      <span>Approve</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item @click="updateStatus(scope.row.id, 7)">
                    <div class="d-flex align-items-center">
                      <i class="bi bi-x-circle-fill mfw-bold text-danger me-2"></i>
                      <span>Reject</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-space>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="statusDialog" width="400">
      <template #header>
        <span>{{ stage?.title }}</span>
      </template>
      <template #default>
        <el-form label-position="top">
          <el-form-item>
            <template #label>
              <span>Change Status</span>
              <el-select v-model="stage.status">
                <el-option v-for="status in statuses" :key="status.value" :label="status.text"
                           :value="status.value"></el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary strong @click="statusDialog = false">Cancel</n-button>
          <n-button :color="colors.primary" class="ms-2"
                    @click="statusDialog = false; updateStatus(stage.id, stage.status)">Update
          </n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dataDialog" width="600">
      <template #header>
        <span>{{ stage?.title }}</span>
      </template>
      <template #default>
        <el-form label-position="top">
          <el-form-item>
            <template #label>
              <span>Change Status</span>
              <el-select v-model="stage.status">
                <el-option v-for="status in statuses" :key="status.value" :label="status.text"
                           :value="status.value"></el-option>
              </el-select>
            </template>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary strong @click="dataDialog = false">Cancel</n-button>
          <n-button :color="colors.primary" class="ms-2"
                    @click="dataDialog = false; updateStatus(stage.id, stage.status)">Update
          </n-button>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";
import stageService from "@/Services/ComponentsServices/stageService";
import {ShowMessage} from "@/Utility/Utility";
import userService from "@/Services/ComponentsServices/userService";
import optionService from "@/Services/ComponentsServices/optionService";
import colors from "@/Utility/colors";
import {NButton} from 'naive-ui'

export default {
  name: "ApprovalsList",
  computed: {
    colors() {
      return colors
    }
  },
  components: {BreadCrumb, NButton},
  data() {
    return {
      loading: false,
      statusDialog: false,
      dataDialog: false,
      approverId: sessionStorage.getItem("userId") ?? 0,
      approvers: [],
      approvals: [],
      statuses: [
        {text: "Not Started", value: 0},
        {text: "In Progress", value: 1},
        {text: "Pending", value: 2},
        {text: "Submitted", value: 3},
        {text: "Completed", value: 4},
        {text: "Partial Approved", value: 5},
        {text: "Approved", value: 6},
        {text: "Reject", value: 7},
      ],
      stage: {},
      approverSearch: "",
      role: sessionStorage.getItem("role"),
      approvalUser: {
        "id": 0,
        "applicationId": 0,
        "processId": 0,
        "stageId": 0,
        "approvalUserId": 0,
        "approvalDate": "2024-06-13T10:54:56.384Z",
        "status": 0
      },
      filter: {
        applicantId: 0,
        search: "",
        sort: "",
        pageSize: 10,
        currentPage: 1,
        totalCount: 0,
        status: 2,
      },
    }
  },

  methods: {
    editStage(stage, type) {
      this.stage = JSON.parse(JSON.stringify(stage));
      switch (type) {
        case 'status':
          this.statusDialog = true;
          break;
        case 'data':
          this.getStageData(this.stage);
          this.dataDialog = true;
          break;
      }
    },
    getStageData(stage) {
      try {
        this.$store.state.applicationId = stage.applicationId;
        this.$store.state.stageId = stage.stageId;
        this.$store.state.pageId = stage.id;
        this.$router.push('/stagedata')
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Getting data failed!")
      }
    },

    async updateStatus(stagId, status) {
      this.loading = true;
      try {
        let response = await stageService.updateStatus(stagId, status);

        if (response.data === true) {
          ShowMessage("success", "Stage status updated");
          await this.getApprovals();
        } else {
          ShowMessage("error", "Status update failed");

        }
      } catch (e) {
        ShowMessage("error", e?.response?.data?.message ?? "Something went wrong");
      }
      this.loading = false;
    },
    handleSizeChange(newSize) {
      this.filter.pageSize = newSize;
      this.getApprovals();
    },
    getStatus(optId) {
      //return this.statuses.find(x => x.optId === optId)?.optTitle ?? 'Pending';
      return this.statuses.find(x => x.value === optId)?.text ?? 'Pending';
    },
    handleCurrentChange(newPage) {
      this.filter.currentPage = newPage;
      this.getApprovals();
    },
    async getApprovals() {
      this.loading = true;
      try {
        let response = await stageService.getStagesForApproval(this.approverId, this.filter);
        this.approvals = response?.data?.items ?? [];
      } catch (e) {
        ShowMessage("error", e?.response?.data?.message ?? "Fetching data failed");
      }
      this.loading = false;
    },
    async getApprovers() {
      if (this.role === 'Admin') {
        this.loading = true;
        try {
          let response = await userService.getApprovers(this.approverSearch);
          this.approvers = response?.data?.items ?? []
          if (this.approvers.length > 0) {
            this.approverId = this.approvers[0].id
          }
        } catch (e) {
          ShowMessage("error", e?.response?.data?.message ?? "Fetching data failed");
        }
        this.loading = false;
      }
      await this.getApprovals(this.approverId);
    },
    async getStatuses() {
      try {
        let response = await optionService.getOptionsByKey("status");
        this.statuses = response.data ?? []
      } catch (e) {
        console.log(e)
      }
    }
  },
  created() {
    this.getApprovers();
    //this.getStatuses();
  }
}
</script>

<style scoped>

</style>