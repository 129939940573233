<template>
  <div class="container-fluid">
    <div class="container-fluid border-bottom mb-2 p-0">
      <div class="row d-flex align-items-center custom-toolbar">
        <div class="col-12 col-md-8">
          <BreadCrumb :showBread="true"/>
        </div>
        <div class="col-12 col-md-4 d-flex justify-content-end">
          <el-button-group>
            <router-link to="approvals">
              <el-button text bg class="btn btn-light ms-1">
                <i class="bi bi-chevron-left me-2"></i>
                <span class="text-capitalize">Back</span>
              </el-button>
            </router-link>
            <el-dropdown type="primary">
              <el-button type="primary" class="px-2">
                <i class="mgc_down_line"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="updateStatus(id, 6)">
                    <div class="d-flex align-items-center">
                      <i class="bi bi-check-circle-fill mfw-bold text-success me-2"></i>
                      <span>Approve</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item @click="updateStatus(id, 7)">
                    <div class="d-flex align-items-center">
                      <i class="bi bi-x-circle-fill mfw-bold text-danger me-2"></i>
                      <span>Reject</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-button-group>
        </div>
      </div>
    </div>
    <div class="row justify-content-center py-2">
      <div class="col-8 d-flex flex-column justify-content-center align-items-center">
        <h3>{{ stage?.title }}</h3>
        <span class="text-secondary">{{ stage?.description }}</span>
        <div>
          <div v-for="section in stage.sections" :key="section.id">
            <div class="card mt-3">
              <div class="card-header bg-gray-light border-0 py-3">
                <h5 class="mfw-bold m-0">{{ section?.title ?? "" }}</h5>
              </div>
              <div class="card-body">
                <div>
                  <el-form label-position="top">
                    <el-form-item v-for="question in section?.questions" :key="question.id"
                                  :class="question.cssClass">
                      <template #label>
                        <span :class="question.isRequired ? 'required' : ''">{{ question.title }}</span>
                      </template>
                      <template #default>
                        <el-input disabled
                                  v-if="getQuestionType(question.questionType) === 'upload' && !question.validation"
                                  v-model="question.fileName">
                          <template #prepend>
                            <el-upload disabled :auto-upload="false"
                                       :show-file-list="false"
                                       class="mb-0">
                              <template #trigger>
                                <el-button disabled type="primary">Choose file</el-button>
                              </template>
                            </el-upload>
                          </template>
                        </el-input>
                        <el-input disabled
                                  v-if="getQuestionType(question.questionType) === 'freetext' && !question.validation"
                                  v-model="question.value">
                        </el-input>
                        <el-input disabled type="textarea" :rows="3"
                                  v-if="getQuestionType(question.questionType) === 'textarea' && !question.validation"
                                  v-model="question.value">
                        </el-input>
                        <div class="w-100"
                             v-if="getQuestionType(question.questionType) === 'freetext' &&  question?.validation === 'email'">
                          <el-input disabled v-model="question.value"></el-input>

                        </div>
                        <div class="w-100"
                             v-if="getQuestionType(question.questionType) === 'date' &&  question?.validation === 'date'">
                          <el-date-picker disabled :format="dateFormat" :editable="false" v-model="question.value"
                          ></el-date-picker>

                        </div>
                        <el-select disabled v-if="getQuestionType(question.questionType) === 'dropdown'"
                                   v-model="question.value">
                          <el-option v-for="option in getOptionsForHeaderId(question.optionHeaderId ?? 0)"
                                     :key="option.optId"
                                     :label="option.optTitle" :value="option.optTitle">
                          </el-option>
                        </el-select>
                        <el-select disabled v-if="getQuestionType(question.questionType) === 'yesno'"
                                   v-model="question.value">
                          <el-option label="Yes" :value="true"></el-option>
                          <el-option label="No" :value="false"></el-option>
                        </el-select>

                      </template>
                    </el-form-item>
                  </el-form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border"></div>
  </div>
</template>
<script>
import stageService from "@/Services/ComponentsServices/stageService";
import {ShowMessage} from "@/Utility/Utility";
import optionService from "@/Services/ComponentsServices/optionService";
import configService from "@/Services/ComponentsServices/configService";
import BreadCrumb from "@/components/Layout/BreedCrumb.vue";

export default {
  name: "StageData",
  components: {BreadCrumb},
  data() {
    return {
      loading: false,
      applicationId: 0,
      stageId: 0,
      id: 0,
      optionsMap: new Map(),
      stage: {},
      currency: "£",
      currencyPosition: "right",
      dateFormat: "DD/MM/YYYY",
    }
  },
  methods: {
    getQuestionType(id) {
      return this.questionTypes.find(x => x.optId === id)?.optValue.toLowerCase() ?? "";

    },

    async getListOptions(headerId) {

      if (this.optionsMap.has(headerId)) {
        return this.optionsMap.get(headerId);
      }
      try {
        let response = await optionService.getOptionsByHeaderId(headerId);
        let options = response?.data ?? [];
        this.optionsMap.set(headerId, options);
        return options;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    getOptionsForHeaderId(headerId) {
      return this.optionsMap.get(headerId) || [];
    },
    async getQuestionTypes() {
      try {
        let response = await optionService.getOptionsByKey("QuestionTypes");
        this.questionTypes = response?.data ?? [];
      } catch (e) {
        console.log(e)
      }
    },
    async getConfigs() {
      try {
        let response = await configService.getByKey("CurrencySymbol");
        this.currency = response.data.value ?? "£";
        let response2 = await configService.getByKey("CurrencyPosition");
        this.currencyPosition = response2.data.value ?? "left";
        let response3 = await configService.getByKey("DateFormat");
        this.dateFormat = response3.data.value ?? "DD/MM/YYYY";

      } catch (e) {
        console.log(e);

      }
    },
    async getStageData(applicationId, stageId) {
      try {
        let response = await stageService.viewStageData(applicationId, stageId);
        this.stage = response?.data?.stage;
      } catch (e) {
        ShowMessage("error", e?.data?.message ?? "Getting stage data failed!")
      }
    },
    async updateStatus(stagId, status) {
      this.loading = true;
      try {
        let response = await stageService.updateStatus(stagId, status);

        if (response.data === true) {
          ShowMessage("success", "Stage status updated");
          this.$router.push('/approvals')
        } else {
          ShowMessage("error", "Status update failed");

        }
      } catch (e) {
        ShowMessage("error", e?.response?.data?.message ?? "Something went wrong");
      }
      this.loading = false;
    },

  },
  created() {
    this.getQuestionTypes();
    if (this.$store.state.pageId > 0 && this.$store.state.stageId > 0) {
      this.applicationId = this.$store.state.applicationId;
      this.stageId = this.$store.state.stageId;
      this.id = this.$store.state.pageId;
      this.getStageData(this.$store.state.pageId, this.$store.state.stageId);
    }
  }
}
</script>

<style scoped>

</style>